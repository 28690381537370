import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import useCookie from "./useCookie";

const query = gql`
  query MemberProfileQuery {
    coop_profile {
      uuid
      name
      email
    }
  }
`;

function useMember() {
  const [token, , logout] = useCookie('token');
  // const [member, setMember] = useState<any>();
  const { data, loading, refetch } = useQuery<{ coop_profile: { uuid: string, [key: string]: any }}>(query);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  return {
    member: data?.coop_profile,
    loading,
    loggedIn: Boolean(data?.coop_profile),
    logout
  };
}

export default useMember;