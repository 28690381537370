
import styled, { keyframes } from "styled-components";

const wobble = keyframes`
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(2.5deg);
  }

  75% {
    transform: rotate(-2.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const fluctuate = keyframes`
  0% {
    transform: scale(100%, 100%);
  }

  50% {
    transform: scale(120%, 120%);
  }

  100% {
    transform: scale(100%, 100%);
  }
`;

// Unwobbled
const WobblyButton = styled.a`
  display: block;
  /* animation: ${wobble} 1s ease infinite; */
  background: rgba(255,214,0,1);
  border: 2px solid rgba(255,214,0,1);
  border-radius: .5em;
  color: white;
  margin: 1em;
  padding: 1em 1em;
  cursor: pointer;

  .title {
    font-size: 2em;
    line-height: 1.5em;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.5em;
  }

  &:hover {
    text-decoration: none;
    opacity: .9;
    border-size: 5px;
    /* animation: ${fluctuate} 1s ease infinite; */
  }

  @media (max-width: 840px) {
    padding: 0.2em 1em;
    font-size: 1.5em;
  }

  @media (max-width: 600px) {
    padding: 0.3em 1em;
    font-size: 1.1em;
  }
`;

export default WobblyButton;