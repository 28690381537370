
import React, { useState, useCallback } from 'react';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Input from '../components/form/Input.js';
import Radio from '../components/form/Radio.js';
import Hider from '../components/Hider';
import { sendReferral } from '../API.js';
import { useTranslation } from 'react-i18next';

const RequestInfoForm = () => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const submit = useCallback((data) => {
    (async () => {
      setLoading(true);
      setError();

      try {
        const result = await sendReferral(data);
        console.log('result', result);

        if (result.success) {
          setSuccess(result);
        }

        setError('An error occured. Please try again.');
      } catch (error) {
        console.warn('submit info', error);
        setError('An error occured. Please try again.');
      }

      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <Hider hidden={success} className='m-2' style={{ minWidth: '10em'}}>
        <h5>{ t('business.landing.apply', 'Please Fill The Form Below To Find Out How We Are Connecting Our Customers To Happy Businesses') }</h5>
        <Formsy style={{width: '100%'}} onValidSubmit={submit} onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
          <Input name='form_type' type='hidden' value='Business Information Request' />
          <Input name='name' label='Your Name' placeholder='Your Name' required />
          <Input name='company' label='Business Name' placeholder='Name of Company' required />
          <Input 
            name='email' 
            label='Email' 
            placeholder='email@company.com' 
            validations='isEmail' 
            validationError='Must be a valid email.' 
            required 
            />
          <Input 
            name='phone' 
            label='Phone' 
            placeholder='(555) 555-5555' 
            required
            />
          <Input name='website' label='Website' placeholder='https://www.company.com' />
          <Input name='best_time' label='Best Time?' placeholder='Best time to contact you...' />
          <Input name='city' label='City/State' placeholder='Your City/State or Time Zone' required />
          <Radio name='attended_networking' label='Have you attended any online HNP networking events?' options={['Yes', 'No']} required />
          <Input name='from' label='How did you hear about us?' />
          <button className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>Submit</button>
          <Hider hidden={!error}  className='toast toast-error mt-2'>
            { error }
          </Hider>
        </Formsy>
      </Hider>
      <Hider className='empty' hidden={!success}>
        <div className='empty-icon'>
          <i className='icon icon-3x icon-mail'></i>
        </div>
        <p className='empty-title h5'>Thank You For Reaching Out!</p>
        <p className='empty-subtitle'>One of our representatives will follow up with you soon.</p>
      </Hider>
    </div>
  )
}

const RequestInfoPage = () => {
  const { t } = useTranslation();

  return (
    <Page title='HNP - Request Info for Business' className='background background-office'>
      <HeaderBar>
        <h1>Happy Business Attract 37% More Customers</h1>
        <h4>Harvard Business Review</h4>
      </HeaderBar>
      <Container className='p-2' style={{ color: '#FFF', backgroundColor: '#000A' }}>
        <div className='columns col-12 m-2 p-2'>
            <div className='column col-sm-12 col-md-6' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <h6 className='text-center' style={{ color: '#FFF' }}>{ t('business.landing.tag') }</h6>
              <img alt='' style={{ width: '100%' }} src='/images/happy-business-decal-photos.jpg' />
            </div>
            <div className='column col-sm-12 col-md-6 p-2' style={{ color: 'white' }}>
              <RequestInfoForm />
            </div>
        </div>
      </Container>
    </Page>
  );
}

export default RequestInfoPage;