
import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { getEmbedUrl } from '../components/custom/parseVideoUrl';
import ExternalLink from '../components/ExternalLink';
import { CoopListing } from '../hnp-graphql';

const query = gql`
  query Listing($listing_uuid: ID) {
    coop_listing(listing_uuid: $listing_uuid) {
      uuid
      member_uuid
      category_uuid
      category
      share
      vhb
      premium
      name
      blurb
      logo
      description
      keywords
      contact
      phone
      website
      email
      linkedin
      instagram
      facebook
      twitter
      tiktok
      video
      address {
        street
        city
        state
        zip
        country
      }
    }
  }
`;

interface QueryResponse {
  coop_listing: CoopListing;
}

interface SingleListingProps {
  listing: CoopListing;
}

function SingleListing({ listing }: SingleListingProps) {
  const { 
    logo, name, blurb, category, description, 
    website, linkedin, twitter, instagram, facebook, video, premium
  } = listing;

  const showPremium = Boolean(video && premium);

  return (
    <div className=''>
      <div className='' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        { Boolean(logo) && (
          <img 
            alt={`${name} Logo`}
            src={`https://res.cloudinary.com/hnp-api/image/upload/c_fill,h_750,w_750/${logo}`} 
            height={400} 
            width={400} 
            className='m-2'
            />
        )}
        <div className='text-center p-2'>
          <h4 className='m-2'>{ name }</h4>
          <h6 className='text-gray'><em>{ category }</em></h6>
          <h5 className='text-gray'>{ blurb }</h5>
          <hr className='divider' />
          <p>{ description }</p>
        </div>
      </div>
      { showPremium && Boolean(video) && (
        <div>
          <iframe width={600} title={name || ''} src={getEmbedUrl(video || '')} />
        </div>
      )}
      <div className='m-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        { Boolean(website) && (
          <ExternalLink className='btn m-2' href={website}>
            Website
          </ExternalLink>
        )}
        { Boolean(linkedin) && (
          <ExternalLink className='btn m-2' href={linkedin}>
            LinkedIn
          </ExternalLink>
        )}
        { Boolean(twitter) && (
          <ExternalLink className='btn m-2' href={twitter}>
            Twitter
          </ExternalLink>
        )}
        { Boolean(instagram) && (
          <ExternalLink className='btn m-2' href={instagram}>
            Instagram
          </ExternalLink>
        )}
        { Boolean(facebook) && (
          <ExternalLink className='btn m-2' href={facebook}>
            Facebook
          </ExternalLink>
        )}
      </div>
    </div>
  );
}

function SingleListingPage() {
  const { listing_uuid } = useParams<{ listing_uuid?: string }>();
  const { data, loading } = useQuery<QueryResponse>(query, {
    variables: { listing_uuid }
  });

  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 100}}>
        <div className='loading loading-lg'></div>
      </div>
    );
  }

  if (!data?.coop_listing.uuid) {
    return (
      <div className='empty'>
        <div className='empty-icon'>
          <i className='icon icon-people'></i>
        </div>
        <p className='empty-title h5'>Listing Not Found</p>
        <div className='empty-action'>
          <Link to='/listings' className='btn btn-primary'>Back to Listings...</Link>
        </div>
      </div>
    );
  }

  return (
    <SingleListing 
      listing={data.coop_listing} 
      />
  );
}

export default SingleListingPage;