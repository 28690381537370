
import React from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);

const TextArea = (props) => {
  const { horizontal, getValue, setValue, label, showRequired, isPristine, isValid, showError, 
    getErrorMessage, placeholder = '', rows = 3 } = props;
  const error = getErrorMessage();

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <div className={classNames('col-sm-12', { 'col-9': horizontal })}>
        <textarea 
          className="form-input" 
          value={getValue() || ''}
          placeholder={placeholder}
          rows={rows}
          onChange={e => setValue(e.currentTarget.value)}
          />
          { Boolean(error) && (
            <p className="form-input-hint">{ error }</p>
          ) }
      </div>
    </div>
  );
}

export default withFormsy(TextArea);