
import React, { useState, useCallback } from 'react';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Input from '../components/form/Input.js';
import Divider from '../components/Divider.js';
import Hider from '../components/Hider';
import { sendReferral } from '../API.js';
import TextArea from '../components/form/TextArea.js';

const RequestInfo = () => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const submit = useCallback((data) => {
    (async () => {
      setLoading(true);
      setError();

      try {
        const result = await sendReferral(data);
        console.log('result', result);

        if (result.success) {
          setSuccess(result);
        }

        setError('An error occured. Please try again.');
      } catch (error) {
        console.warn('submit info', error);
        setError('An error occured. Please try again.');
      }

      setLoading(false);
    })();
  }, []);

  return (
    <Page title='HNP - Request Info for Business'>
      <HeaderBar>
        <h1>Refer a Business</h1>
        <h4>Get a $25 gift card as a reward for any businesses that sign up!</h4>
      </HeaderBar>
      <Container className='p-2'>
        <Hider hidden={success} className='m-2'>
          <h5>Please Fill The Form Below To Connect Us With Happy Businesses</h5>
          <Formsy style={{width: '100%'}} onValidSubmit={submit} onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
            <Input name='form_type' type='hidden' value='Business Referral Form' />
            <Input name='referrer_name' label='Your Name' placeholder='Your Name' required />
            <Input 
              name='referrer_email' 
              label='Email' 
              placeholder='email@company.com' 
              validations='isEmail' 
              validationError='Must be a valid email.' 
              required 
              />
            <Input 
              name='referrer_phone' 
              label='Your Phone #' 
              placeholder='(555) 555-5555' 
              />
            <Divider />
            <Input name='company' label='Business Name' placeholder='Name of Company' required />
            <Input name='type' label='Business Type' placeholder='Business Category' />
            <Input name='contact_name' label='Contact Name' placeholder='Contact Name' required />
            <Input 
              name='email' 
              label='Email' 
              placeholder='email@company.com' 
              validations='isEmail' 
              validationError='Must be a valid email.' 
              required 
              />
            <Input 
              name='phone' 
              label='Contact Phone' 
              placeholder='(555) 555-5555' 
              />
            <Input name='website' label='Website' placeholder='https://www.company.com' />
            <Input name='relationship' label='Relationship' placeholder='Your Relationship to the Business' />
            <TextArea name='notes' label='Notes' placeholder='Tell us about the business. Why are they a happy business?' />
            <button className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>Submit</button>
            <Hider hidden={!error}  className='toast toast-error mt-2'>
              { error }
            </Hider>
          </Formsy>
        </Hider>
        <Hider className='empty' hidden={!success}>
          <div className='empty-icon'>
            <i className='icon icon-3x icon-mail'></i>
          </div>
          <p className='empty-title h5'>Thank You for the referral!</p>
          <p className='empty-subtitle'>One of our representatives will follow up with them soon.</p>
        </Hider>
      </Container>
    </Page>
  )
}

export default RequestInfo;