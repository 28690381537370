
import React from 'react';
import { Link } from 'react-router-dom';
import '../osagie.styles.css';

function ResponsiveVideo({ src, title = '', aspect = '16:9' }: { src: string, title?: string, aspect?: '16:9' | '4:3' | '1:1' }) {
  return (
    <div className='video-responsive'> 
      <iframe title={title} src={src} />
    </div>
  );
}

export default function MembershipPage() {

  return (
    <div className='container text-center'>
      <h1>Why Join the Happy Neighborhood Project</h1>
      <div className='columns' style={{ padding: '5em', backgroundColor: 'grey', color: 'white' }}>
        <div className='column col-md-6 col-sm-12'>
          <ResponsiveVideo 
            title='TEDx, Edwin Edebiri - Happiness as a Skill' 
            src='https://www.youtube.com/embed/z3cNvYW_A1g?rel=0' 
            />
        </div>
        <div className='column col-md-6 col-sm-12 full-center'>
          <h2>Check out the first 6 minutes<br />of our TEDx Talk.</h2>
          <p>It will clarify why we do what we do<br />and give you goosebumps!</p>
          <Link to='/plans' className='btn btn-primary'>View Plans</Link>
        </div>
      </div>
      <div style={{ padding: '5em' }}>
        <h2>Join the HNP</h2>
        <Link to='/plans' className='btn btn-primary'>View Plans</Link>
      </div>
    </div>
  );
}