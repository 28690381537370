import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { GraphQL, GraphQLProvider } from 'graphql-react';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DEBUG_MODE } from './API';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import Footer from './components/Footer';
import SeenIn from './components/SeenIn';
import HnpProvider from './HnpContext.js';
import redirectTo from './components/redirectTo';
import PageviewTracker from './components/PageviewTracker';
import useHopinReferralLink from './data/useHopinReferralLink';

// import OsePages from './hnp-ose/src/OsePages';
import CoopPoints from './pages/coop/CoopPoints';
import CoopReferrer from './pages/CoopReferrer';
import Home from './pages/Home';
import About from './pages/About';
import MembershipPage from './pages/Membership';
import CoopRegistration from './pages/CoopRegistration';
import CoopLanding from './pages/coop/landing';
// import Find from './pages/Find';
import ListingsList from './pages/ListingsList';
import Listings from './pages/Listings';
import ListingSearch from './pages/ListingSearch';
import SingleListingPage from './pages/SingleListing';
import Listing from './pages/Listing';
import GlobalSummit from './pages/GlobalSummit';
import GlobalSummitLanding from './pages/GlobalSummitLanding';
import CountMeIn from './pages/CountMeIn';
import Club100 from './pages/Club100';
import Links from './pages/Links';
import BusinessSupport from './pages/BusinessSupport';
import ThcReveal from './pages/ThcReveal';
import Contact from './pages/Contact';
import Business from './pages/Business';
import NetworkingLanding from './pages/NetworkingLanding';
import NetworkingCalls from './pages/NetworkingCalls';
import NetworkingCallRegistration from './pages/NetworkingCallRegistration';
import Networking from './pages/Networking2024';
import NetworkingRegistration from './pages/NetworkingRegistration';
import RequestInfo from './pages/RequestInfo';
import ReferABusiness from './pages/ReferABusiness';
import GuessNumber from './pages/GuessNumber';
import Ineligible from './pages/Ineligible';
import Jobs from './pages/Jobs';
import ZoomSignup from './pages/ZoomSignup';
import CollabSurvey from './pages/CollabSurvey';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Cohost from './pages/Cohost';
import Birthday from './pages/Birthday';
import PBS from './pages/PBS';
import AppDownload from './pages/AppDownload';
import Events from './pages/Events';
import Join from './pages/Join';
import JoinRemote from './pages/JoinRemote';
import Hosts from './pages/Hosts';
import Test from './pages/Test';
import Availability from './pages/Availability';
import RemoteListingSignup from './pages/RemoteListingSignup';
import Classes from './pages/Classes';
import ClassesEvents from './pages/ClassesEvents';
import ClassPage from './pages/ClassPage';
import ShareGHS from './pages/ShareGHS';
import DeepDiveLanding from './pages/landings/DeepDive';
import BusinessBootCamp from './pages/landings/BusinessBootCamp';
import AskHNPLanding from './pages/landings/AskHNP';
import ChamberLanding from './pages/landings/Chamber';
import ClassesLanding from './pages/landings/Classes';
import GHS2022Page from './pages/landings/GHS2022';
import GHSVendorSignup from './pages/landings/forms/GHSVendorSignup';
import IDH2023Page from './pages/landings/IDH2023';
import ChatbotOauth from './pages/ChatbotOauth';
import Chatbot from './pages/Chatbot';
import Plans from './pages/Plans';
import RandomZoomWinner from './pages/RandomZoomWinner';

import FacebookPixel from './tools/FacebookPixel';
import DebugView from './components/DebugView';
import Cookies from 'js-cookie';
import LET from './pages/LET';
import IDHRedirect from './pages/landings/IDHRedirect';

ReactGA.initialize('UA-147966438-1', {
  gaAddress: 'https://happyneighborhoodproject.com/generaladmission.js'
});

const authLink = setContext((_, { headers }) => {
  try {
    const token = JSON.parse(Cookies.get('token'));
  
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      };
    }
  } catch(e) { }
});

const httpLink = createHttpLink({
  uri: DEBUG_MODE ? 'http://localhost:3005/coop/graphql' : 'https://api.hnpabc.com/coop/graphql',
});

export const apollo = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const graphql= new GraphQL();

const OpenGraphHelmet = () => (
  <Helmet>
    <meta property="og:title" content="The Happy Neighborhood Project"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://happyneighborhoodproject.com/"/>
    <meta property="og:image" content="https://happyneighborhoodproject.com/images/thumbnail.png"/>
    <meta property="og:image:width" content="1024"/>
    <meta property="og:image:height" content="512"/>
    <meta property="og:site_name" content="The Happy Neighborhood Project"/>
    <meta property="og:description" content="Our mission is to make Happy Neighborhood Project the happiest place in the world for business just like Disney is the happiest place for children. Here you can have access to resources that can help your business."/>
    <meta property="fb:app_id" content="1223155401391681"/>
  </Helmet>
);

const Pages = () => (
  <>
    <Header />
    <Switch>
      <Route path='/let' exact component={LET} />
      <Redirect from='/points' to='/coop/points' />
      <Route path='/coop/points' component={CoopPoints} />
      <Route path='/' exact component={Home} />
      <Route path='/about' exact component={About} />
      <Route path='/membership' exact component={MembershipPage} />
      <Route path='/coop/registration' exact component={CoopRegistration} />
      <Route path='/plans' exact component={Plans} />
      <Route path='/test' exact component={Test} />
      <Route path='/privacy' exact component={PrivacyPolicy} />
      <Route path='/terms' exact component={TermsOfService} />
      <Route path='/cohost' exact component={Cohost} />
      <Route path='/links' exact component={Links} />
      <Route path='/ghs2020' exact component={GlobalSummit} />
      <Route path='/countmein' exact component={CountMeIn} />
      <Route path='/club100' exact component={Club100} />
      <Redirect from='/bd' to='/birthday' />
      <Redirect from='/birthdays' to='/birthday' />
      <Route path='/birthday' exact component={Birthday} />
      <Route path='/q/:slug/:zip' component={ListingsList} />
      <Route path='/search/:zip/:slug' component={Listings} />
      <Route path={['/zip', '/zips']} component={Availability} />
      <Redirect from='/hiring' to='/jobs' />
      <Route path='/jobs' component={Jobs} />
      <Route path='/listing/:friendly_id' component={Listing} />
      <Redirect path='/find' exact to='/listings' />
      <Route path='/listings' component={ListingSearch} exact />
      <Route from='/listings/:listing_uuid' component={SingleListingPage} />
      <Redirect from='/hb/:friendly_id' to='/listing/:friendly_id' />
      <Route path='/reveal' exact component={ThcReveal} />
      <Route path='/reveal21' exact component={ThcReveal} />
      <Route path='/contact' exact component={Contact} />
      <Route path='/business' exact component={Business} />
      <Redirect from='/refer' to='/refer-a-business' />
      <Route path='/refer-a-business' exact component={ReferABusiness} />
      <Route path='/number' exact component={GuessNumber} />
      <Route path='/business-support' exact component={BusinessSupport} />
      <Route path='/business/request-info' exact component={RequestInfo} />
      <Route path='/business/ineligible' exact component={Ineligible} />
      {/* <Route path='/zoom' exact component={ZoomSignup} /> */}
      <Route path='/zoom-e' exact component={ZoomSignup} />
      <Route path='/zoom-a' exact component={ZoomSignup} />
      <Route path='/zoom-p' exact component={ZoomSignup} />
      {/* <Route path={['zoom-e', 'zoom-a', 'zoom-p']} component={ZoomSignup} /> */}
      <Route path='/remote' exact component={RemoteListingSignup} />
      <Route path='/collab' exact component={CollabSurvey} />
      <Route path='/zoom/hosts' exact component={Hosts} />
      <Route path='/zoom/winner' exact component={RandomZoomWinner} />
      <Route path='/zoom/chatbot' exact component={Chatbot} />
      <Route path='/zoom/chatbot/success' exact component={ChatbotOauth} />
      <Route path='/tedx' exact component={About} />
      <Route path='/class' exact component={ClassesEvents} />
      <Route path='/class/:uuid' exact component={ClassPage} />
      <Route path='/pbs' exact component={PBS} />
      <Route path='/app' exact component={AppDownload} />
      <Route path='/events' exact component={Events} />
      <Route path='/events/:zip' exact component={Events} />
      <Route path='/networking' exact component={Networking} />
      <Route path='/networking/register/:slug' exact component={NetworkingRegistration} />
      <Route path='/class' exact component={Classes} />
      <Route path='/ghs/share' exact component={ShareGHS} />
      <Route path='/am/:slug/business/register' component={Join} />
      <Route path='/am/:slug/remote/register' component={JoinRemote} />
      <Route path='/remote/register' component={JoinRemote} />
      <Route path='/np/:fin' />
      <Route path={['/hbc/:zip', '/ha/:zip']} />
      <Redirect to='/' />
    </Switch>
    <SeenIn />
    <Footer />
  </>
);

function frame(url, title) {
  return () => (
    <iframe 
      title={title || `frame: ${url}`}
      src={url} 
      frameBorder={0}
      style={{ height: '100vh', width: '100vw', border: 0 }} 
      />
  );
}

function PagesSwitch() {
  const hopin_link = useHopinReferralLink();

  return (
    <Switch>
      {/* <Route path={['/', '/about', '/membership']} exact component={OsePages} /> */}
      <Route path={['/r/:slug/*', '/r/:slug']} component={CoopReferrer} />
      <Route path='/coop' exact component={CoopLanding} />
      <Route path='/chamber/:slug' exact component={ChamberLanding} />
      {/* <Route path={['/ghs', '/ghs-a', '/ghs-b', '/ghs-c']} exact component={redirectTo(hopin_link, true)} /> */}
      <Route path='/salesflow' exact component={frame('https://happysalesflow.now.site/home', 'HNP Partner Sales Flow')} />
      {/* <Route path={['/idh/vendor', '/idh2022/vendor']} exact component={IDH2022VendorPage} /> */}
      {/* <Route path='/orl' exact component={ORLLanding} /> */}
      <Route path='/summit' exact component={GlobalSummitLanding} />
      <Route path='/bootcamp' exact component={BusinessBootCamp} />
      <Route path='/deepdive' exact component={DeepDiveLanding} />
      <Route path='/askhnp' exact component={AskHNPLanding} />
      <Route path='/classes' exact component={ClassesLanding} />
      <Route path='/network' component={NetworkingLanding} exact />
      <Route path='/network/calls' component={NetworkingCalls} exact />
      <Route path='/network/calls/:slug' component={NetworkingCallRegistration} exact />
      {/* <Route path='/networking' exact component={redirectTo('https://zoom.us/meeting/register/vJQtf-itrz0pT-dyQn1MHsiAqk9SeJ9p3w', true)} /> */}
      <Route path='/ep' exact component={redirectTo('https://chatbridgeconnect.com/hnp/?aff=e4xdk', true)} />
      <Route path='/orl' exact component={redirectTo('https://orlcares.com/pages/hnp', true)} />
      <Route path='/tgd' exact component={redirectTo('https://thegreatdiscovery.com/hnp', true)} />
      <Route path='/gcl' exact component={redirectTo('https://www.robertduranlicensee.com/hnp/pages/', true)} />
      <Route path='/intro2HNP' exact component={redirectTo('https://zoom.us/meeting/register/u5Qkcu-ppjopMkXVaYu-5R43a8qCW_6Vmg', true)} />
      <Route path='/shop' exact component={redirectTo('https://shop.happyneighborhoodproject.com/', true)} />
      <Route path='/np' exact component={redirectTo('https://ourprintconnection.com/HNP', true)} />
      <Route path='/classes-eventbrite' exact component={redirectTo('https://hnpabc.eventbrite.com/', true)} />
      <Route path='/dell' exact component={redirectTo('https://dell.com/hnp', true)} />
      <Route path='/sym' exact component={redirectTo('https://sellyourmac.com/happy', true)} />
      {/* <Route path='/thryv' exact component={redirectTo('https://emp.thryv.com/site/EventsandWebinars', true)} /> */}
      <Route path='/creative7designs' exact component={redirectTo('http://creative7designs.com/', true)} />
      <Route path='/myunlimitedwp' exact component={redirectTo('https://myunlimitedwp.com/', true)} />
      <Route path='/4mycard' exact component={redirectTo('https://www.4mycard.net/en/home?signup=true&af=1681-l1c5', true)} />
      <Route path='/thinkquick' exact component={redirectTo('https://thinkquickevents.com/', true)} />
      <Route path='/cleverrx' exact component={redirectTo('https://cleverrxhnp6.projects.webpages.one/', true)} />
      <Route path='/podcast' exact component={redirectTo('https://podcast.happyneighborhoodproject.com/', true)} />
      <Route path='/joy' exact component={redirectTo('https://www.barryshore.com/hnp', true)} />
      {/* <Route path='/ghs' exact component={redirectTo(hopin_link, true)} /> */}
      <Route path='/ghs' exact component={GHS2022Page} />
      <Route path='/idh/ticket' exact component={redirectTo(hopin_link, true)} />
      <Route path={['/idh', '/idh2024', 'idh2023', '/idh2022', '/idht', '/idhm', '/idhdc', '/idhe', '/idhb', '/idhr', '/idhd', '/idhs', '/idh/vendor', '/idh2022/vendor']} exact component={redirectTo(hopin_link, true)} />
      <Route path='/idh/ticket/:slug' exact component={IDHRedirect} />
      <Route path='/ghs23' exact component={GHS2022Page} />
      {/* <Route path='/ghsbs' exact component={GHS2022Page} /> */}
      {/* <Route path='/ghs/vendor' exact component={GHSVendorSignup} /> */}
      {/* <Route path='/idh' exact component={frame('https://idh2021.com')} /> */}
      <Route 
        path={['/birthday/current', '/birthday/view', '/birthdays/current', '/birthdays/view']} 
        exact 
        component={redirectTo('https://data.heroku.com/dataclips/vmdcuzgecgqeetjieqheghvayspq', true)} 
        />
      <HnpProvider>
        <Route component={Pages} />
      </HnpProvider>
    </Switch>
  );
}

function App () {

  return (
    <>
      <OpenGraphHelmet />
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        <FacebookPixel />
        <GraphQLProvider graphql={graphql}>
          <ApolloProvider client={apollo}>
            <DebugView />
            <Router>
              <PageviewTracker />
              <PagesSwitch />
            </Router>
          </ApolloProvider>
        </GraphQLProvider>
      </div>
    </>
  );
}

export default App;
