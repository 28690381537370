
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import classNames from 'classnames';
import { createStripeCardToken } from '../../API.js';

const DEFAULT_STATE = { name: '', number: '', expiry: '', cvc: '' };

export default function StripeCreditCardInput({ token, onToken, onError }) {
  const { meta, wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
  const [state, setState] = useState(DEFAULT_STATE);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const reset = useCallback((error) => {
    setState(DEFAULT_STATE);
    setLoading(false);
    onToken && onToken(null);

    if (error) {
      onError && onError(error);
      setErrorMessage('An error occurred verifying your card, please try again.');
      console.error('StripeCreditCardInput', error);
    } else {
      setErrorMessage(null);
    }
  }, [onToken, onError]);

  const handleNumber = useMemo(() => handler('number', setState), []);
  const handleExpiry = useMemo(() => handler('expiry', setState), []);
  const handleCVC = useMemo(() => handler('cvc', setState), []);

  useEffect(() => {
    if (onToken && !loading && !token && !meta.error) {
      (async () => {
          const { number, expiry, cvc } = state || {};
          const [month, year] = String(expiry).split('/').map(s => s.trim());
    
          if (number && month && year && cvc) {
            setLoading(true);
            setErrorMessage(null);

            try {
              const token = await createStripeCardToken(number, month, year, cvc);
              if (!token.error) {
                onToken(token);
              } else {
                reset(token.error);
              }
            } catch (error) {
              reset(error);
            }

            setLoading(false);
          }
      })();
    } else if (!onToken) {
      console.warn('StripeCreditCardInput requires onToken handler.')
    }
  }, [state, onToken, reset, loading, token, meta.isTouched, meta.error]);

  if (token) {
    const { brand, last4, exp_month, exp_year } = token.card || {};
    const expiry = ('0' + exp_month).substr(-2) + '/' + exp_year;

    return (
      <div className="toast toast-success">
        <button onClick={() => reset()} className="btn btn-clear float-right"></button>
        {brand} ending in <strong>{last4}</strong> (exp. {expiry})
      </div>
    );
  }

  return (
    <>
      <PaymentInputsWrapper {...wrapperProps} className={classNames({loading})}>
        <svg {...getCardImageProps({ images })} />
        <input {...getCardNumberProps(handleNumber)} value={state.number} disabled={loading} />
        <input {...getExpiryDateProps(handleExpiry)} value={state.expiry} disabled={loading} />
        <input {...getCVCProps(handleCVC)} value={state.cvc} disabled={loading} />
      </PaymentInputsWrapper>
      { Boolean(errorMessage) && 
        <div className="toast toast-error mt-2">
          <button onClick={() => setErrorMessage(null)} className="btn btn-clear float-right"></button>
          { errorMessage }
        </div>
      }
    </>
  );
}

function handler(key, setState) {
  return { 
    onChange: (event) => {
      const { value } = event.currentTarget;

      setState && setState(state => ({
        ...state,
        [key]: value
      }));
    }
  }
}