
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { Grid, Row, Col } from '@react-spectre/grid';
import classNames from 'classnames';
import { useTranslation } from'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../components/form/HookFormInput';
import { sendCountMeInfo } from '../API.js';

const CountMeInForm = ({ onResult }) => {
  const { t } = useTranslation();
  const _form = useForm();
  const { handleSubmit, errors } = _form;
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await sendCountMeInfo(data);
    setResult(result);
    setLoading(false);
    onResult && onResult(result);
  };

  if (result) {
    return (
      <div class="empty">
        <div class="empty-icon">
          <i class="icon icon-people"></i>
        </div>              
        <p>{ t('countmein.success.p1', 'Thank you for your support!') }</p>
        <p>{ t('countmein.success.p2', 'We are glad to be able to Count on You!') }</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookFormInput 
        name='name.first' 
        placeholder='First Name'
        label='First Name'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='name.last' 
        placeholder='Last Name'
        label='Last Name'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='email' 
        placeholder='Email Address'
        label='Email Address'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='phone' 
        placeholder='Phone Number'
        label='Cell Phone'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='company' 
        placeholder='Business Name'
        label='Company'
        form={_form}
        />
      <HookFormInput 
        name='zip' 
        placeholder='Zip Code'
        label='Zip Code'
        form={_form}
        />
      <HookFormInput 
        name='country' 
        placeholder='Your Country'
        label='Country'
        form={_form}
        />
      <button 
        disabled={errors.length || loading} 
        className={classNames('btn btn-primary mt-2', { loading })} 
        type="submit" 
        >
          Submit!
      </button>
    </form>
  );
}

const CountMeIn = () => {
  const { t } = useTranslation();
  
  return (
    <Page title='You Can Count on Me Edwin'>
      <HeaderBar title={t('countmein.heading', 'You Can Count on Me Edwin')} />
      <Container>
        <div className='text-center'>
          <blockquote>
            I am committing to personally help register 10 individuals before September 30th, 2021 for the Global Happiness Summit.
          </blockquote>
        </div>
        <Grid>
          <Row>
            <Col md={12}>
              <CountMeInForm />
            </Col>
          </Row>
        </Grid>
      </Container>
    </Page>
  )
}

export default CountMeIn;