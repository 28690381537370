
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Category } from "../components/CategorySelect";
import { Place } from "../components/LocationSearch";
import latlon2hex from '../tools/latlon2hex';

const useGoToListingSearch = (category?: Category, place?: Place): [() => void, boolean] => {
  const history = useHistory();

  const canGo = Boolean(category && place);

  const go = useCallback(() => {
    if (category && place) {
      if (place.zip) {
        history.push(`/search/${place.zip}/${category.slug}`, {
          zip: place.zip,
          place,
          category
        });
      } else if (place.location) {
        const latlon = latlon2hex(place.location.lat, place.location.lon);
        history.push(`/search/${latlon}/${category.slug}`, {
          latlon,
          place,
          category
        });
      }
    }
  }, [category, place, history]);

  return [go, canGo];
}

export default useGoToListingSearch;