
import React, { useRef, useState, useCallback } from 'react';
import TagsInput from 'react-tagsinput';
import { withFormsy } from 'formsy-react';

const VALID_ZIP_REGEX = /\b\d{5}\b/;

function ZipsInput({ getValue, setValue }) {
  const input = useRef();
  const [text, setText] = useState('');

  const handleChange = useCallback((tags, changed, changedIndexes) => {
    setValue(tags);
  }, [setValue]);

  const handleChangeText = useCallback(text => {
    text = text.replace(/\D/g, '').substr(0, 5);

    if (text.match(VALID_ZIP_REGEX)) {
      if (input.current) {
        input.current.addTag(text);
      }
    } else {
      setText(text);
    }
  }, []);

  return (
    <TagsInput
      ref={ref => input.current = ref}
      value={getValue() || []}
      inputValue={text}
      validationRegex={VALID_ZIP_REGEX}
      onChange={handleChange}
      onChangeInput={handleChangeText}
      renderTag={ZipTag}
      renderLayout={(tags, input) => (
        <div className='form-group'>
          { input }
          { tags }
        </div>
      )}
      inputProps={{
        className: 'form-input my-2 col-3 col-sm-12',
        style: { display: 'inline' },
        type: 'text',
        placeholder: 'Add a Zip Code'
      }}
      addKeys={[9, 13, 32]}
      removeKeys={[]}
      addOnBlur
      onlyUnique
      />
  );
}

const ZipTag = (props) => {
  const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...rest } = props;
  const remove = () => onRemove(key);

  return (
    <span key={key} {...rest} className='label label-rounded label-primary p-2 m-2'>
      { getTagDisplayValue(tag) } <i className='icon icon-cross hover-scale' onClick={remove} />
    </span>
  );
}

export default withFormsy(ZipsInput);
