
import React from 'react';
import classNames from 'classnames';

interface HiderProps {
  hidden?: boolean;
  children: any;
  className?: string;
}

const Hider = ({ hidden = false, children, className, ...props }: HiderProps) => (
  <div className={classNames(className, { 'd-none' : hidden})} {...props}>
    { children }
  </div>
);

export default Hider;