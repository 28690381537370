
import React from 'react';

const Success = ({ success, title, message, children, action, onAction, ...props }) => {
  if (success) {
    return (
      <div className="empty" {...props}>
        <div className="empty-icon">
          <i className="icon icon-3x icon-check"></i>
        </div>
        <p className="empty-title h5">{ title || 'Success' }</p>
        <p className="empty-subtitle">{ message }</p>
        { onAction && 
          <div className="empty-action">
            <button 
              className="btn btn-primary" 
              onClick={onAction}
              >
                {action || 'Done'}
            </button>
          </div>
        }
      </div>
    );
  }

  return children
}

export default Success;