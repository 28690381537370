
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendForm } from '../../../API';
import HookFormInput from '../../../components/form/HookFormInput';
import HookFormRadio from '../../../components/form/HookFormRadio';
import HookFormSelect from '../../../components/form/HookFormSelect';

const DeepDiveForm = ({ onClose = () => {}}) => {
  const[submitted, setSumbitted]=useState<boolean>(false);
  const _form = useForm({
    mode: 'onBlur'
  });
  
  // const { formState } = _form;

  const onSubmit = async (data: any) => {
    console.log('Ask HNP Form', { data });
    const result = await sendForm('deep-dive', data);
    console.log('Result', { result })
    setSumbitted(true);
  };

  if (submitted) {
    return (
      <div className='text-center'>
        <h4>Thank Your For Submitting Your Information, We Will Contact You Soon.</h4>
        <div className='divider' />
        <button className='btn btn-lg' onClick={onClose}>Close</button>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={_form.handleSubmit(onSubmit)}>
        <HookFormRadio 
          form={_form}
          items={['Yes', 'No, but I would like to become one to attend the HNP Business Deep Dive session']}
          name='is_happy_business'
          label='Are you a verified Happy Business Member?'
          errorMessage='Answer Required'
          formProps={{
            required: true
          }}
          />
        <HookFormSelect 
          form={_form}
          items={[
            'October 13th, 1:00-4:00pm (Pacific)',
            'November 10th, 1:00-4:00pm (Pacific)',
            'December 8th, 1:00-4:00pm (Pacific)'
          ]}
          name='month_to_attend'
          label='Which month would you like to attend?'
          placeholder='Please select a month...'
          hasOther={false}
          errorMessage='Answer Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='first_name'
          label='First Name'
          placeholder='Your First Name'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='last_name'
          label='Last Name'
          placeholder='Your Last Name'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='company'
          label='Company Name'
          placeholder='The Name of Your Business'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='phone'
          label='Phone'
          placeholder='Your Cell Phone Number'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='email'
          label='Email'
          placeholder='Your Email Address'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <div className='divider' />
        <small>
          <em>
            Your credit will still be used if you no show the HNP Business Deep Dive session.<br />
            Exceptions are rarely made.
          </em>
        </small>
        <div className='divider' />
        <button 
          type='submit' 
          className='btn btn-lg float-right my-2'>
            Submit
        </button>
      </form>
    </div>
  );
}

export default DeepDiveForm;