
import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { getAccountManager_V2_DEPRECATED, registerBusiness } from '../API.js';
import Steps from '../components/Steps.js';
import CompanyInfoForm from '../components/CompanyInfoForm.js';
import Hider from '../components/Hider';
import SelectZipsForm from '../components/SelectZipsForm.js';
import PaymentInformationForm from '../components/PaymentInformationForm.js';
import Divider from '../components/Divider.js';

// TODO make one way business form
// TODO make payment form separate put coupon there
// TODO use spectre steps

const Join = () => {
  const { slug } = useParams();
  const [step, setStep] = useState(0);
  const [referrer, setReferrer] = useState();
  const [business, setBusiness] = useState();
  const [zips, setZips] = useState([]);
  const [payment, setPayment] = useState();
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getAccountManager_V2_DEPRECATED(slug).then(referrer => {
      setReferrer(referrer);
    });
  }, [slug]);

  const onNext = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  const onPrev = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  const register = useCallback(() => {
    setProcessing(true);
    setError(null);
    registerBusiness({
      business, referrer, zips, payment
    }).then(({error, ...result }) => {
      setProcessing(false);
      if (!error) {
        setResult(result);
        setStep(3);
      } else {
        console.error('join payment', error);
        setError(typeof error === 'string' ? error : 'An error occurred.');
        setProcessing(false);
      }
    }).catch(error => {
      console.error('join payment', error);
      setError('An error occurred.');
      setProcessing(false);
    });
  }, [business, referrer, zips, payment]);

  return(
    <Page title='Become a Verified Happy Business'>
      <HeaderBar title='Become a Verified Happy Business' />
      <Container>
        <Steps 
          current={step} 
          steps={['Company Information', 'Zip Codes', 'Payment', 'Success!']}
          />
        <Divider />
        <Hider hidden={step !== 0} className='col-8'>
          <CompanyInfoForm onChange={setBusiness} onNext={onNext} />
        </Hider>
        <Hider hidden={step !== 1} className='col-8'>
          <SelectZipsForm onChange={setZips} onNext={onNext} onPrev={onPrev} />
        </Hider>
        <Hider hidden={step !== 2} className='col-8'>
          <PaymentInformationForm
             zips={zips} 
             onChange={setPayment} 
             onNext={register} 
             onPrev={onPrev} 
             processing={processing}
             error={error}
             />
        </Hider>
        <Hider hidden={step !== 3} className='col-8'>
          { result &&
            <div className='empty'>
              <div className='empty-icon'>
                <i className='icon icon-3x icon-mail'></i>
              </div>
              <p className='empty-title h5'>Welcome to the Neighborhood!</p>
              <p className='empty-subtitle'>Welcome <em>{result.business.company}</em> you should receive your confirmation email at '{result.business.email}' shortly.</p>
            </div> 
          }
        </Hider>
      </Container>
    </Page>
  );
}

export default Join;