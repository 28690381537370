
import React, { useEffect } from 'react';
import useCalls from '../data/useCalls';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import { Disclaimer } from './NetworkingLanding';
import { useParams } from 'react-router-dom';
import NetworkingRegistrationForm from '../components/NetworkingRegistrationForm';
import { Helmet } from 'react-helmet';

const Block = ({ backgroundColor = '#000A', children }: { backgroundColor?: string, children: React.ReactChild | React.ReactChildren }) => (
  <Container style={{ minHeight: '90vh', backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    { children }
  </Container>
);

const NetworkingCallRegistration = () => {
  const { slug } = useParams<{ slug: string }>();
  const calls = useCalls();
  const call = calls.find(call => call.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP - Networking' className='background background-networking-2 text-light'>
      <Disclaimer />
      <Block>
        { !!call ? 
          <div className='col-8 col-md-10 col-sm-12 text-left'>
            <Helmet>
              <title>HNP Networking: { call?.location }</title>
              <meta property="og:title" content={`Happy Networking: ${call.location}`} />
              <meta property="og:url" content={`https://happyneighborhoodproject.com/networking/register/${call.slug}`} />
              <meta property="og:description" content={`Online Happy Networking call/meeting for ${call.location}!`} />
            </Helmet>
            <NetworkingRegistrationForm call={call} /> 
          </div> :
          <div />
        }
      </Block>
    </Page>
  );
}

export default NetworkingCallRegistration;