
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useZip } from '../../HnpContext';
import cleanZip, { isZipValid } from '../../tools/cleanZip.js';

const ZipInput = ({ emitInitial, onChange, defaultValue = '', ...props }) => {
  const [zip, setZip] = useZip();
  const [value, setValue] = useState(zip || defaultValue);
  const [changed, setChanged] = useState(Boolean(emitInitial));
  const valid = useMemo(() => isZipValid(value), [value]);

  useEffect(() => {
    setValue(value => value || zip);
  }, [zip]);

  useEffect(() => {
    if (changed && zip) {
      setChanged(false);
      onChange && onChange(zip);
    }
  }, [zip, changed, onChange]);

  const onChangeText = useCallback((e) => {
    const _zip = cleanZip(e.target.value);

    setValue(_zip);

    if (isZipValid(_zip) && _zip !== zip) {
      setZip(_zip);
      setChanged(true);
    }
  }, [zip, setZip]);

  return (
    <div style={{height: 'min-content'}} className={classNames('input-group has-icon-right', { 'has-success': valid })}>
      <span className="input-group-addon">Zip Code</span>
      <input 
        type='text' 
        className='form-input' 
        placeholder='Enter Zip...' 
        value={value} 
        onChange={onChangeText}
        {...props}
        />
      <i className={classNames('form-icon icon', { 'icon-check': valid })} />
    </div>
  );
}

export default ZipInput;