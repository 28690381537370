
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';

function LET() {

  return (
    <Page title='Leadership Experience Tour'>
      <Container>
        <h1>Our newest partner!</h1>
        <p>The Leadership Experience Tour is the #1 platform for entrepreneurs looking to take their business to the next level. Shawn Fair gives you access to resources, tools, and supportive services needed to succeed. Secure your spot, get immediate branding, and choose your payment plan.</p>
        <p><a href='https://qrco.de/LETSecure' target='_blank' rel='noopener noreferrer'>Secure Your Spot</a></p>
        <p><a href='https://qrco.de/LETPlan' target='_blank' rel='noopener noreferrer'>Select a Plan</a></p>
      </Container>
    </Page>
  );
}

export default LET;