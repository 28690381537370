
import React, { useState } from 'react'
import Faq from '../UI/single-faq/single-faq'
import classes from './faq.module.css'
import { Link } from 'react-router-dom';

const Faqs = () => {
    const [visibility, setVisibility] = useState(
        {
            key: 1,
            visible: true
        }
    )
    const faqsItm = [
        { key: 1, titel: 'What is the difference between a Free Member & a Share-Member', body: <>Free Members gets to attend our networking events, Master Classes, and earn points. A Share-Member can do all of those things in addition to earning one Vote, get special access, and if they are also a <Link to='/membership'>Verified Happy Business</Link>, they will earn Revenue Sharing from the points they generate.</> },
        { key: 2, titel: 'Where does HNP revenue come from for the CoOp revenue sharing pool?', body: 
          <>
            While we provide many a few free services, we still generate income from from multiple sources, including but not limited to our Verified Happy Business membership, corporate partnerships (Dell, Thryv, etc.) &amp; relationships with companies such as Zoom. 
            <br /><br />
            As a CoOp, we are positioned to secure more partnerships that will benefits our members and generate more revenue for the CoOp plus we have a Happy Store in the works!
          </> },
        { key: 3, titel: 'How do I earn points?', body: <>You can see the ways to earn points <Link to='/coop/points'>here</Link>.</> },
        { key: 4, titel: 'How do I earn Revenue Sharing?', body: 'First, you must be a Share-Member. Second, you must be a Verified Happy Business. You may earn points through activities listed on hnpabc.com/points. If you have points at the end of each quarter, you will be able to earn Revenue Sharing "Pro-Rata" based off of the pool. The more points you earn the more revenue sharing you earn from the Sharing-Pool!' },
        { key: 5, titel: 'How much do I earn in Revenue Sharing by being a Share-Member?', body: 'It is based off of the amount of points that you have. Sharing points only determine the "Pro-Rata" share of the Sharing Pool that each qualified Share-Member may earn each quarter.' },
        { key: 6, titel: 'How much does it cost to be a Share-Member? ', body: '$197 one-time payment (Lifetime Membership Fee).' },
        { key: 7, titel: 'When do I earn Revenue Sharing? ', body: <>If you are a Share-Member &amp; <Link to='/membership'>Verified Happy Business</Link> and have also accumulated points, Revenue Sharing is paid out quarterly and annually (5 payments per year).</> },


    ]
    const toggleHandler = (key) => {
        setVisibility({ key: key, visible: true })
    }
    const singleFaq = faqsItm.map((itm) => {
        if (visibility.key === itm.key) {
            return (<Faq clicked={() => { toggleHandler(itm.key) }} visible key={itm.key} titel={itm.titel} body={itm.body} />)
        }
        else {
            return (<Faq clicked={() => { toggleHandler(itm.key) }} key={itm.key} titel={itm.titel} body={itm.body} />)
        }
    }

    )
    return (
        <section id={classes.faqs}>
            <div className={[classes.container, "container"].join(" ")}>
            <h2 className={classes.sectitel}>FAQ's</h2>
                {singleFaq}
            </div>
        </section>
    )
}
export default Faqs