
import React from 'react';
import { useMemo } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Link } from 'react-router-dom';

const countdownRenderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (!completed) {
    return (
      <h2 style={{ color: 'purple' }}>
        <strong>{days ? `${days} days, ` : ''}{hours} hours, {minutes} minutes &amp; {seconds} seconds</strong> until our 2 Year Anniversary Worldwide Meeting!<br />
        One lucky co-op member in attendance will receive a new DELL laptop!<br />
        <Link to='/networking/register/worldwide'>Register for our July 7th meeting!</Link>
      </h2>
    );
  }

  return null;
};

const date = new Date('2023-07-07T11:00:00.000-0700');

export default function AnniversaryCountdown() {

  const renderer = useMemo(() => countdownRenderer, []);

  return (
    <Countdown 
      date={date}
      daysInHours
      renderer={renderer}
      />
  );
}