
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const Ineligible = () => {

  return (
    <Page title='HNP - Business Ineligible'>
      <HeaderBar>
        <h1>Not Eligible For Membership</h1>
        <h4>At this time.</h4>
      </HeaderBar>
      <Container style={{textAlign: 'center'}}>
        <p>Based on your responses, your business is not eligible for membership at this time. However, we will like to work with your business to increase the culture of happiness at work so you can become eligible for membership.</p>
        <p>Send us an email at <a href='mailto:support@hnpabc.com'>support@hnpabc.com</a> and one of our consultants will get back to you.</p>
      </Container>
    </Page>
  )
}

export default Ineligible;