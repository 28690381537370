
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getListingByFriendlyId } from '../API.js';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Divider from '../components/Divider.js';
import EventItem from '../components/EventItem.js';
import ExternalLinkify from '../components/ExternalLinkify.js';
import Video from '../components/Video.js';
import ContactInfo from '../components/ContactInfo.js';
import ShareAndFeedback from '../components/ShareAndFeedback.js';
import nl2br from '../tools/nl2br.js';

const Listings = () => {
  const { friendly_id } = useParams();
  const location = useLocation();
  const [listing, setListing] = useState(location && location.state ? location.state.listing : {});
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const hasLogo = listing.logo && listing.logo.url;
  const hasVideo = listing.video && listing.video.url;

  useEffect(() => {
    setLoading(true);

    getListingByFriendlyId(friendly_id).then(result => {
      const { listing, events } = result;

      listing && setListing(listing);
      events && setEvents(events);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });
  }, [friendly_id]);

  return (
    <Page title={`HNP - Listing, ${listing.name} in ${listing.zip}`}>
      <HeaderBar title={listing.name} />
      <Container style={{textAlign: 'center'}}>
        { loading && 
          <p>{ loading ? 'Loading...' : '' }</p>
        }
        <div className='columns'>
          <div className='column col-sm-12 text-center'>
            <h4><em>{listing.blurb}</em></h4>
            { hasLogo && 
              <img 
                src={listing.logo.url} 
                alt={`Logo for ${listing.name}`} 
                style={{ maxWidth: '100%', height: 'auto' }} 
                />
            }
            { hasLogo && hasVideo &&
              <Divider />
            }
            { hasVideo && 
              <div style={{paddingLeft: '3em', paddingRight: '3em', margin: '1em'}}>
                <Video src={listing.video.url} aspect={listing.video.aspect} />
              </div>
            }
          </div>
          <Divider vertical />
          <div className='column col-sm-12'>
            <ShareAndFeedback listing={listing} />
            <Divider />
            <ContactInfo info={listing} />
            <Divider />
            <p style={{ textAlign: 'left' }}>
              <ExternalLinkify>
                { nl2br(listing.description) }
              </ExternalLinkify>
            </p>
            <Divider />
          </div>
        </div>
        { events && Boolean(events.length) && 
          <div>
            <Divider />
            <h3>Events</h3>
            { events.map(event => (
              <div key={event.uuid}>
                <Divider />
                <EventItem event={event} />
              </div>
            )) }
          </div> 
        }
      </Container>
    </Page>
  );
}

export default Listings;