
import React from 'react';
import Page from '../components/layout/Page';
import { Video } from '@react-spectre/media';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ExternalLink from '../components/ExternalLink.js';

const youtubeLink = <ExternalLink href='https://youtu.be/0mWCwfpotYE'>YouTube</ExternalLink>;
const kvieLink = <ExternalLink href='https://vids.kvie.org/video/happy-neighborhood-project-grvokx/'>KVIE.org</ExternalLink>;

const PBS = () => {
  return (
    <Page title='HNP - PBS (KVIE) Arts Showcase'>
      <HeaderBar title='PBS (KVIE) Arts Showcase' />
      <Container style={{textAlign: 'center'}}>
        <Video.Container responsive> 
          <iframe title='PBS (KVIE) Arts Showcase' src="https://www.youtube.com/embed/cBh0QOkiw3U?rel=0&autoplay=1" />
        </Video.Container>
        <p style={{fontSize: '2em', marginTop: '.5em'}}>
          Watch the entire episode on {youtubeLink} or at {kvieLink}!
        </p>
      </Container>
    </Page>
  )
}

export default PBS;