
import dateFormat from 'dateformat';
import React, { useEffect, useMemo } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import Page from '../../components/layout/Page';
import Video from '../../components/Video';
import useCoopReferrer from '../../data/useCoopReferrer';
import useHopinReferralLink from '../../data/useHopinReferralLink';
import FacebookPixel from '../../tools/FacebookPixel';
// import GHSSignup from './forms/GHSSignup';
// import GHSVendorSignup from './forms/GHSVendorSignup';
import './index.css';

const countdownRenderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (!completed) {
    return (
      <span className='text-warning'>
        in {days ? `${days} days, ` : ''}{hours} hours, {minutes} minutes &amp; {seconds} seconds
      </span>
    );
  }

  return null;
};

const GHS2021Page = () => {
  const [referrer] = useCoopReferrer();
  const hopin_link = useHopinReferralLink();

  // const [vendor, setVendor] = useState(false);

  const [start, end] = useMemo(() => {
    const _start = new Date(1696608000000);
    const _end = new Date(1696629600000);

    return [_start, _end];
  }, []);

  useEffect(() => {
    console.log({ referrer });
  }, [referrer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='Global Happiness Summit 2023' className='landing-page'>
      <div className='container'>
        <FacebookPixel id={2662239060715252} event='ViewContent' />
        <div className='text-center mb-2' style={{ marginTop: '2em' }}>
          <h3 style={{ color: 'rgb(255, 214, 0)' }}>The Happy Neighborhood Project &amp;<br />Happy CoOp present:</h3>
          <h1 className='mb-1' style={{ color: 'rgb(87, 85, 217)' }}>The 2023 Global Happiness Summit</h1>
          <h4 className='mb-1'>{dateFormat(start, 'dddd, mmmm dS, yyyy "@" h:MMtt')} - {dateFormat(end, 'h:MMtt Z')}</h4>
          <Countdown 
            date={start} 
            renderer={countdownRenderer}
            /><br />
          <a href={hopin_link} target='_blank' rel='noopener noreferrer' className='btn mb-2 mr-2'>
            Purchase Event Ticket / Vendor Package
          </a>
        </div>
        <Video src='https://dubb.sfo2.digitaloceanspaces.com/videos/2022-08-25/fc90030e926889d0a2af108a778c93b5/720p_aY1r.mp4' />
        <div style={{ marginTop: '2em' }}>
          <h2 style={{ color: 'rgb(87, 85, 217)' }}>What is the Global Happiness Summit?</h2>
          <p>The Global Happiness Summit is a virtual annual event where business meets happiness. It was created to bring happy and positive entrepreneurs together to network, learn from speakers, and spread happiness. In just a few years, the Global Happiness Summit is the world's most happy business event!</p>
        </div>
        <div>
          <h2 style={{ color: 'rgb(87, 85, 217)' }}>Why attend?</h2>
          <p>
            <strong>Mega Networking (over 700 attendees last year, aiming for over 1,000 this year)</strong><br />
            Build your network with hundreds of happy business people!
          </p>
          <p>
            <strong>Giveaways</strong><br />
            Get a chance to win awesome prizes (cash &amp; more) just by attending!
          </p>
          <p>
            <strong>Educational Business Speakers</strong><br />
            Expand your skills from industry experts!
          </p>
          <p>
            <strong>Happiness Exercises</strong><br />
            Boost your happiness and learn from happiness experts!
          </p>
        </div>
        <div className='p-2'>
          <h2 style={{ color: 'rgb(87, 85, 217)' }}>What are people saying?</h2>
          <figure>
            <blockquote>
              "The Happy Neighborhood project is awesome! I met 53 prospects while attending last year's [event] and knew this was the place to be.This year I will be a first-time vendor, it's exciting and meeting even more prospects in a group session as a vendor.<br /><br />
              Last Year,  I saw the vision and wanted to be involved so I signed up as the first Co-op member of this organization.Their idea made sense for me - after all we are in business together! Get involved and watch your business take off!"</blockquote>
            <figcaption className='text-right'>Captain Joe A.</figcaption>
          </figure>
          <div className='divider' style={{ marginTop: '2em', marginBottom: '2em' }} />
          <figure>
            <blockquote>"I have done business with over 20 people that I met [at the Global Happiness Summit] where either I have opened my wallet to them, they have opened their wallet to me, or we have opened our wallets for each other."</blockquote>
            <figcaption className='text-right'>Gabriella W.</figcaption>
          </figure>
          <div className='divider' style={{ marginTop: '2em', marginBottom: '2em' }} />
          {/* <figure>
            <blockquote>"Since [participating], I have gotten a 4,910% ROI. Of course, you get what you put in, but I am a serial networker and I have NEVER gotten more clients than from Happy Neighborhood Project."</blockquote>
            <figcaption className='text-right'>Marc H.</figcaption>
          </figure> */}
          {/* <div className='divider' style={{ marginTop: '2em', marginBottom: '2em' }} /> */}
          <figure>
            <blockquote>"I was starting to feel depressed, but attending your events brought life back into me and my business."</blockquote>
            <figcaption className='text-right'>Adrian G.</figcaption>
          </figure>
        </div>
        <div className='text-center mb-2'>
          <a href={hopin_link} target='_blank' rel='noopener noreferrer' className='btn mb-2 mr-2'>
            Purchase Event Ticket / Vendor Package
          </a>
        </div>

        {/* <Link className='btn mb-2' to='/ghs/vendor'>
          Purchase Virtual Vendor Booth
        </Link> */}
        {/* <button className='btn mb-2' onClick={() => setVendor(v => !v)}>
          Purchase { Boolean(vendor) ? 'General Admission Ticket' : 'Virtual Vendor Booth'}!
        </button>
        <div className='panel mb-2'>
          <div id='purchase' className='panel-body'>
            { Boolean(vendor) ? (
              <GHSVendorSignup />
            ): (
              <GHSSignup />
            )}
          </div>
        </div> */}
        <div className='mb-2'>
          <h4>Check out the Hopin Virtual Conference Demo below</h4>
          <Video iframe title='Hopin Virtual Conference Demo' src='https://www.youtube.com/embed/JgGVOlbOPUU' />
        </div>
      </div>
    </Page>
  );
}

export default GHS2021Page;