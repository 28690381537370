
import { gql, useQuery } from '@apollo/client';

export interface Call {
  location: string;
  slug: string;
  meeting_id: number;
  passcode: string;
  registration_url: string;
  day: string;
  start_time: string;
  time: string;
  timezone: string;
  next: number;
  start?: Date;
  cohosts: Cohost;
}

export interface Cohost {
  meeting_id: number;
  name: string;
  type: 'COHOST' | 'DEPUTY' | 'ALUMNI'; // Should Alumni be a type or just use end date
  email: string;
  phone: string;
  started_at: string;
  ended_at: string;
  extra: {
    slackInvite: boolean;
    agendaScript: boolean;
    cohostManual: boolean;
    pendingActions: string;
    notes: string;
  }
}

type Calls = Array<Call>;

interface QueryResponse {
  meetings: Calls
}

const query = gql`
  query Meetings {
    meetings {
      meeting_id
      slug
      location
      start_time
      day
      time
      next
      registration_url
      cohosts {
        uuid
        name
      }
    }
  }
`;

const useCalls = () => {
  const { data } = useQuery<QueryResponse>(query);

  return data?.meetings?.slice() || [] as Calls;
}

export function dayToNumber(day: string): number {
  if (!day) return 0;
  switch(day.toLowerCase()) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    case 'sunday':
    default:
      return 0;
  }
}

export function getNextDayOfWeek(date: Date, dayOfWeek: number) {
  const result = new Date(date.getTime());
  result.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
  return result;
}

export function timeWithSecondsToDatePST(time: string, day: string) {
  if (!time || !day) {
    return new Date();
  }

  const today = new Date();
  const date = new Date(
    `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${time}.000-07:00`
  );
  const dotw = dayToNumber(day);
  return getNextDayOfWeek(date, dotw);
}

export default useCalls;