
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container.js';
import HeaderBar from '../components/layout/HeaderBar.js';
import ZipInput from '../components/form/ZipInput.js';
import { getEvents } from '../API.js';
import { useZip } from '../HnpContext.js';
import Divider from '../components/Divider.js';
import EventItem from '../components/EventItem.js';
import isZipValid from '../tools/cleanZip.js';

const Events = () => {
  const history = useHistory();
  const location = useLocation();
  const { zip: _zip } = useParams();
  const [zip] = useZip();
  const [events, setEvents] = useState([]);
  const [expandedEvents, setExpandedEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isZipValid(_zip)) {
      if (isZipValid(zip)) {
        history.replace(`/events/${zip}`);
      } else if (location.pathname !== '/events') {
        history.replace('/events');
      }
    }
  }, [zip, _zip, history, location]);

  useEffect(() => {
    if (_zip) {
      setLoading(true);
      getEvents(_zip).then(({ events, expandedEvents }) => {
        if (events) {
          setEvents(events);
          setExpandedEvents(expandedEvents || []);
        }

        setLoading(false);
      }).catch((error) => {
        console.error('Events', error);
        setLoading(false);
      });
    }
  }, [_zip]);

  const onChange = useCallback(zip => {
    if (location.pathname !== `/events/${zip}`) {
      history.replace(`/events/${zip}`);
    }
  }, [history, location]);

  return (
    <Page title='HNP - Community Events'>
      <HeaderBar title='Community Events' />
      <Container>
        <ZipInput defaultValue={_zip} onChange={onChange} loading={loading} />
        { loading &&
          <p>Loading...</p>
        }
        <DisplayEvents
          title={`Nearby Events`}
          events={events} 
          />
        <DisplayEvents
          events={expandedEvents} 
          />
      </Container>
    </Page>
  )
}

function DisplayEvents({ title, events }) {
  if (!events || !events.length) {
    return null;
  }

  return (
    <div>
        { title && 
          <h2 style={{ marginTop: '2rem' }}>{title}</h2>
        }
        { events.map((event, i) => (
          <div key={event.uuid}>
            <EventItem event={event} />
            <Divider />
          </div>
        ))}
    </div>
  );
}

export default Events;