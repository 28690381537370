
 // eslint-disable-next-line no-restricted-globals
export const DEBUG_MODE = (location.hostname === "localhost" || location.hostname === "127.0.0.1");

export const API_DEV_URL = 'http://localhost:3005';
// export const API_DEV_URL = 'https://hnp-api-dev.herokuapp.com';
export const API_URL ='https://api.hnpabc.com';
export const URL = DEBUG_MODE ? API_DEV_URL : API_URL;
const STRIPE_PUBLIC_KEY = DEBUG_MODE ? 'pk_test_wVcBMBlthusazJyMZPztJ6pz' : 'pk_live_6RBkVlHP5MIW0Z8ol4qA9Ubb';
  
export async function createStripeCardToken(number, month, year, cvc) {
  const body = toShallowFormBody({
    'card[number]': number.trim(),
    'card[exp_month]': month,
    'card[exp_year]': year,
    ...(cvc ? {'card[cvc]': cvc} : {})
  });

  const response = await fetch(`https://api.stripe.com/v1/tokens`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'Authorization': `Bearer ${STRIPE_PUBLIC_KEY}`
    },
    method: 'POST',
    body
  });

  return await response.json();
}

export async function getZoomLeaderboard() {
  return await _GET(`/v3/zoom/leaderboard/new`);
}

export async function registerZoomSubscription(data) {
  return await _POST(`/v3/zoom/subscription`, data);
}

export async function getCategories() {
  const { categories } = await cache('CATEGORIES', () => 
    _GET('/v3/categories')
  );

  return categories;
}

export async function getPricing(zips = [], coupon = '') {
  const hash = `?zips=${zips.join(',')}&coupon=${coupon}`;
  return await _GET(`/v3/pricing${hash}`);

  // return await cache('PRICING' + hash, () => 
  //   _GET(`/v3/pricing${hash}`)
  // );
}

export async function getRemotePricing(coupon = '') {
  const hash = `?coupon=${coupon}`;
  return await _GET(`/v3/pricing/remote${hash}`);

  // return await cache('REMOTE_PRICING' + hash, () => 
  //   _GET(`/v3/pricing/remote${hash}`)
  // );
}

export async function getZipAvailability_V2_DEPRECATED(zip, category_uuid) {
  return await _GET(`/v2/listings/categories/${category_uuid}/${zip}`);
}

export async function getCalls() {
  return await _GET(`/v3/zoom/meetings`);
}

export async function getListingsBySlug(zip, slug) {
  return await cache(`LISTINGS_BY_SLUG_${zip}_${slug}`, () => 
    _GET(`/v3/listings/${zip}?slug=${slug}`)
  );
}

export async function getListingByFriendlyId(friendly_id) {
  return await cache(`LISTINGS_${friendly_id}`, () =>
    _GET(`/v3/listings/friendly/${friendly_id}`)
  );
}

export async function getEvents(zip) {
  return await cache(`EVENTS_${zip}`, () =>
    _GET(`/v3/events/zip/${zip}`)
  );
}

export async function registerBusiness({business, referrer, zips, payment}) {
  return await _POST('/v3/businesses', {
    business, referrer, zips, payment
  });
}

export async function sendReferral(info) {
  return await _POST('/v3/contact/refer', info);
}

export async function sendNumberGuess(info) {
  return await _POST('/v3/contact/number', info);
}

export async function sendSurveyData(name, data) {
  return await _POST('/v3/contact/survey', {
    name, data
  });
}

export async function sendFeedback(feedback) {
  return await _POST('/v3/contact/feedback', feedback);
}

export async function sendCohostInfo(info) {
  return await _POST('/v3/contact/cohost', info);
}

export async function sendBirthday(info) {
  return await _POST('/v3/contact/birthday', info);
}

export async function sendForm(form, data) {
  return await _POST('/v3/contact/form', { form, data });
}

export async function sendSummitSponsorRequest(info) {
  return await _POST('/v3/contact/summit/sponsor', info);
}

export async function sendCountMeInfo(info) {
  return await _POST('/v3/contact/countmein', info);
}

export async function sendClub100Info(info) {
  return await _POST('/v3/contact/club-100', info);
}

export async function getNearestZip_V2_DEPRECATED(lat, lon, distMiles = 5) {
  return await _GET(`/v2/zips/nearest?lat=${lat}&lon=${lon}&distanceInMiles=${distMiles}`);
}

export async function getAccountManager_V2_DEPRECATED(slug) {
  return await cache(`ACCOUNT_MANAGERS_${slug}`, () =>
    _GET(`/v2/accounts/am/${slug}`)
  );
}

// HELPER METHODS

const store = {};
async function cache(key = 'CACHE', getter, options = { timeout: 1000 * 60 * 5 }) {
  if (!options.bust && store[key] && store[key].time > Date.now() - options.timeout) {
    return store[key].value;
  }

  const value = await getter();

  store[key] = {
    value,
    time: Date.now()
  };

  return value;
}

function toShallowFormBody(body) {
  return Object.keys(body).map(key => 
    encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
  ).join('&');
}

export const _GET = (endpoint, options) => 
  fetch(`${URL}${endpoint}`, options)
    .then(r => r.json());

export const _POST = (endpoint, body, options) =>
  fetch(`${URL}${endpoint}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body),
    ...options
  }).then(r => r.json());