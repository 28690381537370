
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useSearch(key: string) {
  const { search } = useLocation();

  return useMemo(() => {
    return new URLSearchParams(search).get(key);
  }, [key, search]);
}