
import React, { useCallback, useEffect, useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ExternalLink from '../components/ExternalLink.js';
import copyToClipboard from '../tools/copyToClipboard.js';
import classNames from 'classnames';

const links = [{
  info: 'GoBrunch Gourmet Plan',
  url: 'https://checkout.gobrunch.com/b/eVacNddpz2dH74Y009?prefilled_promo_code=HNP24'
},{
  info: 'GoBrunch Prime Chef Plan',
  url: 'https://checkout.gobrunch.com/b/dR6aF51GRf0tfBu8wG?prefilled_promo_code=HNP24'
},{
  info: 'The Great Discovery Program',
  url: 'https://hnpabc.com/tgd'
},{
  info: 'Knocard',
  url: 'https://allabouthnp.com/'
},{
  info: 'Happy Sales Flow',
  url: 'https://happyneighborhoodproject.com/salesflow'
}, {
  info: 'To apply to be a verified happy business member',
  url: 'https://happyneighborhoodproject.com/membership'
}, {
//   info: 'Zoom discounted enterprise deal',
//   url: 'https://happyneighborhoodproject.com/zoom'
// }, {
  info: 'Apply to be a Co-Host',
  url: 'https://happyneighborhoodproject.com/cohost'
}, {
//   info: 'Global Happiness Summit',
//   url: 'https://happyneighborhoodproject.com/summit'
// }, {
//   info: 'Count Me In (Help Register Attendees)',
//   url: 'https://happyneighborhoodproject.com/countmein'
// }, {
//   info: 'Online Networking landing page with FAQ’s, testimonials, instructions, and registrations links',
//   url: 'https://happyneighborhoodproject.com/network'
// }, {
//   info: 'Direct link to register for HNP networking events (no FAQ’s, instructions, or testimonials)',
//   url: 'https://happyneighborhoodproject.com/networking'
// }, {
//   info: 'Our Merchandise Shop',
//   url: 'https://happyneighborhoodproject.com/shop'
// }, {
  info: 'Let us know if you have given or received business from other HNP networkers and/or HNP verified happy businesses so we can highlight you',
  url: 'https://happyneighborhoodproject.com/collab'
}, {
  info: 'Dell discount deal',
  url: 'https://hnpabc.com/Dell'
}, {
  info: 'ORL Care',
  url: 'https://hnpabc.com/ORL'
}, {
  info: 'Grant Cardone Licensee',
  url: 'https://hnpabc.com/GCL'
}, {
  info: 'Engage Pro',
  url: 'https://hnpabc.com/EP'
}, {
//   info: 'Sell Your Mac promos',
//   url: 'https://hnpabc.com/sym'
// }, {
//   info: 'Clever RX',
//   url: 'https://hnpabc.com/cleverrx'
// }, {
//   info: 'Creative 7 Designs',
//   url: 'https://happyneighborhoodproject.com/creative7designs'
// }, {
  info: '4MyCard',
  url: 'https://happyneighborhoodproject.com/4mycard'
}, {
//   info: 'Happy Sales Flow',
//   url: 'https://happyneighborhoodproject.com/salesflow'
// }, {
  info: 'Let us know when your birthday is so we can celebrate it with you',
  url: 'https://happyneighborhoodproject.com/birthday'
// }, {
//   info: 'Facebook HNP Online Networking Group',
//   url: 'https://www.facebook.com/groups/hnpOnlineNetworking'
}];

const Links = () => {
  const [copied, setCopied] = useState();

  const copy = useCallback((text) => async () => {
    const success = await copyToClipboard(text);

    if (success) {
      setCopied(text);
    }
  }, [setCopied]);

  useEffect(() => {
    const timeout = setTimeout(() => 
      setCopied()
    , 1500);

    return () => clearTimeout(timeout);
  }, [copied, setCopied]);

  return (
    <Page title='HNP - Links'>
      <HeaderBar title='Links' />
      <Container>
        <p className='text-center'>
          An index of some of our useful links!
          <br />
          Just copy and share with your friends/network!
        </p>
        <ol>
            { links.map((link, i) => 
              <li key={i} className='py-2'>
                  <ExternalLink href={link.url}>
                    { link.info }
                  </ExternalLink>
                  <div className='input-group m-2'>
                    <input className='form-input' value={link.url} />
                    <button className='btn btn-primary input-group-btn' onClick={copy(link.url)}>
                      <i className={classNames('icon', copied === link.url ? 'icon-check' : 'icon-copy')} /> Copy
                    </button>
                  </div>
              </li>
            )}
        </ol>
      </Container>
    </Page>
  )
}

export default Links;