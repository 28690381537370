
import React from 'react';
import { Link } from 'react-router-dom';
import { CoopListing } from '../hnp-graphql';
import LinesEllipsis from 'react-lines-ellipsis';

interface ListingCardProps {
  listing: CoopListing;
}

function ListingCard({ listing }: ListingCardProps) {
  const { uuid, name, blurb, logo } = listing;

  return (
    <Link to={`/listings/${uuid}`} className='card'>
      <div className='card-image' style={{ display: 'flex' }}>
        { Boolean(logo) ? (
          <img 
            alt={`${name} Logo`}
            src={`https://res.cloudinary.com/hnp-api/image/upload/c_fill,h_250,w_250/${logo}`} 
            height={100} 
            width={100} 
            />
        ): (
          <div style={{ height: 100, width: 100, backgroundColor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ fontSize: 40 }}>{ name?.charAt(0) }</span>
          </div>
        )}
        <div className='card-header' style={{ flexGrow: 1, padding: '0.5rem' }}>
          <div className='card-title h5'>
            <LinesEllipsis text={name || ''} />
          </div>
          <div className='card-subtitle text-gray'>
            <LinesEllipsis 
              text={blurb || ''} 
              maxLine={2} 
              basedOn='letters'
              />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ListingCard;