
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const query = gql`
  query GetEvent($uuid: ID!) {
    event(uuid: $uuid) {
      title
      instructor
      description
      url
      start
    }
  }
`;

const ClassPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data } = useQuery(query, {
    variables: { uuid }
  });

  const event = data?.event;

  return (
    <Page title='HNP - Master Class'>
      <HeaderBar title='Master Class' />
      <Container>
        <div className='card'>
          <div className='card-body'>
            <h3>{ event?.title }</h3>
            <h4>{ event?.instructor }</h4>
            <hr />
            { Boolean(event?.start) && (
              <p>Time: { format(new Date(event?.start), 'MMM do, yyyy h:mma') }</p>
            )}
            <hr />
            <ReactMarkdown>{ event?.description }</ReactMarkdown>
            { Boolean(event?.url) && (
              <>
                <hr />
                <a href={event?.url} target='_blank' rel='noreferrer noopener'>Register</a>
              </>
            )}
          </div>
        </div>
      </Container>
    </Page>
  );
}

export default ClassPage;