
import React, { useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import Formsy from 'formsy-react';
import Input from './form/Input.js';
import TextArea from './form/TextArea.js';
import Radio from './form/Radio.js';
import Success from './Success.js';
import { sendFeedback } from '../API.js';
import Divider from './Divider.js';

const FeedbackDisplay = ({ listing, visible, onClose }) => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const form = useRef();

  const feedbackPlaceholder = `What feedback would you like to leave for ${listing.name}? Feedback could be positive information or suggestions to resolve any challenges. Whatever you wish to say.`;

  const _onClose = useCallback(() => {
    form.current && form.current.reset({});
    setSuccess(false);
    setLoading(false);
    onClose && onClose();
  }, [onClose]);

  const submit = useCallback((feedback) => {
    setLoading(true);

    sendFeedback(feedback).then(result => {
      setSuccess(result.success);
      setLoading(false);
    }).catch((error) => {
      console.error('[Error] Send Feedback', error);
      setLoading(false);
    });
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className='card my-2'>
      <div className='card-header'>
        <button className='btn btn-clear float-right' onClick={_onClose} aria-label='Close' />
        <div className='modal-title h1'>Give Feedback!</div>
      </div>
      <div className='card-body'>
        <Success 
          success={success} 
          title='Sent'
          message='Feedback Successfully Sent!'
          action='Close' 
          onAction={_onClose} 
          style={{ margin: '1.5rem 1rem' }}
          >
          <Formsy 
            ref={r => form.current = r} 
            className='form-horizontal' 
            onValidSubmit={submit} 
            onValid={() => setValid(true)} 
            onInvalid={() => setValid(false)}
            >
            <Input name='listing_uuid' type='hidden' value={listing && listing.uuid} />
            <Input name='listing_name' type='hidden' value={listing && listing.name} />
            <Input name='account_uuid' type='hidden' value={listing && listing.account_uuid} />
            <Input name='name' label='Name' placeholder='Enter Your Name...' horizontal required />
            <Input 
              name='email' 
              label='Email' 
              placeholder='email@example.com' 
              validations='isEmail' 
              validationError='Must be a valid email.' 
              horizontal
              required 
              />
            <TextArea 
              name='message' 
              label='Feedback' 
              placeholder={feedbackPlaceholder} 
              rows={5}
              horizontal 
              required 
              />
            <Radio 
              name='rating' 
              label='Rating' 
              options={[1, 2, 3, 4, 5]} 
              horizontal
              />
            <Divider />
            <button className={classNames('btn btn-primary', { loading })} disabled={!valid}>Submit</button>
          </Formsy>
        </Success>
      </div>
    </div>
  );
}

export default FeedbackDisplay;