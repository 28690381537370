
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';

export interface Place {
  name: string;
  zip?: string;
  location?: {
    lat: number;
    lon: number;
  }
  city?: string;
  state?: string;
  country: string;
}

type OnSelectHandler = (place?: Place) => void;

interface LocationSearchProps {
  place?: Place;
  onSelect: OnSelectHandler;
}

const PlaceResult = ({ place, onSelect }: { place: Place, onSelect: OnSelectHandler }) => {

  return (
    <button className='btn btn-link btn-block' onClick={() => onSelect(place)}>
      { place.name }{' '}
      { Boolean(place.city) ?
        <small>{ place.city }, { place.state }</small> :
        <small>{ place.state }, { place.country }</small> 
      }
    </button>
  );
}

export default function LocationSearch({ place, onSelect = () => {} }: LocationSearchProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<Array<Place>>([]);
  const [lastSearched, setLastSearched] = useState(new Date());

  const updateResult = useCallback((features: Array<any>, searched: Date) => {
    if (searched > lastSearched) {
      setResult(features.map(item => {
        return {
          name: item?.properties?.name,
          location: {
            lon: item?.geometry?.coordinates[0],
            lat: item?.geometry?.coordinates[1]
          },
          city: item?.properties?.city,
          state: item?.properties?.state,
          country: item?.properties?.country
        };
      }));
    }
  }, [lastSearched]);

  const searchLocation = useMemo(() => debounce(444, async (query: string, limit: number = 5) => {
    const searchTime = new Date();
    setLastSearched(searchTime);
    setLoading(true);

    const response = await fetch(`https://photon.komoot.io/api/?q=${query}&limit=${limit}`);
    const result = await response.json();
    updateResult(result?.features, searchTime);

    setLoading(false);
  }), [updateResult]);

  useEffect(() => {
    if (!Number.isNaN(parseInt(query, 10)) && query.length <= 5) {
      if (query.length === 5) {
        const place = {
          name: `Zip Code: ${query}`,
          zip: query,
          country: 'United States'
        };

        setResult([place]);
      }
    } else if (query) {
      searchLocation(query);
    } else {
      setResult([]);
    }
  }, [query, searchLocation]);

  if (place) {
    return (
      <span className='chip'>
        { place.name }
        <button className='btn btn-clear' aria-label='Close' onClick={() => onSelect()}></button>
      </span>
    );
  }

  return (
    <div className='popover popover-bottom' style={{ width: '100%', padding: '.1em'}}>
      <input 
        className='form-input'
        placeholder='Enter your zip/location...'
        value={query} 
        onChange={e => setQuery(e.currentTarget.value)} 
        />
      <div className='popover-container'>
        <div className={classNames('card', { loading, 'loading-lg': loading })}>
          <div className='card-body'>
            {
              !Boolean(result.length) ?
                <p className='text-center'>Enter your zip code or search for your location.</p> :
                result.map((place, i) => <PlaceResult key={i} place={place} onSelect={onSelect} />)
            }
          </div>
        </div>
      </div>
    </div>
  );
}