import { useEffect } from "react";
import { useMemo, useState } from "react";

interface Sponsor {
  name: string;
  image: string;
  to?: string;
  href?: string;
}

export const sponsors: Array<Sponsor> = [
  { name: 'Organic Oral Care', image: '/images/logos/orl-512x.png', href: 'https://orlcares.com/pages/hnp'},
  { name: 'TEDx UNLV', image: '/images/logos/tedx-unlv-64.png', to: '/TEDx' },
  { name: 'PBS', image: '/images/logos/pbs-64.png', to: '/pbs' },
  { name: 'United Nations', image: '/images/logos/united-nations.jpg' },
  { name: 'DELL', image: '/images/logos/dell-64.png', href: 'https://happyneighborhoodproject.com/dell' },
  // { name: 'Creative 7 Designs', image: '/images/logos/creative7designs.png', href: 'https://happyneighborhoodproject.com/creative7designs' },
  // { name: 'Clever RX', image: '/images/logos/clever-rx.png', href: 'https://happyneighborhoodproject.com/cleverrx' },
  { name: 'The Happy Neighbhorhood Project', image: '/images/hnp-decal-64.png' },
  { name: '4MyCard', image: '/images/logos/4mycard.svg', href:'https://happyneighborhoodproject.com/4mycard'},
  // Grant Cordone
  // Dubb
  // Nowsite
];

export default function useSponsors(rotate?: boolean, time: number = 4000) {
  const _sponsors = useMemo(() => {
    return shuffle(sponsors);
  }, []);

  const [output, setOutput] = useState(_sponsors.slice(-4));

  useEffect(() => {
    if (rotate) {
      let index = 0;
      const interval = setInterval(() => {
        setOutput([
          _sponsors[(++index + _sponsors.length) % _sponsors.length],
          _sponsors[(index+1 + _sponsors.length) % _sponsors.length],
          _sponsors[(index+2 + _sponsors.length) % _sponsors.length],
          _sponsors[(index+3 + _sponsors.length) % _sponsors.length]
        ]);
      }, time);

      return () => interval && clearInterval(interval);
    } else {
      setOutput(_sponsors);
    }
  }, [rotate, _sponsors, time]);

  return output;
}

function shuffle<T>(array: Array<T>) {
  const fresh = array.slice();

  for (let i = fresh.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [fresh[i], fresh[j]] = [fresh[j], fresh[i]];
  }

  return fresh;
}