
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import { Link } from 'react-router-dom';
import { Video } from '@react-spectre/media';
import WobblyButton from '../components/WobblyButton';
import AnniversaryCountdown from '../components/custom/AnniversaryCountdown';

const RegisterButton = () => (
  <WobblyButton as={Link} to='/networking' style={{ textTransform: 'uppercase' }}>
    <div className='title'>
      Register to Attend<br />Happy Business Networking
    </div>
    <div className='subtitle'>
      These events are FREE, forever!<br />That is our commitment to spreading Happiness!
    </div>
  </WobblyButton>
);

const ZoomBackground = () => (
  <div style={{ 
    backgroundColor: 'black',
    backgroundImage: 'url("/images/zoom_bg_hnp.jpg")',
    backgroundRepeat: 'repeat repeat',
    backgroundPosition: 'center center',
    opacity: .3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
    zIndex: -1
  }} />
);

const VideoTestimonials = () => (
  <Container>
    <div className='columns'>
      <div className='column col-sm-12 col-6 mt-2'>
        <Video.Container responsive>
          <iframe 
            title='Wellness With Stef' 
            src='https://player.vimeo.com/video/420293309'
            frameBorder='0' 
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen
            />
        </Video.Container>
      </div>
      <div className='column col-sm-12 col-6 mt-2 d-flex'>
        <div className='p-2 d-flex' style={{ backgroundColor: '#5755d9', color: 'white', flexDirection: 'column' }}>
          <p>"I have been able to network with some phenomenal people, some that have resulted in being clients for me and some that I have been able to connect with in other ways. My favorite thing about this community is that they all just have genuine hearts and they passionately care about people."</p>
          <div className='d-flex' style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end'}}>
            <h4 style={{ color: 'rgba(255,214,0,1)', margin: 0 }}>Wellness With Stef</h4>
            <h3 className='mt-2'  style={{ color: 'rgba(255,214,0,1)', margin: 0 }}>Stefanie Thayer</h3>
          </div>
        </div>
      </div>
    </div>
    <div className='columns'>
      <div className='column col-sm-12 col-6 mt-2'>
        <Video.Container responsive>
          <iframe 
            title='Studley Chiropractic' 
            src='https://player.vimeo.com/video/420293896'
            frameBorder='0' 
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen
            />
        </Video.Container>
      </div>
      <div className='column col-sm-12 col-6 mt-2 d-flex'>
        <div className='p-2 d-flex' style={{ backgroundColor: '#5755d9', color: 'white', flexDirection: 'column' }}>
          <p>"I would encourage you guys to check it out. Just by talking to people, people have asked me questions about my business and I've actually had patients come in from it so its gained me a little bit of business as well. But more than anything it's a great way to connect with people and it's a great way for me to be a resource to my community."</p>
          <div className='d-flex' style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end'}}>
            <h4 style={{ color: 'rgba(255,214,0,1)', margin: 0 }}>Studley Chiropractic</h4>
            <h3 className='mt-2' style={{ color: 'rgba(255,214,0,1)', margin: 0 }}>Dr. Chip Studley</h3>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

const Hero = () => (
  <Container style={{ position: 'relative', maxWidth: '100%' }}>
    <ZoomBackground />
    <div className='text-center' style={{ padding: '2em', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div className='text-center'>
        <h1><strong>The Happiest</strong> Place In The World For <strong>Business People</strong></h1>
        {/* <AnniversaryCountdown />
        <RegisterButton /> */}
      </div>
    </div>
  </Container>
);

// const HeroGHS = () => {
//   // const hopin_link = useHopinReferralLink();

//   return (
//     <Container style={{ position: 'relative', maxWidth: '100%' }}>
//       <ZoomBackground />
//       <div className='text-center' style={{ padding: '5em' }}>
//         <img alt='HNP Logo' src='/images/hnp-logo.png' style={{ maxWidth: '50vw' }} />
//         <h1><strong>The Happiest</strong> Place In The World For <strong>Business People</strong></h1>
//         <Link component={WobblyButton} to='/ghs' style={{ textTransform: 'uppercase' }}>
//           <div className='title'>
//             Register for GHS 2021
//           </div>
//           <div className='subtitle'>
//             Early Bird Pricing ends September 24th!
//           </div>
//         </Link>
//       </div>
//     </Container>
//   );
// }

// const GHSRegistration = () => {
//   const hopin_link = useHopinReferralLink();

//   return (
//     <Container className='text-center'>
//       <a className='btn' href={hopin_link} target='_blank' rel='noreferrer noopener'>Register for GHS 2021</a>
//       <p>Early Bird Pricing ends September 24th!</p>
//     </Container>
//   );
// }

const Growth = () => (
  <Container className='text-center'>
    <h1 style={{ color: '#5755d9', fontSize: '300%', fontWeight: 'bold' }}>Since March 2020 You &amp; Your Friends<br />Grew Happy Business Networking</h1>
  </Container>
);

const Testimonials = () => (
  <Container className='text-center'>
    <h1 style={{ color: '#5755d9', fontSize: '300%', fontWeight: 'bold' }}>In Our Network</h1>
    <div className='columns mt-2'>
      <div className='column d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
        <h2>You <span style={{ textDecoration: 'underline' }}>Grow</span> By Being A Part of A Supportive Community</h2>
      </div>
      <div className='column d-flex col-sm-12' style={{ flexDirection: 'column' }}>
        <p style={{ flex: 1 }}>"Since joining HNP, I have gotten a 4,910%+ ROI. Of course, you get out what you put in, but I am a serial networker and I have NEVER gotten more clients from a group than Happy Neighborhood Project."</p>
        <h5>Marc Hamm, Owner at Pro Web Firm</h5>
        <h5>Director of the Greater East Side Chamber of Commerce</h5>
      </div>
    </div>
    <div className='divider' />
    <div className='columns'>
      <div className='column d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
        <h2>You <span style={{ textDecoration: 'underline' }}>Cultivate</span> Collaboration, Opportunities &amp; Ideas</h2>
      </div>
      <div className='column d-flex col-sm-12' style={{ flexDirection: 'column' }}>
        <p style={{ flex: 1 }}>"My favorite thing about this community is that they all just have genuine hearts and they passionately care about people."</p>
        <h5>Stefanie Thayer, Owner at Wellness With Stef</h5>
      </div>
    </div>
    <div className='divider' />
    <div className='columns'>
      <div className='column d-flex col-sm-12' style={{ justifyContent: 'center', alignItems: 'center' }}>
        <h2>You <span style={{ textDecoration: 'underline' }}>Expand</span> Your Skills</h2>
      </div>
      <div className='column d-flex' style={{ flexDirection: 'column' }}>
        <p style={{ flex: 1 }}>"We dug into content, content, posts, engagement, interactions, design. He showed me some real NUGGETS of information. I loved how much I was able to take away from our social media session."</p>
        <h5>Melissa Bowers, Owner at Melissa Bowers At Your Service</h5>
      </div>
    </div>
  </Container>
);

const LastCall = () => (
  <Container style={{ position: 'relative', maxWidth: '100%' }}>
    <ZoomBackground />
    <div className='text-center' style={{ padding: '5em' }}>
      <h1><strong>START EXCHANGING REFERRALS WITH LIKE-MINDED BUSINESS OWNERS.</strong></h1>
      <RegisterButton />
    </div>
  </Container>
);

const Home = () => {
  return (
    <Page title='HNP'>
      {/* <div style={{ textAlign: 'center', backgroundColor: 'rgba(255,214,0,1)', fontSize: '150%' }}>
        <span><small><em>
          Questions, comments, concerns, suggestions? Text them to <a href="tel:9165797688">(916) 579-7688</a>
        </em></small></span>
      </div> */}
      <Hero />
      <Growth />
      <Testimonials />
      <VideoTestimonials />
      <LastCall />
    </Page>
  );
}

export default Home;