
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import LocationSearch, { Place } from '../components/LocationSearch';
import CategorySelect, { Category } from '../components/CategorySelect';
import useGoToListingSearch from '../tools/useGoToListingSearch';
import useLocalPricing from '../data/useLocalPricing';
import useRemotePricing from '../data/useRemotePricing';
import CategoryLocationSearch from '../components/CategoryLocationSearch';

const Test = () => {
  const [place, setPlace] = useState<Place>();
  const [category, setCategory] = useState<Category>();
  const [go, canGo] = useGoToListingSearch(category, place);
  const localPricing = useLocalPricing(['94606', '95617']);
  const remotePricing = useRemotePricing();

  return (
    <Page title='HNP - Test Page'>
      <HeaderBar title='Test Page' />
      <Container>
        <CategoryLocationSearch />
        <pre lang='JSON'>{ JSON.stringify({ localPricing, remotePricing }, null, 2) }</pre>
        <pre lang='JSON'>{ JSON.stringify({ place }, null, 2) }</pre>
        <hr />
        <CategorySelect category={category} onSelect={category => setCategory(category)} />
        <LocationSearch place={place} onSelect={place => setPlace(place)} />
        <button className='btn btn-primary' disabled={!canGo} onClick={go}>Serach!</button>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At elementum eu facilisis sed. Euismod elementum nisi quis eleifend quam. Faucibus ornare suspendisse sed nisi. Commodo ullamcorper a lacus vestibulum sed arcu non. Suspendisse potenti nullam ac tortor vitae. Gravida cum sociis natoque penatibus et. Arcu dui vivamus arcu felis bibendum ut tristique. Quis imperdiet massa tincidunt nunc pulvinar sapien. Mauris rhoncus aenean vel elit. Mattis enim ut tellus elementum sagittis. Donec pretium vulputate sapien nec sagittis.</p>
        <p>Diam in arcu cursus euismod. Mi bibendum neque egestas congue quisque egestas. Sem viverra aliquet eget sit amet tellus cras. Aliquam nulla facilisi cras fermentum. Dapibus ultrices in iaculis nunc sed augue lacus. Elementum nibh tellus molestie nunc non. Convallis aenean et tortor at risus viverra. A arcu cursus vitae congue mauris. Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat. Id leo in vitae turpis massa. Nisi vitae suscipit tellus mauris a diam. Faucibus a pellentesque sit amet porttitor eget dolor morbi non. Orci sagittis eu volutpat odio facilisis mauris sit. Sagittis vitae et leo duis ut. Felis imperdiet proin fermentum leo vel.</p>
        <p>Et pharetra pharetra massa massa ultricies mi quis hendrerit. Ut eu sem integer vitae justo. Viverra suspendisse potenti nullam ac. At auctor urna nunc id. At consectetur lorem donec massa sapien faucibus et molestie ac. Sem viverra aliquet eget sit amet tellus cras adipiscing. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Urna et pharetra pharetra massa massa ultricies. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Nibh praesent tristique magna sit amet purus.</p>
        <p>Turpis in eu mi bibendum neque egestas congue quisque egestas. Id cursus metus aliquam eleifend mi in nulla. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Cursus vitae congue mauris rhoncus aenean. Dictumst quisque sagittis purus sit amet volutpat consequat mauris nunc. Vitae congue mauris rhoncus aenean vel. Sed odio morbi quis commodo odio aenean sed adipiscing diam. Mi proin sed libero enim sed faucibus turpis in. Vulputate eu scelerisque felis imperdiet. Gravida quis blandit turpis cursus in hac. Nullam non nisi est sit amet facilisis magna. Purus sit amet luctus venenatis lectus magna fringilla urna porttitor. Nulla facilisi etiam dignissim diam. Nunc scelerisque viverra mauris in aliquam sem fringilla. Nisi porta lorem mollis aliquam ut porttitor. Praesent elementum facilisis leo vel fringilla est ullamcorper eget. Rhoncus aenean vel elit scelerisque mauris pellentesque. Vitae sapien pellentesque habitant morbi tristique senectus. Consectetur purus ut faucibus pulvinar elementum integer.</p>
        <p>Blandit cursus risus at ultrices mi tempus imperdiet nulla. Mauris augue neque gravida in fermentum et sollicitudin ac orci. Vel eros donec ac odio. Nisi est sit amet facilisis magna etiam tempor orci eu. Tincidunt dui ut ornare lectus sit amet est. Purus viverra accumsan in nisl nisi scelerisque eu. Nunc congue nisi vitae suscipit tellus mauris a diam maecenas. Nibh mauris cursus mattis molestie a. Et molestie ac feugiat sed lectus vestibulum mattis. Blandit cursus risus at ultrices mi. Felis donec et odio pellentesque diam volutpat commodo. Eleifend mi in nulla posuere sollicitudin. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Sed velit dignissim sodales ut eu sem integer vitae justo. Lacus sed viverra tellus in. Iaculis eu non diam phasellus vestibulum lorem sed risus. Amet massa vitae tortor condimentum lacinia quis vel. Vel quam elementum pulvinar etiam non. Purus sit amet volutpat consequat mauris nunc congue nisi. Et egestas quis ipsum suspendisse ultrices gravida.</p>
      </Container>
    </Page>
  )
}

export default Test;