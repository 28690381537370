
import React, { useEffect, useState } from 'react';
import Page from '../../components/layout/Page';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './index.css';
import Section, { Divider } from './components/Section';
import { Header, Purple, Checkmark, Text, SubHeader } from './components/Text';
import { Row, Half, Third, TwoThirds } from './components/Layout';
import Modal from './components/Modal';
import DeepDiveForm from './forms/DeepDiveForm';
import WobblyButton from '../../components/WobblyButton';

const PAYMENT_LINK = 'https://buy.stripe.com/3cs7uKdUNeyAa1W9AK';

const alsoAsk = ['Business Growth', 'Operations', 'Competitive Advantage', 'Sales', 'Hiring', 'Strategy',
  'HR', 'Technology', 'Leadership', 'Management', 'And more...'];

const powerNetworking = [
  'Be best matched with other verified members that compliment your business',
  'Build lifelong relationships with other like-minded members that want to see you succeed',
  'Go over exercises that will help others think of you first when referring business'
];

const breakdown = [
  ['Workshops', 'We kickoff the session with back to back workshops as a group.'],
  ['Breakout into 2 rooms - Breakout 1', '45 minutes of private consulting with Edwin Edebiri or HNP Leadership Team - Q&A format.'],
  ['Switch - Breakout 2', '45 minutes of private consulting with Edwin Edebiri or HNP Leadership Team - Q&A format.'],
  ['Debrief & Action Plan', 'Go over key takeaways with the group and create an action plan.'],
  ['Power Networking', '30 minutes of Power Networking with verified Happy Business Members.']
];

const BusinessBootCamp = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP Boot Camp' className='landing-page text-white'>
      <div className='d-flex background' style={{ background: `url('/images/tedx-stage.png')`, height: '100vh', alignItems: 'center' }}>
        <div className='p-centered landing-hero text-center' style={{ padding: '4em 1em', color: 'white', backgroundColor: '#000A' }}>
          <img alt='' id='logo' src='/images/hnp-logo.png' width='480' height='240' />
          <Header>
            You know your business<br />
            We know small business growth<br />
            <strong>Let's transform your business</strong>
          </Header>
          <AnchorLink href='#boot-camp' className='btn mt-2'>What is HNP's Business Boot Camp?</AnchorLink>
        </div>
      </div>
      <Section>
        <Row>
          <Half className='p-2 text-right center above'>
            <SubHeader>HNP's Business Boot Camp is an immersive business growth education session for ambitious verified Happy Business Members.</SubHeader>
          </Half>
          <Half className='center p-2'>
            <img src='/images/zoom-calls.png' alt='Group video calls packed with happy businesspeople!' title='Group video calls packed with happy businesspeople!' style={{ width: '100%' }} />
          </Half>
        </Row>
      </Section>
      <Section>
        <WobblyButton href={PAYMENT_LINK}>Purchase Your Ticket!</WobblyButton>
      </Section>
      <Section>
        <Row>
          <Third className='center p-2'>
            <img src='/images/edwin-tedx.jpg' alt='Edwin Edebiri doing a TEDx Talk' title='Edwin Edebiri doing a TEDx Talk' style={{ height: '25em' }} />
          </Third>
          <TwoThirds className='p-2 text-left center'>
            <Header>Are you ready to transform your business?</Header>
            <Text>HNP's Business Boot Camp is for self employed, small business owners, and entrepreneurs. Our team will break down marketing, social media, and how to overcome your current business hurdles so that you can take your business to the next level.</Text>
          </TwoThirds>
        </Row>
      </Section>
      <Section>
        <img src='/images/tedx-backstage.png' alt='Edwin Edebiri backstage with TEDx staff' title='Edwin Edebiri backstage with TEDx staff' style={{ width: '100%' }} />
      </Section>
      <Section>
        <Header>Who Will I Meet?</Header>
        <Row className='pt-2'>
          <Third className='px-2'>
            <img 
              src='/images/edwin-edebiri.jpg' 
              title='Edwin Edebiri, CHO speaking on a microphone' 
              alt='Edwin Edebiri, CHO speaking on a microphone' 
              width='100%' 
              />
            <h2 className='text-yellow mt-2'><strong>Edwin Edebiri, CHO</strong></h2>
            <Text>Edwin Edebiri has sold two multiple-million dollar companies in his early twenties and thirties. He has worked with business icons like Darren Hardy, Zig Ziglar, Les Brown, and Brian Tracy. He sits down with HNP Business Boot Camp attendees for a private business coaching session.</Text>
          </Third>
          <Third className='px-2'>
            <img
              src='/images/aaron-talking.png' 
              title='A consulting session!' 
              alt='A consulting session!' 
              width='100%' 
              />
            <h2 className='text-yellow mt-2'><strong>HNP Leadership Team</strong></h2>
            <Text>Leadership teams from HNP lead the marketing, social media &amp; content marketing workshops.</Text>
          </Third>
          <Third className='px-2'>
            <img
              src='/images/hnp-tribe.png' 
              alt='Happy businesses showing off their decals!' 
              title='Happy businesses showing off their decals!' 
              width='100%' 
              />
            <h2 className='text-yellow mt-2'><strong>HNP Tribe</strong></h2>
            <Text>Learn, network, collaborate &amp; build life long relationships with other members within the HNP tribe that bend over backwards to see you succeed.</Text>
          </Third>
        </Row>
      </Section>
      <Section>
        <Header>What Will I Learn?</Header>
        <Text>The half day session will be full of speakers, breakout sessions, exercises, workshops, and deep business conversations in all the areas that we are experts in.</Text>
      </Section>
      <Section>
        <Row>
          <Half className='h-split text-left'>
            <img alt='' src='/images/icon-megaphone.png' style={{ height: '5em', width: '5em', marginRight: '2em' }} />
            <div>
              <SubHeader>Marketing &amp; Branding</SubHeader>
              <Text className='text-muted'>Learn tools and tactics to stand out in a competitive market.</Text>
            </div>
          </Half>
          <Half className='h-split text-left'>
            <img alt='' src='/images/icon-brain.png' style={{ height: '5em', width: '5em', marginRight: '2em' }} />
            <div>
              <SubHeader>Mindset &amp; Fear Reduction Training</SubHeader>
              <Text className='text-muted'>Overcome fears that are holding you back from playing the bigger game.</Text>
            </div>
          </Half>
          <Half className='h-split text-left'>
            <img alt='' src='/images/icon-vcard.png' style={{ height: '5em', width: '5em', marginRight: '2em' }} />
            <div>
              <SubHeader>Social Media</SubHeader>
              <Text className='text-muted'>Overwhelmed with how to best use Facebook, Instagram, or LinkedIn to market your business? We'll dissect each platform and get you up to speed.</Text>
            </div>
          </Half>
          <Half className='h-split text-left'>
            <img alt='' src='/images/icon-diamond.png' style={{ height: '5em', width: '5em', marginRight: '2em' }} />
            <div>
              <SubHeader>Content Marketing</SubHeader>
              <Text className='text-muted'>Educate your prospects to understand the true value of your product/service.</Text>
            </div>
          </Half>
        </Row>
      </Section>
      <Section>
        <Header>People also ask us about:</Header>
        <Divider />
        <div className='list two-column'>
          { alsoAsk.map(item => (
            <span key={item} className='list-item'>
              <Checkmark color='purple' /> { item }
            </span>
          ))}
        </div>
      </Section>
      <Section>
        <Header>What is <Purple>Power Networking?</Purple></Header>
        <Text>Power Networking is focused on really getting to know other verified members and build a stronger relationship with them.</Text>
        <Text className='text-left'>Through our program you will:</Text>
        <Divider />
        <div className='text-left'>
          { powerNetworking.map(item => (
            <span key={item} className='list-item'>
              <Checkmark color='purple' /> { item }
            </span>
          ))}
        </div>
      </Section>
      <Section>
        <Row>
          <Third className='v-center pr-2'>
            <SubHeader>The HNP Business Boot Camp breakdown</SubHeader>
          </Third>
          <TwoThirds className='text-left pl-2'>
            { breakdown.map(item => (
              <>
                <SubHeader className='text-purple'>{ item[0] }</SubHeader>
                <Text>{ item[1] }</Text>
                <Divider />
              </>
            ))}
          </TwoThirds>
        </Row>
      </Section>
      <Section>
        <Header>
          How much would this Business Boot Camp be worth to you if it could transform your business?
        </Header>
      </Section>
      <Section>
        <Header>
          Total per seat:<br />
          <del className='text-danger'>$2,500</del><br/>
          $497
        </Header>
        <Text>Free to Premium Verified Happy Businesses</Text>
      </Section>
      <Section>
        <WobblyButton href={PAYMENT_LINK}>Purchase Your Ticket!</WobblyButton>
      </Section>
      {/* <Section full>
        <div className='call-to-action'>
          <div className='left-two-thirds'>
            <Header>Ready to get your your business questions answered and start Power Networking?</Header>
          </div>
          <div className='right-third'>
            <button className='btn btn-lg' onClick={() => setShowForm(true)}>Apply Now</button>
          </div>
        </div>
      </Section> */}
      <Modal visible={showForm} onClose={() => setShowForm(false)} title='Apply Now'>
        <DeepDiveForm key={showForm ? 0 : 1} onClose={() => setShowForm(false)} />
      </Modal>
    </Page>
  );
}

export default BusinessBootCamp;