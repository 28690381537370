
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { Grid, Row, Col } from '@react-spectre/grid';
import Divider from '../components/Divider.js';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../components/form/HookFormInput';
import HookFormSelect from '../components/form/HookFormSelect';
import HookFormRadio from '../components/form/HookFormRadio';
import { sendBirthday } from '../API.js';

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const days = Array.from(Array(31), (_, i) => i + 1);

const Birthday = () => {
  const { t } = useTranslation();
  const _form = useForm();
  const { handleSubmit, watch } = _form;
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const attended = watch('attended');

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setResult(await sendBirthday(data));
    setLoading(false);
  };

  if (result) {
    return (
      <Page title='HNP - Become a Networking Co-Host'>
        <HeaderBar>
          <h4>{ t('birthday.heading', 'Your Birthday') }</h4>
        </HeaderBar>
        <Container>
          <div class="empty">
            <div class="empty-icon">
              <i class="icon icon-people"></i>
            </div>              
            <p>{ t('birthday.success.p1', 'We look forward to announcing/celebrating your birthday with you!') }</p>
            <p>{ t('birthday.success.p2', 'Every first Friday of the month on our worldwide call at 11 am PDT / 2 EDT.') }</p>
            { Boolean(attended === 'No') &&
              <Row>
                <p>Be sure to attend one our Happy <Link to='/networking'>online networking calls</Link>.</p>
              </Row>
            }
          </div>
        </Container>
      </Page>
    );
  }
  
  return (
    <Page title='HNP - Your Birthday'>
      <HeaderBar title='Your Birthday' />
      <Container>
        <Grid>
          <Row>
            <Col md={12}>
              <h1 className='text-center'>{ t('birthday.title', 'Is Your Birthday Coming Up?') }</h1>
              <p>{ t('birthday.about.p1', 'We want to celebrate with you. Every first Friday of the month we will celebrate with you during our worldwide call at 11 am PDT / 2 EDT.') }</p>
              <p>{ t('birthday.about.p2', 'Please provide your name and birthdate (no need for the year)') }</p>
            </Col>
            <Divider vertical />
            <Col md={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <HookFormInput 
                  name='name.first' 
                  placeholder='First Name'
                  label='First Name'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormInput 
                  name='name.last' 
                  placeholder='Last Name'
                  label='Last Name'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormSelect 
                  name='month' 
                  placeholder='Month of Birth'
                  label='Month'
                  items={MONTHS}
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormSelect 
                  name='day' 
                  placeholder='Day of Birth'
                  label='Day'
                  items={days}
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormRadio
                  name='attended'
                  label='Have you attended any of the HNP online networking meetings?'
                  items={['Yes', 'No']}
                  form={_form}
                  formPropes={{ required: true }}
                  />
                <button 
                  disabled={loading} 
                  className={classNames('btn btn-primary', { loading })} 
                  type="submit" 
                  >
                    Submit!
                </button>
              </form>
            </Col>
          </Row>
          { Boolean(attended === 'No') &&
            <Row>
              <p>Be sure to attend one our Happy <Link to='/networking'>online networking calls</Link>.</p>
            </Row>
          }
        </Grid>
      </Container>
    </Page>
  )
}

export default Birthday;