
import React from "react";

export interface BaseRowAction {
  icon?: string;
  title?: string;
  alt?: string;
  name: string;
  color?: string;
  onAction: (name: string, item: any) => void;
  shouldShow?: (name: string, row: any) => boolean;
}

export interface IconRowAction extends BaseRowAction {
  icon: string;
  alt: string;
}

export interface TitleRowAction extends BaseRowAction {
  title: string;
}

export type RowAction = IconRowAction | TitleRowAction;

function RowActionItem({ action, row }: { action: RowAction, row: any, color?: string }) {
  const { icon, title, alt, name, color = 'primary', onAction } = action;

  return (
    <div style={{ padding: '.2em', display: 'inline-block' }}>
      { icon ? (
        <button title={alt || title} onClick={() => onAction(name, row.original)} color={color} className='btn btn-icon btn-sm tooltip' data-tooltip={title || alt}>
          <i className={`tim-icons ${icon}`} />
        </button>
      ) : (
        <button title={alt || title} onClick={() => onAction(name, row.original)} color={color} className='btn btn-sm tooltip' data-tooltip={title || alt}>
          { title }
        </button>
      )}
    </div>
  );
}

export default RowActionItem;