
import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import ExternalLink from '../components/ExternalLink.js';
import copyToClipboard from '../tools/copyToClipboard.js';

interface Props {
  link: {
    url: string;
    info: string;
  }
}

export const CopiableLink = ({ link }: Props) => {
  const [copied, setCopied] = useState<boolean>();

  const copy = useCallback((text) => async () => {
    setCopied(await copyToClipboard(text));
  }, [setCopied]);

  useEffect(() => {
    const timeout = setTimeout(() => 
      setCopied(undefined)
    , 1500);

    return () => clearTimeout(timeout);
  }, [copied, setCopied]);

  return (
    <div>
        <ExternalLink href={link.url}>
          { link.info }
        </ExternalLink>
        <div className='input-group m-2'>
          <input className='form-input' value={link.url} readOnly />
          <button className='btn btn-primary input-group-btn' onClick={copy(link.url)}>
            <i className={classNames('icon', copied ? 'icon-check' : 'icon-copy')} /> Copy
          </button>
        </div>
    </div>
  );
}

export default CopiableLink;