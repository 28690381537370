
import React from 'react';

export const HeaderBar = ({ title = '', subtitle = '', children = null }) => {
  return (
    <div id='header' className='hero bg-gray' style={{padding: '.5rem'}}>
      <div className='hero-body text-center'>
        { children ? children :
          <>
            { title && <h1 className='text-center text-uppercase' style={{margin: 0}}>{ title }</h1> }
            { subtitle && <h3 className='text-center text-uppercase' style={{margin: 0}}>{ subtitle }</h3> }
          </>
        }
      </div>
    </div>
  );
}

export default HeaderBar;