
import React from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import RowActionItem, { RowAction } from './RowActionItem';


export interface DataTableProps {
  title?: string;
  subtitle?: string;
  data?: Array<any>;
  columns: Array<any>; // Array<ColumnWithLooseAccessor | ColumnWithStrictAccessor>;
  actions?: Array<RowAction>;
  pageSize?: number;
  initialSort?: string;
  ascending?: boolean;
  filterable?: boolean;
}

function DataTable({ title, subtitle, data = [], columns, actions = [], pageSize = 10, initialSort = '', ascending, filterable = true }: DataTableProps) {
  const tableInstance = useTable({ 
      columns, 
      data, 
      initialState: {
        pageSize,
        sortBy: [{
          id: initialSort,
          desc: !ascending
        }]
      } 
    }, 
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    toggleSortBy,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setGlobalFilter,
    state: { globalFilter }
  } = tableInstance;

  return (
    <div className='card'>
      { Boolean(title || subtitle || filterable) &&
        <div className='card-header'>
          { Boolean(title) && <div className='card-title h5'>{ title }</div> }
          { Boolean(subtitle) && <div className='card-subtitle text-gray'>{ subtitle }</div> }
          { Boolean(filterable) && 
            <div>
              <input 
                className='form-input'
                value={globalFilter} 
                onChange={e => setGlobalFilter(e.currentTarget.value)} 
                placeholder='Search...'
                /> 
            </div>
          }
        </div>
      }
      <div className='card-body'>
        <table className="table tablesorter" {...getTableProps()}>
          <thead>
            { headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                { headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    <span color='link' onClick={() => toggleSortBy(column.id)}>
                      { column.render('Header') }
                    </span>
                    <span>
                      { column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''
                      }
                    </span>
                  </th>
                ))}
                { Boolean(actions.length) && 
                  <th className='text-right'>Actions</th>
                }
            </tr>
          ))}
        </thead>
          <tbody {...getTableBodyProps()}>
            { page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  { row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        { cell.render('Cell') }
                      </td>
                    );
                  })}
                  { Boolean(actions.length) && 
                    <td className='text-right d-flex'>
                      { actions
                        .filter(action => action.shouldShow ? action.shouldShow(action.name, row) : true)
                        .map((action, i) => (
                          <RowActionItem 
                            key={i}
                            action={action} 
                            row={row} 
                            />
                        ))}
                    </td>
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      { pageCount > 1 && (
        <div className='card-footer'>
          <nav>
            <ul className='pagination'>
              <li className='page-item'>
                <button onClick={() => gotoPage(0)}>
                  {'<<'}
                </button>
              </li>
              <li className='page-item'>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
              </li>
              <li className='page-item disabled'>
                {'---'}
              </li>
              <li className='page-item'>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
              </li>
              <li className='page-item'>
                <button onClick={() => gotoPage(pageCount - 1)}>
                  {'>>'}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

export default DataTable;