
import React from 'react';
import classNames from 'classnames';

const HookFormSelect = ({ name, label, placeholder, hasOther, errorMessage, items, form, formProps }) => {
  const { register, errors } = form;
  const error = errors[name];

  if (items && items.length) {
    return (
      <div className={classNames('form-group', { 'has-error': Boolean(error) })}>
        { Boolean(label) &&
          <label className='form-label' htmlFor={name}>
            { label }
            <span style={{ color: 'red' }}>{ Boolean(formProps && formProps.required) ? '*' : ''}</span>
          </label>
        }
        <select class="form-select" name={name} ref={register(formProps)}>
          { Boolean(placeholder) && 
            <option disabled selected value=''>{ placeholder }</option>
          }
          { items.map((item, i) => (
            <option key={i} value={item}>
              { item }
            </option>  
          ))}
          { Boolean(hasOther) && 
            <option value='other'>Other...</option>
          }
        </select>
        { Boolean(error) && 
          <p class="form-input-hint">{ errorMessage || 'Required.' }</p> 
        }
      </div>
    );
  }

  return null;
}

export default HookFormSelect;