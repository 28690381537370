
import React, { useState, useCallback, useEffect } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Formsy from 'formsy-react';
import Input from '../components/form/Input.js';
import Divider from '../components/Divider';
import FormsyStripeCreditCardInput from '../components/form/FormsyStripeCreditCardInput.js';
import { registerZoomSubscription } from '../API.js';
import ExternalLink from '../components/ExternalLink.js';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import useCoopReferrer from '../data/useCoopReferrer';

const Zoom = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP - Zoom'>
      <HeaderBar />
      <Container>
        <div className='columns'>
          <div className='column col-12 col-sm-12'>
            <p>We are happy to announce that Happy Neighborhood Project (HNP) have a new partnership with Zoom! Because of our partnership, you will be able to get heavily discounted plans just because you know us (please don't tell)!</p>
            <p>The special HNP / Zoom plan includes:</p>
            <ul>
              <li>Up to 500-person Meetings</li>
              <li>Up to 500-person Webinar Attendees</li>
              <li>Unlimited Cloud Storage</li>
              {/*<li>Create your own customized URL <code className='code-success'>https://yourcompany.zoom.us</code></li>*/}
              <li>Facebook &amp; YouTube Live streaming capability</li>
              <li>All settings &amp; data transfer if you are a current Zoom user - Make sure to sign-up with the same email address with your current zoom account.</li>
              <li>Prorated refund if you are a current Zoom user – If you paid for the month or year you will get the portion you have not used back. Make sure to sign-up with the same email address with your current zoom account.</li>
              <li>And more...</li>
            </ul>
            <p>These features separately will normally costs $250+/month. but with our new partnership, you will get all of these features for only $29.99/mo. Please note that there is a one-time $25 admin fee. So, the first month of $54.99 is non-refundable, subsequent months are $29.99. We require a 30-day notice via email for cancellation (deactivating your account through Zoom does not notify us, so billing will continue without notice). There are very limited number of slots available so register below.</p>
          </div>
        </div>
        <hr />
        <Divider />
        <ZoomRegistrationForm />
      </Container>
    </Page>
  )
}

// TODO
// Send email to notify them to accept Zoom
// Store user info with customer id and subscription status
// Set-up webhooks for subscription

const ZoomRegistrationForm = () => {
  const [valid, setValid] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [, setError] = useState();
  const [receipt, setReceipt] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [referrer] = useCoopReferrer();

  // const clear = useCallback(() => {
  //   setLoading(false);
  //   setReceipt(null);
  // }, []);

  const submit = useCallback(async (data) => {
    setLoading(true);
    setError(null);
    setReceipt(null);
    
    delete data.confirm_email;

    if (referrer && data) {
      data.referrer = referrer;
    }

    try {
      const { receipt, invoice_url } = await registerZoomSubscription(data);

      if (invoice_url) {
        setInvoiceUrl(invoice_url);
      } else if (receipt && receipt.paid) {
        setReceipt(receipt);
      } else if (receipt) {
        setError('Unable to charge card.');
      } else {
        setError('Something went wrong.');
      }
    } catch(error) {
      console.error('Subscribe to Zoom', error);
      setError(error);
    }
    setLoading(false);
  }, [referrer]);

  if (invoiceUrl) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Your registration is almost complete.</h2>
        <p>
          Due to an issue with your bank, you will need to pay via invoices,{' '}
          you should have received an email with instructions for payment.{' '}
          You may also click the link below.
        </p>
        <ExternalLink href={invoiceUrl}>Pay Your Invoice</ExternalLink>
      </div>
    );
  }

  if (receipt) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've successfully registered.</h2>
        <ExternalLink href={receipt.url}>View Your Receipt</ExternalLink>
        { Boolean(receipt.manual_provisioning) && 
          <p>Because you already have a Zoom Pro account, we will manually provision your account by the end of the next business day at which time you will recieve your email from Zoom.</p>
        }
      </div>
    );
  }

  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onSubmit={submit}
      className='col-8'
      >
      <h2>Please Enter Your Information</h2>
      <h4>Upon registration, you will receive an email from Zoom to continue setting up your account with the Happy Neighborhood Project.</h4>
      <Input name='pathname' type='hidden' value={location ? location.pathname : ''} />
      <Input name='company' label='Company Name' placeholder='Company Name' />
      <Input name='name.first' label='First Name' placeholder='Your First Name' required />
      <Input name='name.last' label='Last Name' placeholder='Your Last Name' required />
      <Divider />
      <Input name='email' label='Email' placeholder='name@example.com' validations='isEmail' validationError='Valid email required.' required />
      <Input name='confirm_email' label='Confirm Email' autoComplete='none' validations='equalsField:email' validationError='Email address must match.' required />
      <Input name='phone' label='Phone Number' required />
      <Divider />
      <FormsyStripeCreditCardInput name='token' label='Payment Info' required />
      <Input name='address.street' label='Street Address' />
      <Input name='address.city' label='City' />
      <Input name='address.state' label='State' />
      <Input name='address.zip' label='Zip' />
      <Divider />
      <div className='col-12 clearfix my-2'>
        <button type='submit' className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>Submit</button>
      </div>
      <h6>You will receive an email from Zoom to continue setting up your account.</h6>
    </Formsy>
  );
}

export default Zoom;