
import React, { useMemo } from 'react';
import classNames from 'classnames';

const Divider = ({ vertical = false, text = '', className = '' }) => {
  const { classes, props } = useMemo(() => {
    const classes = classNames({
      divider: !vertical,
      'divider-vert': vertical,
      'text-center': text && !vertical,
      'hide-md': vertical
    }, className);

    const props = {};

    if (text) {
      props['data-content'] = text;
    }

    return { classes, props };
  }, [vertical, text, className]);

  return (
    <div className={classes} {...props} />
  );
};

export default Divider;