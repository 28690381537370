
import React, { useState, useCallback, useEffect } from 'react';
import Formsy from 'formsy-react';
import { getCategories } from '../API.js';
import Input from '../components/form/Input.js';
import Select from '../components/form/Select.js';
import Divider from '../components/Divider';
import { gql } from '@apollo/client';
import { apollo } from '../App.js';

const query = gql`
  query UsernameAvailable($username: String!) {
    is_username_available(username: $username)
  }
`;

const CompanyInfoForm = ({ onChange, onNext }) => {
  const [valid, setValid] = useState(false);
  const [categories, setCategories] = useState([]);

  const submit = useCallback(async (model, resetForm, invalidateForm) => {
    const { email } = model;
    const { data } = await apollo.query({
      query,
      variables: {
        username: email
      }
    });

    console.log('CompanyInfoForm', { data, email, model });

    if (data?.is_username_available) {
      onNext && onNext();
    } else {
      invalidateForm({
        email: 'This email is already in use in our system.'
      });
    }
  }, [onNext]);

  const _onChange = useCallback((values) => {
    onChange && onChange(values);
  }, [onChange]);

  useEffect(() => {
    (async () => {
      try {
        setCategories(await getCategories());
      } catch (error){
        console.error('getCategories', error);
      }
    })();
  }, []);
  
  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onValidSubmit={submit}
      onChange={_onChange}
      className='col-12'
      >
      <h2>Please Enter Your Company Information</h2>
      <Input name='company' label='Company Name' placeholder='Company Name' required />
      <Select name='category_uuid' label='Category' placeholder='Select a Category...' items={categories} required />
      <Input name='name.first' label='First Name' placeholder='Your First Name' required />
      <Input name='name.last' label='Last Name' placeholder='Your Last Name' required />
      <Divider />
      <Input name='email' label='Email' placeholder='name@example.com' validations='isEmail' validationError='Valid email required.' required />
      <Input name='phone' label='Phone Number' />
      <Input name='website' label='Website' placeholder='https://example.com' />
      <Divider />
      <Input name='address.street' label='Street Address' />
      <Input name='address.city' label='City' />
      <Input name='address.state' label='State' />
      <Input name='address.zip' label='Zip' />
      <Divider />
      <div className='col-12 clearfix mt-2'>
        <button className='btn btn-primary float-right' type='submit' disabled={!valid}>Next</button>
      </div>
      <Divider />
      <em className='mt-2'>
        By joining this program I pledge to provide great customer service and encourage a positive work environment.
      </em>
    </Formsy>
  );
}

export default CompanyInfoForm;