
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Video } from '@react-spectre/media';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import ExternalLink from '../components/ExternalLink';
import { SponsorForm } from './GlobalSummit';
import classNames from 'classnames';

const GlobalSummitLanding = ({ standalone }) => {
  const [showSponsorForm, setShowSponsorForm] = useState(false);
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Page title='HNP - Networking' className='background background-networking-2'>
      <Disclaimer />
      <Block>
        <img alt='HNP Logo' src='/images/hnp-logo-192.png' />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em', marginTop: '-2em' }}>
          <span style={{ fontWeight: 'bold', marginRight: '.5em' }}>partnered with</span>
          <SmolLogo src='/images/logos/dell-64.png' />
          <SmolLogo src='/images/logos/zoom-128.png' />
          <SmolLogo src='/images/logos/sellyourmac.png' />
          <SmolLogo src='/images/logos/thryv-light.png' />
        </div>
        <BlockText style={{ marginBottom: 0 }} size={2}><Yellow>October 10th, <SmolBreak />10am to 4pm (Pacific)</Yellow></BlockText>
        <BlockText size={4}>THE 2020 <SmolBreak />GLOBAL HAPPINESS<SmolBreak /> SUMMIT</BlockText>
        <Vimeo style={{ margin: '2em' }} id='445895874' />
        <RegisterLink />
        <SponsorButton onClick={() => setShowSponsorForm(true)} />
      </Block>
      <OpaqueBlock>
        <BlockText size={3}>
          <Purple>How It Works - Step By Step</Purple>
        </BlockText>
        <Steps 
          steps={[
            'Register To Receive Email + Password',
            'Login with Zoom Link At Event Start Time',
            'Networking, giveaways, dancing (in your home), laughing yoga, music, key note speaker and more'
          ]} 
          />
      </OpaqueBlock>
      <Block>
        <Smolumn>
          <h1><strong>5,000 attendees</strong></h1>
          <h1><strong>500 breakout rooms</strong></h1>
          <h1><strong>35% increase in happiness</strong></h1>
        </Smolumn>
      </Block>
      <OpaqueBlock>
        <BlockText size={2}><Purple>OUR WORK HAS BEEN RECOGNIZED BY</Purple></BlockText>
        <Smolumn>
          <div><img src='/images/logos/united-nations.jpg' style={{ height: '3em', margin: '.5em' }}  alt='United Nations' /></div>
          <div><img src='/images/logos/pbs.png' style={{ height: '3em', margin: '.5em' }} alt='PBS' /></div>
          <div><img src='/images/logos/tedx-unlv.png' style={{ height: '3em', margin: '.5em' }}  alt='TEDx Talks' /></div>
        </Smolumn>
      </OpaqueBlock>
      <Block>
        <BlockText size={3}>BE AT THE LARGEST AND HAPPIEST VIRTUAL EVENT IN HISTORY</BlockText>
        <RegisterLink />
        <SponsorButton />
        <p>&copy; 2015 - {year} Happy Neighborhood Project. All Rights Reserved.</p>
      </Block>
      <SponsorModal visible={showSponsorForm} onClose={() => setShowSponsorForm(false)} />
    </Page>
  )
}

export const GlobalSummitLandingComponent = () => {
  const [showSponsorForm, setShowSponsorForm] = useState(false);

  return (
    <>
      <Disclaimer />
      <Block>
        <BlockText style={{ marginBottom: 0 }} size={2}><Yellow>October 10th, <SmolBreak />10am to 4pm (Pacific)</Yellow></BlockText>
        <BlockText size={4}>THE 2020 <SmolBreak />GLOBAL HAPPINESS<SmolBreak /> SUMMIT</BlockText>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0em', marginTop: '0em' }}>
          <span style={{ fontWeight: 'bold', marginRight: '.5em' }}>partnered with</span>
          <SmolLogo src='/images/logos/dell-64.png' />
          <SmolLogo src='/images/logos/zoom-128.png' />
          <SmolLogo src='/images/logos/sellyourmac.png' />
          <SmolLogo src='/images/logos/thryv-light.png' />
        </div>
        <Vimeo style={{ margin: '2em' }} id='445895874' />
        <RegisterLink />
        <SponsorButton onClick={() => setShowSponsorForm(true)} />
      </Block>
      <OpaqueBlock>
        <BlockText size={3}>
          <Purple>How It Works - Step By Step</Purple>
        </BlockText>
        <Steps 
          steps={[
            'Register To Receive Email + Password',
            'Login with Zoom Link At Event Start Time',
            'Networking, giveaways, dancing (in your home), laughing yoga, music, key note speaker and more'
          ]} 
          />
      </OpaqueBlock>
      <Block>
        <Smolumn>
          <h1><strong>5,000 attendees</strong></h1>
          <h1><strong>500 breakout rooms</strong></h1>
          <h1><strong>35% increase in happiness</strong></h1>
        </Smolumn>
      </Block>
      <Block>
        <BlockText size={3}>BE AT THE LARGEST AND HAPPIEST VIRTUAL EVENT IN HISTORY</BlockText>
        <RegisterLink />
        <SponsorButton onClick={() => setShowSponsorForm(true)} />
      </Block>
      <SponsorModal visible={showSponsorForm} onClose={() => setShowSponsorForm(false)} />
    </>
  )
};

export default GlobalSummitLanding;

const SponsorButton = ({ onClick }) => (
  <button className='btn btn-primary mb-2' onClick={onClick}>
    Become/Suggest a Sponsor
  </button>
)

const SponsorModal = ({ visible, onClose = () => {} }) => {

  return (
    <div className={classNames('modal', { active: visible })} id='modal-id'>
      <div className='modal-overlay' aria-label='Close' onClick={onClose} />
      <div className='modal-container'>
        <div className='modal-header'>
          <button className='btn btn-clear float-right' onClick={onClose} aria-label='Close' />
          <div className='modal-title h2'>
            Become/Suggest a Sponsor
          </div>
        </div>
        <div className='modal-body'>
          <SponsorForm />
        </div>
      </div>
    </div>
  );
}

const Smolumn = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const SmolLogo = styled.img`
  height: 64px;
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: 840px) {
    height: 48px;
  }

  @media (max-width: 600px) {
    height: 32px;
  }
`;

const Vimeo = ({ id, title = '', ...props }) => (
  <Video.Container responsive {...props}>
    <iframe title={title} src={`https://player.vimeo.com/video/${id}?wmode=opaque`} />
  </Video.Container>
);

const Steps = ({ steps }) => (
  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-start' }}>
    { steps.map((step, i) => <Step key={i} number={i+1} text={step} />)}
  </div>
);

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: .5em;

  @media (max-width: 840px) {
    flex: auto;
    width: 100%;
  }
`;

const StepBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255,214,0);
  color: white;
  padding: .5em;
  width: 5em;
  height: 5em;
  margin-bottom: 1em;

  @media (max-width: 840px) {
    font-weight: bold;
    width: 4em;
    height: 4em;
  }
`;

const Step = ({ text, number }) => (
  <StepContainer>
    <StepBlock>
      <h2 style={{ margin: 0 }}>{ number }</h2>
    </StepBlock>
    <p>{ text }</p>
  </StepContainer>
);

const Button = styled.button`
  background: rgba(255,214,0,1);
  color: white;
  font-size: 3em;
  font-weight: bold;
  margin: 1em;
  padding: 0.1em 1em;
  border: 2px solid rgba(255,214,0,1);
  border-radius: .25em;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    opacity: .9;
  }

  @media (max-width: 840px) {
    padding: 0.2em 1em;
    font-size: 1.5em;
  }

  @media (max-width: 600px) {
    padding: 0.3em 1em;
    font-size: 1.1em;
  }
`;

const RegisterLink = () => (
  <Button as={ExternalLink} href='https://us02web.zoom.us/webinar/register/WN__nIqklsSRuycNp7MFJZoNA'>
    Reserve My Seat Now
  </Button>
);

const Block = ({ backgroundColor = '#000A', children }) => (
  <Container style={{ backgroundColor, color: 'white', textAlign: 'center', padding: '2em' }}>
    { children }
  </Container>
);

const OpaqueBlock = ({ backgroundColor = 'white', children }) => (
  <div style={{ width: '100%', backgroundColor }}>
    <Container style={{ backgroundColor, color: 'black', textAlign: 'center', padding: '2em' }}>
      { children }
    </Container>
  </div>
);

const BlockText = styled.span`
  line-height: ${({ size }) => `${Math.max(size / 6, 1)}em`};
  font-size: ${({ size }) => `${size}em`};
  font-weight: bold;
  margin: .5em;

  @media (max-width: 840px) {
    line-height: ${({ size }) => `${Math.max(size / 6, 1)}em`};
    font-size: ${({ size }) => `${size / 1.5}em`};
  }
`;

const Yellow = styled.span`
  color: rgba(255,214,0,1);
`;

const Purple = styled.span`
  color: #54319b;
`;

const SmolBreak = styled.br`
  @media (min-width: 840px) {
    display: none;
  }
`;

// export const Disclaimer = () => (
//   <div style={{ backgroundColor: 'red', color: 'white', textAlign: 'center', padding: '.75em' }}>
//     <h4 style={{ margin: 0, }}>
//       <strong style={{ textDecoration: 'underline' }}>Disclaimer:</strong> These Events Are First Come First Serve! Priority Is Given To Those Who Register &amp; Log On Early!
//     </h4>
//   </div>
// );

export const Disclaimer = () => (
  <div style={{ backgroundColor: 'red', color: 'white', textAlign: 'center', padding: '.75em' }}>
    <h4 style={{ margin: 0, }}>
      Before you try to re-register, be sure to check your spam folder for an email from "no-reply@zoom.us"
    </h4>
  </div>
);