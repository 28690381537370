
export function hex2coord(hex: string) {
  const degrees = parseInt(hex.substr(0, 2), 16);
  const floating = parseInt(hex.substr(2), 16);

  // const longitude = floating >= 2000;
  let sign = 1;

  if (floating - 3000 === floating % 1000 || floating - 1000 === floating % 1000) {
    sign = -1;
  }

  return sign * parseFloat(degrees + '.' + floating % 1000);
}

export function hex2latlon(hex: string) {
  if (hex.length === 5) {
    // Probably zip code.
    
    return { 
      latitude: null, 
      longitude: null 
    };
  }

  const latitude = hex2coord(hex.slice(0, 5));
  const longitude = hex2coord(hex.slice(-5));

  return {
    latitude,
    longitude
  };
}

export function coord2Hex(coord: number, longitude?: boolean): string {
  let degrees = Math.floor(Math.abs(coord));
  let floating = parseInt(Math.abs(coord).toFixed(3).toString().split('.')[1], 10);

  if (longitude) {
    floating += 2000;
  }

  if (coord < 0) {
    floating += 1000;
  }

  const hex = degrees.toString(16) + floating.toString(16);

  return degrees.toString(16).length === 2 ? hex : '0' + hex;
}

export default function latLon2Hex(latitude: number, longitude: number): string {
  return (coord2Hex(latitude) + coord2Hex(longitude, true)).toUpperCase();
}