
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink.js';

const MenuItem = ({ to, href, label, onClick, spacer, boxed }) => {  
  const style = useMemo(() => {
    return boxed ? {
      borderColor: '#5755d9'
    } : {}
  }, [boxed]);

  if (spacer) {
    return (
      <div style={{ width: '4em' }} />
    );
  }

  if (to) {
    return (
      <Link to={to} className='btn btn-link hover-scale' style={style} onClick={onClick}>
        {label}
      </Link>
    )
  } else if (href) {
    return (
      <ExternalLink className='btn btn-link hover-scale' style={style} href={href} onClick={onClick}>
        {label}
      </ExternalLink>
    );
  }

  return (
    <button className='btn btn-link' onClick={onClick}>{label}</button> 
  );
}

export default MenuItem;