
import React from 'react';
import classes from './profit-sharing.module.css';
import { Link } from 'react-router-dom';

const profit = () => {
    return (
        <section id={classes.profit}>
            <div className="container">
                <div className={classes.wrapper}>
                    <div className={classes.profitLft}>
                        <h3 className={classes.sectitel}>
                            Revenue Sharing
                        </h3>
                        <p>
                            Both Free Members and Share-Members earn points for certain activities on the HNP platform (<Link to='/coop/points'>see ways to earn points</Link>). Only Share-Members that are <Link to='/membership'>Verified Happy Businesses</Link> and have earned points each quarter can receive Revenue Sharing from the Patronage Fund "Pro-Rata". The more points you earn the more revenue sharing you earn from the Sharing-Pool!
                        </p>
                    </div>
                    <div className={classes.profitrght}>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default profit