
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const Chatbout = () => {
  return (
    <Page title='HNP - Chatbot'>
      <HeaderBar title='Connect with our Zoom Chat Helper' />
      <Container>
        <h1>Add the Chat Helper</h1>
        <p>We have a chatbot that will help you with your connections from our networking meetings. Add it to your Zoom account with the buttton below!</p>
        <a href='https://zoom.us/oauth/authorize?response_type=code&client_id=a94Z60pfSIO_AHYl0cqUbw&redirect_uri=https://hnpabc.com/zoom/chatbot/success' target='_blank' rel='noopener noreferrer'>
          <img src='https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png' height='32' alt='Add to ZOOM' />
        </a>
      </Container>
    </Page>
  )
}

export default Chatbout;