
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Video } from '@react-spectre/media';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';

const NetworkingLanding = () => {
  const history = useHistory();

  const year = useMemo(() => new Date().getFullYear(), []);

  const register = useCallback(() => {
    console.log('clicked register');
    history.push('/network/calls');
  }, [history]);

  return (
    <Page title='HNP - Networking' className='background background-networking-2'>
      <Disclaimer />
      <Block>
        <img alt='logo' src='/images/hnp-logo-192.png' />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em', marginTop: '-2em' }}>
          <span style={{ fontWeight: 'bold', marginRight: '.5em' }}>partnered with</span>
          <SmolLogo src='/images/logos/dell-64.png' />
          <SmolLogo src='/images/logos/zoom-128.png' />
        </div>
        <BlockText size={4}><Yellow>Get Referrals</Yellow> With <SmolBreak />Like-Minded Business Owners <SmolBreak /><Yellow>From Home</Yellow>.</BlockText>
        <div style={{ marginTop: '1em' }}>
          <BlockText size={2}>Our Work Has Been Recognized By</BlockText>
          <br />
          <br />
          <SmolLogo src='/images/logos/pbs-64.png' />
          <SmolLogo src='/images/logos/tedx-unlv-64.png' />
          <SmolLogo src='/images/logos/united-nations-64.jpg' />
        </div>
        <RegisterButton onClick={register} />
      </Block>
      <OpaqueBlock>
        <Smolumn style={{ marginBottom: '1.5em' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <BlockText size={2.5}>
              <Purple>Read This Before Going <Yellow><span style={{ textDecoration: 'underline' }}>ANY</span></Yellow> Further</Purple>
            </BlockText>
            <p>"Hey I won't be able to attend today's event because I literally got a referral from one of the attendees that I need to meet with. Say hi to everyone for me!"</p>
            <p><small>Facebook Messenger Friday 8th, 2020, 9:05 AM</small><br /><strong>From The Law Office Of Luke Garcia</strong></p>
          </div>
          <img alt='Luke Garcia' style={{ margin: '.25em', maxWidth: '100%' }} src='/images/luke-garcia.png' />
        </Smolumn>
        <BlockText size={3}>WHO ARE THESE EVENTS FOR?</BlockText>
        <ForWhom items={[
          'Accountants', 'Attorneys', 'Auto Services', 'Beauty Services', 'Business-to-business', 'Business-to-consumer', 'Cleaning Services', 'Chiropractors', 'Coaches', 'Consultants', 'Contractors', 'Dental Services', 'Digital Marketers', 'Financial Advisors', 'Health & Wellness', 'Insurance Agents', 'Mortgage Professionals', 'Photographers', 'Startups', 'Realtors', 'Restaurant Owners', 'Therapists', 'Business owners looking to grow their business'
        ]} 
        />
      </OpaqueBlock>
      <Block>
        <BlockText size={3}>THE HAPPIEST ONLINE NETWORKING EVENTS IN THE WORLD</BlockText>
        <BlockText size={2}>Why We Keep These Events Free &amp; How It Works</BlockText>
        <Vimeo style={{ margin: '2em' }} id='420383087' />
        <RegisterButton onClick={register} />
      </Block>
      <OpaqueBlock>
        <BlockText size={3}>
          <Purple>How It Works - Step By Step</Purple>
        </BlockText>
        <Steps 
          steps={[
            'Register To Receive Email & Password',
            'Login with Zoom Link At Event Start Time',
            'Intro I 5 min First Breakout Session I 20 min',
            'Half-time I 5 min Second Breakout Session I 20 min',
            'Leave Event With 10+ New Contacts To Connect With'
          ]} 
          />
        <BlockText size={3} style={{ marginBottom: 0 }}>
          <Purple>DON'T JUST TAKE OUR WORD FOR IT...</Purple>
        </BlockText>
        <BlockText size={2}>See What Others Have Said About Their Experience!</BlockText>
        <VimeoQuote
          id='420293309'
          author='Stefanie Thayer'
          title='Wellness With Stef'
          quote={`"I have been able to network with some phenomenal people, some that have resulted in being clients for me and some that I have been able to connect with in other ways. My favorite thing about this community is that they all just have genuine hears and they passionately care about people."`}
          />
        <VimeoQuote
          id='420293896'
          author='Dr. Chip Studley'
          title='Studley Chiropractic'
          quote={`"I would encourage you guys to check it out. Just talking to people, people have asked me questions about my business and I've actually had patients come in from it so it gained me a little bit of business as well. But more than anything it's a great way to connect with people and it's great for me to be a resource to my community."`}
          reverse
          />
      </OpaqueBlock>
      <Block>
        <BlockText size={3}>START GETTING REFERRALS WITH LIKE-MINDED BUSINESS OWNERS</BlockText>
        <RegisterButton onClick={register} />
        <p>&copy; 2015 - {year} Happy Neighborhood Project. All Rights Reserved.</p>
      </Block>
    </Page>
  )
}

export default NetworkingLanding;

const Smolumn = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const SmolLogo = styled.img`
  height: 64px;
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: 840px) {
    height: 48px;
  }

  @media (max-width: 600px) {
    height: 32px;
  }
`;

const Vimeo = ({ id, title = '', ...props }) => (
  <Video.Container responsive {...props}>
    <iframe title={title} src={`https://player.vimeo.com/video/${id}?wmode=opaque`} />
  </Video.Container>
);

const QuoteHolder = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  margin-top: .5em;

  @media (max-width: 840px) {
    flex-direction: column;
    margin-top: 2em;
  }
`;

const VimeoQuote = ({ id, quote, author, title, reverse }) => (
  <QuoteHolder reverse={reverse}>
    <div style={{ flex: 3 }}>
      <Vimeo id={id} />
    </div>
    <div style={{ width: '.5em', height: '.5em' }} />
    <div style={{ display: 'flex', flexDirection: 'column', padding: '.5em', flex: 2, backgroundColor: '#54319b', color: 'white', alignItems: 'center', justifyContent: 'center' }}>
      <h4>{ quote }</h4>
      <h5><Yellow>{ title }</Yellow></h5>
      <h5><Yellow>{ author }</Yellow></h5>
    </div>
  </QuoteHolder>
);

const Steps = ({ steps }) => (
  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
    { steps.map((step, i) => <Step key={i} number={i+1} text={step} />)}
  </div>
);

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5em;

  @media (max-width: 840px) {
    width: 100%;
  }
`;

const StepBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255,214,0);
  color: white;
  padding: .5em;
  width: 5em;
  height: 5em;
  margin-bottom: 1em;

  @media (max-width: 840px) {
    font-weight: bold;
    width: 4em;
    height: 4em;
  }
`;

const Step = ({ text, number }) => (
  <StepContainer>
    <StepBlock>
      <h2 style={{ margin: 0 }}>{ number }</h2>
    </StepBlock>
    <p>{ text }</p>
  </StepContainer>
);

const Whom = styled.span`
  margin-top: .25em;
  margin-bottom: .25em;
  font-size: 1.5em;
  width: 33%;

  @media (max-width: 840px) {
    font-size: 1em;
    margin-top: .15em;
    margin-bottom: .15em;
    width: 50%;
  }
`;

const ForWhom = ({ items }) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    { items.map( (item, i) => (
      <Whom key={i}>{'✓'} { item }</Whom>
    ))}
  </div>
);

const Button = styled.button`
  background: rgba(255,214,0,1);
  color: white;
  font-size: 3em;
  font-weight: bold;
  margin: 1em;
  padding: 0.1em 1em;
  border: 2px solid rgba(255,214,0,1);
  border-radius: .25em;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }

  @media (max-width: 840px) {
    padding: 0.2em 1em;
    font-size: 1.5em;
  }

  @media (max-width: 600px) {
    padding: 0.3em 1em;
    font-size: 1.1em;
  }
`;

const RegisterButton = ({ onClick }) => (
  <Button onClick={onClick} style={{ textTransform: 'uppercase' }}>
    Reserve My Seat Now
  </Button>
);

const Block = ({ backgroundColor = '#000A', children }) => (
  <Container style={{ backgroundColor, color: 'white', textAlign: 'center', padding: '2em' }}>
    { children }
  </Container>
);

const OpaqueBlock = ({ backgroundColor = 'white', children }) => (
  <div style={{ width: '100%', backgroundColor }}>
    <Container style={{ backgroundColor, color: 'black', textAlign: 'center', padding: '2em' }}>
      { children }
    </Container>
  </div>
);

const BlockText = styled.span`
  line-height: ${({ size }) => `${Math.max(size / 6, 1)}em`};
  font-size: ${({ size }) => `${size}em`};
  font-weight: bold;
  margin: .5em;

  @media (max-width: 840px) {
    line-height: ${({ size }) => `${Math.max(size / 6, 1)}em`};
    font-size: ${({ size }) => `${size / 1.5}em`};
  }
`;

const Yellow = styled.span`
  color: rgba(255,214,0,1);
`;

const Purple = styled.span`
  color: #54319b;
`;

const SmolBreak = styled.br`
  @media (min-width: 840px) {
    display: none;
  }
`;

export const Disclaimer = () => (
  <div style={{ backgroundColor: '#54319b', color: 'white', textAlign: 'center', padding: '.75em' }}>
    <h4 style={{ margin: 0, }}>
      <strong style={{ textDecoration: 'underline' }}>Disclaimer:</strong> These Events Are First Come First Serve! Priority Is Given To Those Who Register &amp; Log On Early!
    </h4>
  </div>
);