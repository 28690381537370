
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import useCalls from '../data/useCalls';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import { Disclaimer } from './NetworkingLanding';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';

const sortCalls = (calls) => calls.slice().sort((a, b) => a.location.localeCompare(b.location));

const NetworkingCalls = () => {
  const [filter, setFilter] = useState('');
  const calls = useCalls();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sorted = useMemo(() => sortCalls(calls), [calls]);

  const filtered = useMemo(() => {
    return sorted.filter(call => {
      if (call.location === 'Worldwide') {
        return false;
      } else if (!filter) {
        return true;
      }

      return call.location.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
  }, [filter, sorted]);

  const worldwide = useMemo(() =>
    calls.find(c => c.location === 'Worldwide'),
  [calls]);

  return (
    <Page title='HNP - Networking' className='background background-networking-2'>
      <Disclaimer />
      <Block>
        <SearchContainer>
          <div className='input-group'>
            <input 
              className='form-input' 
              onChange={e => setFilter(e.currentTarget.value)} 
              placeholder='Search...'
              />
            <button 
              className='btn btn-primary input-group-btn' 
              onClick={() => setFilter('')}>
                Clear
            </button>
          </div>
        </SearchContainer>
        { Boolean(worldwide) && 
          <Call call={worldwide} worldwide /> 
        }
        { filtered.map((call, i) => (
          <Call key={i} call={call} />
        ))}
      </Block>
    </Page>
  )
}

export default NetworkingCalls;

const SearchContainer = styled.div`
  width: 100%;
  padding-left: 30%;
  padding-right: 30%;
  margin-bottom: 1em;

  @media (max-width: 840px) {
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

const Button = styled(Link)`
  width: 30%;
  margin: 1.5%;
  align-self: stretch;

  &:hover {
    opacity: 90%;
  }

  @media (max-width: 840px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Call = ({ call, worldwide }) => (
  <Button to={`/network/calls/${call.slug}`}>
    <div style={{ padding: '.5em', backgroundColor: worldwide ? 'rgb(84, 49, 155)' : 'rgba(255,214,0,1)', color: worldwide ? 'white' : 'rgba(84, 49, 155)', display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: '2em', fontWeight: 'bold' }}>{ call.location }</span>
      <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{ dateFormat(call.next, 'dddd') }s</span>
      <span style={{ fontSize: '1em', fontWeight: 'bold', marginTop: '-.35em' }}>
        { dateFormat(call.next, 'h:MMTT (Z)') }
      </span>
    </div>
  </Button>
);

const Block = ({ backgroundColor = '#000A', children }) => (
  <Container style={{ backgroundColor, color: 'white', textAlign: 'center', padding: '3em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
    { children }
  </Container>
);