
import React from 'react';
import { Helmet } from 'react-helmet';

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
};

interface Props {
  children: React.ReactChild | Array<React.ReactChild>;
  title: string;
  style?: any;
  className?: string;
}

const Page = ({ children, title = 'HNP', style, ...props }: Props) => (
  <div style={{...styles.container, ...style}} {...props}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    { children }
  </div>
);

export default Page;