
import React from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink.js';
import useSponsors from '../data/sponsors';

const SeenIn = () => {
  const sponsors = useSponsors(true);

  return (
    <div className='seen-in'>
      { sponsors.map((sponsor) => {
          if (sponsor.to) {
            return (
              <Link key={sponsor.name} to={sponsor.to} className='seen-in-logo-parent hover-scale'>
                <img 
                  className='seen-in-logo' 
                  src={sponsor.image} 
                  alt={sponsor.name} 
                  title={sponsor.name} 
                  />
              </Link>
            );
          }
          
          if (sponsor.href) {
            return (
              <ExternalLink key={sponsor.name} href={sponsor.href} className='seen-in-logo-parent hover-scale'>
                <img 
                  className='seen-in-logo' 
                  src={sponsor.image} 
                  alt={sponsor.name} 
                  title={sponsor.name} 
                  />
              </ExternalLink>
            );
          }

          return (
            <div key={sponsor.name} className='seen-in-logo-parent'>
              <img 
                className='seen-in-logo' 
                src={sponsor.image} 
                alt={sponsor.name} 
                title={sponsor.name} 
                />
            </div>
          );
        })
      }
    </div>
  );
}

export default SeenIn;