import React from 'react'
import logo from '../../assets/img/logo.jpg'
import classes from './header.module.css'
const header = ()=>{
    return(
        <header>
            <div className="container">
               <a className={classes.logo} href={window.location.origin.toString()}>
                   <img src={logo} alt="logo" />
               </a>
            </div>
        </header>
    )
}
export default header;