
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Video as Vid } from '@react-spectre/media';

const Video = ({src, aspect, children, iframe, title = 'Video'}) => {

  if (iframe) {
    return (
      <Vid.Container responsive> 
        <iframe
          src={src}
          title={title}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
          frameBorder={0}
          allowFullScreen
        />
      </Vid.Container>
    );
  }

  if (String(aspect).toLowerCase() === 'square') {
    return (
      <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
        <div style={{height: '100%', width: '100%', position: 'absolute', top: 0}}>
          { src &&
            <video
              src={src}
              style={{width: '100%', height: '100%', objectFit: 'cover', backgroundColor: 'grey'}}
              controls
              preload='auto'
              >
                {children}
            </video>
          }
        </div>
      </div>
    );
  }

  if (src) {
    return (
      <video
        src={src}
        style={{ backgroundColor: 'grey' }}
        className={classNames('video-responsive', { 'video-responsive-1-1': aspect === 'square' })}
        controls
        preload='auto'
        >
          { children }
      </video>
    );
  }

  return null;
} 

Video.propTypes = {
  src: PropTypes.string,
  aspect: PropTypes.oneOf(['wide', 'square']),
  children: PropTypes.any,
  iframe: PropTypes.bool,
  title: PropTypes.string
};

export default Video;