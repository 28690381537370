
import React from 'react';

const PIXEL_ID = '2662239060715252';

const FacebookPixel = ({ id, event = 'PageView' }) => {
  const url = `https://www.facebook.com/tr?id=${id || PIXEL_ID}&amp;ev=${event}`;

  return (
    <img 
      alt='unvis'
      src={url} 
      height={1} 
      width={1}
      style={{ display: 'none' }}
      />
  );
}

export default FacebookPixel;