
import React from 'react'
import Hero from '../components/hero/hero'
import About from '../components/about/about'
import Trust from '../components/trust/trust'
import Whyus from '../components/why-us/whyus';
import ProsfitSharing from '../components/profit-sharing/profit-sharing'
import EarnSP from '../components/earn-share-points/earn-share-points'
import Faq from '../components/FAQ/faq'

const main = () => {
    return (
        <main>
            <Hero />
            <About />
            <Trust />
            <Whyus />
            <ProsfitSharing />
            <EarnSP />
            <Faq />
        </main>
    )
}

export default main;
