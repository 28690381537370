
import React, { useCallback, useEffect } from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import toggleArray from '../../tools/toggleArray';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);
// TODO make required work!

export const Checkbox = (props) => {
  const { value, label, checked, horizontal, columns, onChange = () => {} } = props;

  return (
    <label className={classNames('form-checkbox', { 'form-inline' : horizontal || columns, 'col-6': columns, 'col-sm-12': columns })}>
      <input 
        type='checkbox'
        value={value}
        checked={checked}
        onChange={e => onChange(e.currentTarget.value)}
        />
      <i className="form-icon"></i> { label }
    </label>
  );
}

const Checkboxes = (props) => {
  const { options = [], horizontal, defaultValue, getValue, setValue, label, showRequired, isPristine, isValid, showError, 
    getErrorMessage, resetValue, setValidations, validationError, validationErrors, getErrorMessages, hasValue, isFormDisabled, isFormSubmitted, isRequired, isValidValue, placeholder, extractKey, extractLabel, extractValue, showSelectAll, columns, ...rest } = props;

  useEffect(() => {
    if (defaultValue && !getValue()) {
      setValue([defaultValue]);
    }
  }, [defaultValue, getValue, setValue]);

  const error = getErrorMessage();

  const CheckboxOptions = useCallback(() => {
    const values = getValue() || [];

    const checkboxes = options.map((option, i) => {
      const key = extractKey ? extractKey(option) : option.id || option.uuid || option;
      const label = extractLabel ? extractLabel(option) : option.label || option.name || option;
      const value = extractValue ? extractValue(option) : option.value || option.id || option.uuid || option;
      const checked = values.indexOf(value) > -1;

      return (
        <Checkbox 
          key={key}
          label={label}
          value={value}
          checked={checked}
          horizontal={horizontal}
          columns={columns}
          onChange={val => setValue(toggleArray(values, val))}
          />
      );
    });

    return checkboxes;
  }, [options, getValue, setValue, horizontal, extractKey, extractLabel, extractValue, columns]);

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      { Boolean(showSelectAll) && (
        <div className={classNames({ 'text-center': columns })}>
          <button 
            className="btn btn-link" 
            onClick={e => {
              e.preventDefault();
              setValue(options.map(option => 
                extractValue ? extractValue(option) : option.value || option.id || option.uuid || option
              ));
            }} >
              Select All
            </button>
            <span> / </span>
            <button 
              className="btn btn-link" 
              onClick={e => {
                e.preventDefault();
                setValue(defaultValue ? [defaultValue] : []);
              }} >
                De-Select All
              </button>
        </div>
      )}
      <div className={classNames('col-sm-12', { 'col-9': horizontal })} {...rest}>
        <CheckboxOptions />
      </div>
      <div className={classNames('col-sm-12', { 'col-9 col-offset-3': horizontal })} {...rest}>
        { Boolean(error) && (
          <p className="form-input-hint">{ error }</p>
        ) }
      </div>
    </div>
  );
}

export default withFormsy(Checkboxes);