
// - Supported YouTube URL formats:
//   - http://www.youtube.com/watch?v=My2FRPA3Gf8
//   - http://youtu.be/My2FRPA3Gf8
//   - https://youtube.googleapis.com/v/My2FRPA3Gf8
// - Supported Vimeo URL formats:
//   - http://vimeo.com/25451551
//   - http://player.vimeo.com/video/25451551
// - Also supports relative URLs:
//   - //player.vimeo.com/video/25451551

const VIDEO_REGEX = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/;

function parseVideoUrl (url: string) {

  const regex = url.match(VIDEO_REGEX);

  const domain = regex ? regex[3] : '';
  const id = regex ? regex[6] : '';

  let type: 'youtube' | 'vimeo' | undefined;
  if (domain.indexOf('youtu') > -1) {
      type = 'youtube';
  } else if (domain.indexOf('vimeo') > -1) {
      type = 'vimeo';
  }

  return { type, id };
}

export function isValidVideoUrl(url: string) {
  const { type, id } = parseVideoUrl(url);

  return Boolean(type) && Boolean(id);
}

export async function getThumbnail(url: string) {
  const { type, id } = parseVideoUrl(url);

  if (type && id) {
    if (type === 'youtube') {
      return `//img.youtube.com/vi/${id}/maxresdefault.jpg`;
    } else if (type === 'vimeo') {
      const result = await fetch(`http://vimeo.com/api/v2/video/${id}.json`);
      const data = await result.json();
  
      return data[0].thumbnail_large as string;
    }
  }

  return '';
}

export function getEmbedUrl(url: string) {
  const { id, type } = parseVideoUrl(url);

  if (type && id) {
    if (type === 'youtube') {
      return `//www.youtube.com/embed/${id}`;
    } else if (type === 'vimeo') {
      return `//player.vimeo.com/video/${id}`;
    }
  }

  return '';
}

export default parseVideoUrl;