
import React, { ReactChild } from 'react';
import classNames from 'classnames';

interface Props {
  children?: ReactChild | Array<ReactChild>;
  className?: string;
}

export const Row = ({ children, className }: Props) => {
  return (
    <div className={classNames('landing-row', className)}>
      { children }
    </div>
  );
}

export const Half = ({ children, className }: Props) => {
  return (
    <div className={classNames('col-half', className)}>
      { children }
    </div>
  );
}

export const Third = ({ children, className }: Props) => {
  return (
    <div className={classNames('col-third', className)}>
      { children }
    </div>
  );
}

export const TwoThirds = ({ children, className }: Props) => {
  return (
    <div className={classNames('col-two-thirds', className)}>
      { children }
    </div>
  );
}