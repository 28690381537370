
export default function shuffle<T>(array: Array<T>): Array<T> {
  let temp;
  let current;
  let top = array.length;

  if (top) {
    while(--top) {
      current = Math.floor(Math.random() * (top + 1));
      temp = array[current];
      array[current] = array[top];
      array[top] = temp;
    }
  }

  return array;
}