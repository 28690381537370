
import React from 'react';
import classNames from 'classnames';
import {
  useTable,
  useSortBy
} from 'react-table';

const columns = [{
  Header: 'First Name',
  accessor: 'first_name'
}, {
  Header: 'Last Name',
  accessor: 'last_name'
}, {
  Header: 'How Did You Hear?',
  accessor: 'howDidYouHear'
}, {
  Header: 'Referred?',
  accessor: 'referrer'
}]

const ZoomRegistrants = ({ registrants }) => {
  console.log({ registrants });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ 
    columns, 
    data: [],
    initialState: {
      sortBy: [{
        id: 'last_name',
        desc: false
      }]
    }
  }, useSortBy);

  return (
    <table className='table' {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
}

const RegistrantsModal = ({ registrants, onClose }) => {
  return (
    <div className={classNames('modal', { active: Boolean(registrants) })} id='modal-id'>
      <div className='modal-overlay' aria-label='Close' onClick={onClose} />
      <div className='modal-container'>
        <div className='modal-header'>
          <button className='btn btn-clear float-right' onClick={onClose} aria-label='Close' />
          <div className='modal-title h2'>
            Registrants
          </div>
        </div>
        <div className='modal-body'>
          <div className='content'>
            <ZoomRegistrants registrants={registrants} />
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default RegistrantsModal;