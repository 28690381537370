
import React, { useMemo } from 'react';
import classNames from 'classnames';

export default function Steps({ current = 0, steps = [] }) {
  const items = useMemo(() => steps.map((step, i) => (
    <li key={i} className={classNames('step-item', { active: current === i})}>
      <button className='tooltip' data-tooltip={step}>{step}</button>
    </li>
  )), [current, steps]);

  return (
    <ul className='step'>
      { items }
    </ul>
  );
}