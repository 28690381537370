
import { gql, useQuery } from '@apollo/client';

export interface Pricing {
  total: number;
  regular: number;
  discount: number;
  coupon: string;
  splits?: Splits;
}

export interface Split {
  amount: number;
  total: number;
  due: string;
  description: string;
}

export type Splits = Array<Split>;

export interface LocalPricingResponse {
  pricing: Pricing
}

const LocalPricingQuery = gql`
  query Pricing($zips: [String]!, $coupon: String) {
    pricing(zips: $zips, coupon: $coupon) {
      total
      regular
      discount
      coupon
    }
  }
`;

export const useLocalPricing = (zips: Array<string>, coupon?: string) => {
  const { data } = useQuery<LocalPricingResponse>(LocalPricingQuery, {
    variables: {
      zips, coupon
    }
  });

  return data?.pricing;
}

export default useLocalPricing;