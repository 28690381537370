
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { hex2latlon } from '../tools/latlon2hex';
import { gql, useQuery } from '@apollo/client';
import Container from '../components/layout/Container';
import Page from '../components/layout/Page';

interface ListingsListParams {
  slug: string;
  zip: string;
}

export interface Listing {
  uuid: string;
  name: string;
  blurb: string;
  friendly_id: string;
  distance: number;
}

const query = gql`
  query GetListings($slug: String! $latitude: Float $longitude: Float $zip: String) {
    listings(category_slug: $slug latitude: $latitude longitude: $longitude zip: $zip) {
      uuid
      name
      blurb
      friendly_id
      distance
    }
  }
`;

// const metersToMiles = (meters: number = 0) => meters / 1609.344;

const ListingItem = ({ listing }: { listing: Listing }) => {
  const miles = false; // metersToMiles(listing.distance);

  return (
    <Link to={{state: { listing }, pathname: `/listings/${listing.uuid}`}}>
      <h5>
        <strong>
          {listing.name} { miles ? `(${miles} miles)` : ''}
        </strong>
        <br />
        <small>
          {listing.blurb}
        </small>
      </h5>
    </Link>
  );
}

const Listings = () => {
  const { slug, zip } = useParams<ListingsListParams>();
  const { latitude, longitude } = hex2latlon(zip);
  const { data, loading } = useQuery<{listings: Array<Listing>}>(query, {
    variables: {
      slug, zip, latitude, longitude
    }
  });

  return (
    <Page title={`HNP - Listings for ${slug} in ${zip}`}>
      <Container style={{textAlign: 'left', alignItems: 'left'}}>
        { loading ?
          <p>{ loading ? 'Loading...' : '' }</p> :
          <h3>Happy Business Members</h3>
        }
        {
          data?.listings.map((listing, i) => (
            <ListingItem listing={listing} />
          ))
        }
      </Container>
    </Page>
  );
}

export default Listings;