
import React, { useState, useCallback } from 'react';
import Formsy from 'formsy-react';
import ZipsInput from '../components/form/ZipsInput.js';

const SelectZipsForm = ({ onChange, onNext, onPrev }) => {
  const [valid, setValid] = useState(false);

  const _onChange = useCallback((values, isChanged) => {
    onChange && onChange(values.zips);
  }, [onChange]);
  
  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onChange={_onChange}
      className='col-12'
      >
      <h2>Select Zip Codes</h2>
      <ZipsInput name='zips' required />
      <div className='col-12 clearfix mt-2'>
        <button className='btn btn-primary float-left' onClick={onPrev}>Previous</button>
        <button className='btn btn-primary float-right' onClick={onNext} disabled={!valid}>Next</button>
      </div>
    </Formsy>
  );
}

export default SelectZipsForm;