
import React, { useState, useEffect, useMemo } from 'react';
import Formsy from 'formsy-react';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';
import StripeInput from './form/StripeInput.js';
import Input from './form/Input.js';
import Divider from '../components/Divider.js';
import { getPricing, getRemotePricing } from '../API.js';
import Hider from './Hider';
import Radio from './form/Radio.js';

interface PaymentInformationFormProps {
  remote?: boolean;
  zips?: Array<string>;
  processing?: boolean;
  error?: string;
  onChange: (values: any, valid?: boolean) => void;
  onPrev?: () => void;
  onNext: () => void;
}

export function centsToUSD(cents: number) {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

const PaymentInformationForm = ({ remote, zips, onChange, onNext, onPrev, processing, error }: PaymentInformationFormProps) => {
  const [valid, setValid] = useState<boolean>(false);
  const [pricing, setPricing] = useState<any>({});
  const [code, setCode] = useState<string>();
  const [price, setPrice] = useState<string>('---');
  const [coupon, setCoupon] = useState<string>();
  const [cardInvalidated, setCardInvalidated] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors] = useState<any>({});
  const [showCouponEntry] = useState<boolean>(false);

  const _onChange = useMemo(() => debounce(600, false, (values: any, isChanged: boolean) => {
    switch (values.frequency) {
      case 'yearly':
        setPrice('$100 setup + $799 / year');
        break;
      case 'monthly':
        setPrice('$100 setup + $79 / month');
        break;
      default:
        setPrice('---');
        break;
    }

    onChange && onChange(values, valid);
  }), [valid, onChange]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        if (remote) {
          setPricing(await getRemotePricing(coupon));
        } else {
          setPricing(await getPricing(zips, coupon));
        }
      } catch (e) {
        console.warn('useEffect getPricing', error);
      }

      setLoading(false);
    })();
  }, [remote, zips, coupon, error]);

  useEffect(() => {
    if (error === 'Card Declined') {
      setCardInvalidated(Date.now());
    }
  }, [error, setCardInvalidated]);
  
  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onChange={_onChange}
      validationErrors={validationErrors}
      className={classNames('col-12')}
      >
      <Input type='hidden' name='price' value={parseInt(pricing.total || 0)} />
      <Input type='hidden' name='coupon' value={coupon} />
      <Input type='hidden' name='type' value='basic' />
      <Hider hidden={processing}>
        <div className={classNames('card', {loading})}>
          <div className='card-body'>
            <Radio 
              horizontal 
              label='Payment Frequency' 
              name='frequency' 
              options={['monthly', 'yearly']} 
              style={{ textTransform: 'capitalize' }} 
              required
              />  
          </div>
          <div className='card-footer'>
            Amount: { price }
          </div>
        </div>
        <h2 className='mt-2'>Enter Payment Information: </h2>
        { (!pricing || pricing.total >= 50) && (
          <StripeInput key={cardInvalidated} name='stripe' required />
        )}
        {/* <button className={classNames('btn', 'btn-link', { 'd-none': showCouponEntry })} onClick={() => setShowCouponEntry(true)}>I have a coupon code</button> */}
        <Hider hidden={!showCouponEntry || pricing.discount} >
          <div className='input-group col-9'>
            <input 
              className="form-input" 
              value={code}
              placeholder='Coupon Code'
              onChange={e => setCode(e.currentTarget.value)}
              />
            <button className="btn btn-primary input-group-btn" onClick={() => setCoupon(code)}><i className="form-icon icon icon-check"></i></button>
          </div>
        </Hider>
      </Hider>
      <Hider hidden={!processing}>
        <div className='loading loading-lg' />
        <p>Processing Payment...</p>
      </Hider>
      <Hider className='toast toast-error mt-2' hidden={!error}>
        { error }
      </Hider>
      <Divider />
      <div className='col-12 clearfix'>
        <button className='btn btn-primary float-left' onClick={onPrev} disabled={processing}>Previous</button>
        <button className='btn btn-success float-right' onClick={onNext} disabled={Boolean(error) || !valid || validationErrors.frequency || processing}>Register!</button>
      </div>
    </Formsy>
  );
}

export default PaymentInformationForm;