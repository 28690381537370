
import React from 'react';
import classNames from 'classnames';

const HookFormInput = ({ name, label, placeholder, errorMessage, form, formProps }) => {
  const { register, errors } = form;
  const error = errors[name];
  
  return (
    <div className={classNames('form-group', { 'has-error': Boolean(error) })}>
      { Boolean(label) &&
        <label className='form-label' htmlFor={name}>
          { label }
          <span style={{ color: 'red' }}>{ Boolean(formProps && formProps.required) ? '*' : ''}</span>
        </label>
      }
      <input 
        className='form-input' 
        placeholder={placeholder} 
        name={name} 
        ref={register(formProps)} 
        />
      { Boolean(error) && 
        <p className='form-input-hint'>{ errorMessage || 'Required.' }</p> 
      }
    </div>
  );
}

export default HookFormInput;