
import React, { useState, useCallback, useEffect } from 'react';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
import fuzzysearch from 'fuzzysearch';
import { getCategories } from '../../API';
import { useCategory } from '../../HnpContext';

// TODO Cache Categories For At Least 5 Minutes

const styles = {
  item: { 
    paddingLeft: '.1em', 
    paddingRight: '.1em', 
    textAlign: 'left' 
  },
  menu: {
    zIndex: 1,
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
  }
}

const CategorySelect = ({ onChange, ...props }) => {
  const [category, setCategory] = useCategory();
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState(category ? category.name : '');
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setValue(value => {
      if (!value && category) {
        return category.name;
      }

      return value;
    });
  }, [category]);

  useEffect(() => {
    setChanged(false);
    if (changed) {
      onChange && onChange(category);
    }
  }, [category, changed, onChange]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        setCategories(await getCategories());
      } catch (error){
        console.error('getCategories', error);
      }

      setLoading(false);
    })();
  }, []);

  const onChangeText = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onSelectCategory = useCallback((name, cat) => {
    setValue(name);
    if (cat.uuid !== category.uuid) {
      setCategory(cat);
      setChanged(true)
    }
  }, [category, setCategory]);

  const shouldItemRender = useCallback((category, value) => {
    return fuzzysearch(value.toLowerCase(), category.name.toLowerCase());
    // return category.name.toLowerCase().indexOf(value.toLowerCase()) > -1
  }, []);

  return (
    <Autocomplete
      value={value}
      getItemValue={item => item.name}
      items={categories}
      onChange={onChangeText}
      onSelect={onSelectCategory}
      shouldItemRender={shouldItemRender}
      menuStyle={styles.menu}
      autoHighlight
      selectOnBlur
      wrapperStyle={{display: null}}
      renderItem={(item, isHighlighted) => (
        <div key={item.uuid} style={{...styles.item, background: isHighlighted ? 'lightgray' : 'white'}}>
          {item.name}
        </div>
      )}
      renderInput={props => (
        <div style={{height: 'min-content'}} className='input-group has-icon-right'>
          <span className="input-group-addon">Find</span>
          <input 
            type='text' 
            className='form-input' 
            placeholder={loading ? 'Loading Categories...' :'Coaches, Realtors, Dentists, Restaurants...'}
            {...props}
            />
          <i className={classNames({'form-icon': true, loading })} />
        </div>
      )}
      />
  );
}

export default CategorySelect;