
import React, { useState, useCallback, useEffect } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Formsy from 'formsy-react';
import Input from '../components/form/Input.js';
import Divider from '../components/Divider';
import FormsyStripeCreditCardInput from '../components/form/FormsyStripeCreditCardInput.js';
import classNames from 'classnames';
import { gql, useMutation } from '@apollo/client';
import RequiredCheckbox from '../components/form/RequiredCheckbox';
import { useMemo } from 'react';
import useCoopReferrer from '../data/useCoopReferrer';
import { Link } from 'react-router-dom';
import useCookie from '../tools/useCookie';
import useMember from '../tools/useMember';

const mutation = gql`
  mutation CoopRegistration($data: CoopRegistrationInput!) {
    coop_registration(data: $data) {
      uuid
      token
    }
  }
`;

interface Props {
  freeDefault?: boolean;
  networking?: {
    meeting_id: number;
    occurrence_id: number;
  }
}

const CoopRegistrationForm = ({ freeDefault }: Props) => {
  const [, setToken] = useCookie('token');
  const [free, setFree] = useState<boolean>(!!freeDefault);
  const [referrer_uuid] = useCoopReferrer();
  const [valid, setValid] = useState<boolean>(false);
  const [register, { data, error, loading }] = useMutation(mutation, {
    onCompleted: data => {
      console.log('completed mutation', { data });

      if (data?.coop_registration?.token) {
        setToken(data.coop_registration.token);
      }
    }
  });

  const ccError = useMemo(() => {
    if (error?.message) {
      if (error.message.indexOf('email') === -1) {
        return Date.now();
      }
    }

    return 1;
  }, [error]);

  const submit = useCallback(async (data) => {
    try {
      data.share = !free;

      if (data?.token) {
        data.stripe_token = data.token.id;
        delete data.token;
      }

      delete data.confirm_email;

      const result = await register({ variables: { data }});

      console.log('Register for The Happy CoOp', result);
    } catch(error) {
      console.error('Register for The Happy CoOp', error);
    }
  }, [register, free]);

  if (data?.coop_registration?.uuid) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've successfully registered for The Happy CoOp!</h2>
        <p>Check your email for your login link!</p>
      </div>
    );
  }

  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onSubmit={submit}
      className='col-8'
      >
      { Boolean(referrer_uuid) ? (
        <>
          <h2>Please Enter Your Information!</h2>
          <Input type='hidden' name='ref_uuid' value={referrer_uuid} />
          <Input type='hidden' name='ref_type' value='coop-member' />
        </>
      ) : (
        <h2>Please Enter Your Information</h2>
      )}
      <Input name='name.first' label='First Name' placeholder='Your First Name' required />
      <Input name='name.last' label='Last Name' placeholder='Your Last Name' required />
      <Input name='company' label='Company Name' placeholder='Company Name (Optional)' />
      <Divider />
      <Input name='email' label='Email' placeholder='name@example.com' validations='isEmail' validationError='Valid email required.' required />
      <Input name='confirm_email' label='Confirm Email' validations='equalsField:email' validationError='Email addresses must match.' autoComplete='none' required />
      <Input name='phone_country_code' label='Phone Country Code' autoComplete='none' />
      <Input name='phone_number' label='Phone Number' required />
      <Divider />
      <Input name='address.street' label='Street Address' required />
      <Input name='address.city' label='City' required />
      <Input name='address.state' label='State/Province' required />
      <Input name='address.zip' label='Zip/Postal Code' />
      <Input name='address.country' label='Country' required />
      <Divider />
      <>
        <span>Membership Type</span>
        <br />
        <label className='form-radio form-inline'>
          <input 
            type='radio'
            value='share'
            checked={!free}
            onChange={e => setFree(false)}
            />
          <i className="form-icon"></i> Share
        </label>
        <label className='form-radio form-inline'>
          <input 
            type='radio'
            value='free'
            checked={free}
            onChange={e => setFree(true)}
            />
          <i className="form-icon"></i> Free
        </label>
      </>
      { !free && (<FormsyStripeCreditCardInput key={ccError} name='token' label='Payment Info' required />) }
      <Divider />
      <RequiredCheckbox 
        name='read_information_statement'
        required
        label={
          <span>I have read the <a href='/files/the-happy-coop-information-statement-1-2021.pdf' target='_blank'>Information Statement</a> and understand the terms set within.</span>
          } 
          />
      <div className='col-12 clearfix my-2'>
        <button type='submit' className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>
          Submit{ !free ? ' ($197)' : '' }
        </button>
      </div>
      <div className='col-12'>
        <span className='text-error'>{ error?.message }</span>
      </div>
    </Formsy>
  );
}

const CoopRegistrationPage = () => {
  const { member, loggedIn, logout } = useMember();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='Join The Happy CoOp'>
      <HeaderBar title='Join The Happy CoOp' />
      <Container>
        <div>
          <p>Thank you for your interest in membership with The Happy CoOp. Share Membership requires payment of a one-time fee of $197. Free membership allows one to accrue points without a voting interest. The Happy Neighborhood Project (HNP) is the manager for The Happy CoOp and our name may appear on your credit card purchase. If you have any questions about the CoOp or us as Manager, please review the Information Statement provided on our website or our Terms of Service.</p>
          <p>Purchase of a Membership Interest in The Happy CoOp is subject to the Bylaws of our Cooperative Association and is not an investment interest but a membership fee.  Share Members have one vote per membership and may qualify to receive a Patronage Refund for certain patronage activities (including being a <Link to='/membership'>Verified Happy Business</Link>) and purchases on this website and our affiliates. There is no guarantee of a Patronage Refund, nor is any commission paid on the Share Membership Fee. See the <a href='/files/the-happy-coop-information-statement-1-2021.pdf' target='_blank'>Information Statement</a> for more.</p>
        </div>
        <hr />
        <Divider />
        { loggedIn ? (
          <div className='text-center'>
            <div className='mb-2'>
              You are already logged in as: { member?.name }. <a href='https://coop.hnpabc.com/'>Visit Backend</a>
            </div>
            <button className='btn btn-warning' onClick={() => logout()}>Logout</button>
          </div>
        ): (
          <CoopRegistrationForm />
        )}
      </Container>
    </Page>
  );
}

export default CoopRegistrationPage;