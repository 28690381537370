
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import { Grid, Row, Col } from '@react-spectre/grid';
import { useTranslation } from'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../components/form/HookFormInput';
import HookFormSelect from '../components/form/HookFormSelect';
import HookFormTextarea from '../components/form/HookFormTextarea';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Divider from '../components/Divider.js';
import { sendCohostInfo } from '../API.js';
import classNames from 'classnames';

const LOCATIONS = [
  'Missouri', 'Washington DC', 'Wisconsin', 'Louisiana', 'Connecticut', 'Alabama', 'Arkansas',
  'Kansas', 'Nebraska', 'Mexico', 'Sydney', 'Ohio', 'United Kingdom', 'Pennsylvania', 'Illinois', 
  'Louisiana', 'Missisipi', 'Arkansas', 'Nebraska', 'Idaho', 'New Mexico', 'Maine', 'New Hampshire',
  'Montana', 'West Virginia', 'Rhode Island', 'South Dakota', 'Delaware', 'Vermont', 'Alaska', 'North Dakota',
  'Wyoming', 'Montreal', 'Ireland', 'Switzerland', 'Tampa', 'Michigan', 'Australia', 'Tennessee', 'India',
  'Virginia', 'New Zealand', 'South Africa', 'Nigeria'
].sort();

LOCATIONS.unshift("Women's Group in Your Region");

// Apply to become a co-host
// Name, Location, Business Name, Email, Website, Phone, Best Call Time, Why You Wanna Host, How Did You Hear About Us
// Create a Select with Other Component

const Cohost = () => {
  const { t } = useTranslation();
  const _form = useForm();
  const { handleSubmit, watch } = _form;
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const location = watch('location');

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setResult(await sendCohostInfo(data));
    setLoading(false);
  };

  if (result) {
    return (
      <Page title='HNP - Become a Networking Co-Host'>
        <HeaderBar>
          <h4>{ t('cohost.heading', 'Co-Hosts Wanted!') }</h4>
        </HeaderBar>
        <Container>
          <div class="empty">
            <div class="empty-icon">
              <i class="icon icon-people"></i>
            </div>
            <p class="empty-title h5">Information Sent!</p>
          </div>
        </Container>
      </Page>
    );
  }

  return (
    <Page title='HNP - Become a Networking Co-Host'>
      <HeaderBar>
        <h4>{ t('cohost.heading', 'Co-Hosts Wanted!') }</h4>
      </HeaderBar>
      <Container>
        <Grid>
          <Row>
            <Col md={12}>
              <h1 className='text-center'>{ t('cohost.title', 'Become a Cohost') }</h1>
              <p>{ t('cohost.about.p1') }</p>
              <p>{ t('cohost.about.p2') }</p>
              <p>{ t('cohost.about.p3') }</p>
              <p>{ t('cohost.targets', 'Desired expansion locations:') }</p>
            </Col>
            <Divider vertical />
            <Col md={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <HookFormInput 
                  name='name' 
                  placeholder='Your Name'
                  label='Name'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormInput 
                  name='company' 
                  placeholder='Your Business Name'
                  label='Business Name'
                  form={_form}
                  />
                <HookFormInput 
                  name='phone' 
                  placeholder='Your Phone Number'
                  label='Phone'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormInput 
                  name='email' 
                  placeholder='Your Email'
                  label='Email'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormInput 
                  name='website' 
                  placeholder='Your Website'
                  label='Website'
                  form={_form}
                  />
                <HookFormSelect 
                  name='location' 
                  placeholder='Your Location'
                  label='Location'
                  hasOther
                  items={LOCATIONS}
                  form={_form}
                  formProps={{ required: true }}
                  />

                { Boolean(String(location).toLowerCase().trim() === 'other') &&
                  <HookFormInput 
                    name='other'
                    placeholder='If your location is not on the list.'
                    form={_form}
                    formProps={{ required: true }}
                    />
                }
                <HookFormTextarea 
                  name='reasoning' 
                  placeholder='Why do you want to become a co-host?'
                  label='Why Co-host?'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <HookFormInput 
                  name='referral' 
                  placeholder='How did you find out about us?'
                  label='How did you find us?'
                  form={_form}
                  formProps={{ required: true }}
                  />
                <button 
                  disabled={loading} 
                  className={classNames('btn btn-primary input-group-btn', { loading })} 
                  type="submit" 
                  >
                    Submit!
                </button>
              </form>
            </Col>
          </Row>
        </Grid>
      </Container>
    </Page>
  )
}

export default Cohost;