
import React, { useState, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Steps from '../components/Steps.js';
import CompanyInfoForm from '../components/CompanyInfoForm.js';
import Hider from '../components/Hider';
import PaymentInformationForm from '../components/PaymentInformationForm-new-new';
import Divider from '../components/Divider.js';
import { gql, useMutation } from '@apollo/client';
import useCoopReferrer from '../data/useCoopReferrer';

// TODO make one way business form
// TODO make payment form separate put coupon there
// TODO use spectre steps

const mutation = gql`
  mutation JoinHappyBusiness($type: String! $business: BusinessInfoInput! $category_uuid: ID! $frequency: String! $stripe_token_id: ID!) {
    join_happy_business(type: $type business: $business category_uuid: $category_uuid frequency: $frequency stripe_token_id: $stripe_token_id) {
      business {
        company
        email
      }
      magic_code
      invoice_url
    }
  }
`;

const generateVariables = (input: any) => ({
  business: {
    company: input.business?.company,
    name: {
      first: input.business?.['name.first'],
      last: input.business?.['name.last']
    },
    email: input.business?.email,
    phone: input.business?.phone,
    website: input.business?.website,
    address: {
      street: input.business?.['address.street'],
      city: input.business?.['address.city'],
      state: input.business?.['address.state'],
      zip: input.business?.['address.zip'],
    },
    ...(input.referrer_uuid ? {
      ref_uuid: input.referrer_uuid,
      ref_type: 'coop-member'
    } : null)
    
  },
  type: input.payment?.type,
  category_uuid: input.business?.category_uuid,
  frequency: input.payment?.frequency,
  stripe_token_id: input.payment?.stripe?.id
});

const JoinRemote = () => {
  const [step, setStep] = useState(0);
  const [referrer_uuid] = useCoopReferrer();
  const [redirectUrl] = useState<string>();
  const [business, setBusiness] = useState<any>();
  const [payment, setPayment] = useState<any>();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string>();

  const [submit, { data, error: mutationError }] = useMutation(mutation, {
    errorPolicy: 'all',
    variables: generateVariables({ business, payment, referrer_uuid })
  });

  const onNext = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  const onPrev = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  const register = useCallback(async () => {
    setProcessing(true);
    setError(undefined);

    const { errors } = await submit();
    if (errors) {
      errors.forEach(error => setError(error.message));
    }
    console.log({ errors });
    setProcessing(false);
  }, [submit]);

  if (redirectUrl) {
    return (
      <Redirect to={redirectUrl} />
    );
  }

  if (data?.join_happy_business?.invoice_url) {
    return (
      <Page title='Become a Remote Happy Business'>
        <Container>
            <Steps 
              current={3} 
              steps={['Company Information', 'Payment', 'Success!']}
              />
            <Divider />
            <div className='empty'>
              <div className='empty-icon'>
                <i className='icon icon-3x icon-mail'></i>
              </div>
              <p className='empty-title h5'>Welcome to the Neighborhood!</p>
              <p className='empty-title h6'>Your registration is almost complete.</p>
              <p className='empty-subtitle'>Welcome <em>{data.join_happy_business?.business?.company}</em> you should receive your confirmation email at '{data.join_happy_business?.business?.email}' shortly.</p>
              <p>
                Due to issues with your card, you will need to pay by invoices. Use the following link:{' '}
                <a href={data?.join_happy_business?.invoice_url}>Pay Your Invoice</a>
              </p>
            </div> 
        </Container>
      </Page>
    );
  }

  if (data?.join_happy_business) {
    return (
      <Page title='Become a Remote Happy Business'>
        <Container>
            <Steps 
              current={3} 
              steps={['Company Information', 'Payment', 'Success!']}
              />
            <Divider />
            <div className='empty'>
              <div className='empty-icon'>
                <i className='icon icon-3x icon-mail'></i>
              </div>
              <p className='empty-title h5'>Welcome to the Neighborhood!</p>
              <p className='empty-subtitle'>Welcome <em>{data.join_happy_business?.business?.company}</em> you should receive your confirmation email at '{data.join_happy_business?.business?.email}' shortly.</p>
              { Boolean(data?.join_happy_business?.magic_code) &&
                <p>You will now be redirected to create your password for logging in.</p>
              }
            </div> 
        </Container>
      </Page>
    );
  }

  return (
    <Page title='Become a Happy Business'>
      <HeaderBar title={ referrer_uuid ? 'Become a Happy Business!' : 'Become a Happy Business'} />
      <Container>
        <Steps 
          current={step} 
          steps={['Company Information', 'Payment', 'Success!']}
          />
        <Divider />
        <Hider hidden={step !== 0} className='col-8'>
          <CompanyInfoForm onChange={setBusiness} onNext={onNext} />
        </Hider>
        <Hider hidden={step !== 1} className='col-8'>
          <PaymentInformationForm
             remote
             onChange={pmnt => Boolean(setError('')) || setPayment(pmnt)} 
             onNext={register} 
             onPrev={onPrev} 
             processing={processing}
             error={error}
             />
          { Boolean(!error && mutationError) && (
            <div className='toast toast-error mt-2'>
              { mutationError?.message }
            </div>
          )}
        </Hider>
      </Container>
    </Page>
  );
}

export default JoinRemote;

// function ReferrerAlert({ referrer }: { referrer: any }) {
//   if (referrer) {
//     return (
//       <div className='mt-2 toast toast-success'>
//         Referred by <strong>{referrer.name.first} {referrer.name.last}</strong>!
//       </div>
//     );
//   }

//   return null;
// }