
import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import latLon2Hex from '../tools/latlon2hex';
import CategorySelect, { Category } from './CategorySelect';
import LocationSearch, { Place } from './LocationSearch';

const VariableContainer = styled.div<{flex: number, right?: boolean}>`
  flex: ${({flex = 1}) => flex};
  margin: 0;
  margin-left: ${({right}) => right ? '.5em' : 0};

  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
    margin-top: ${({right}) => right ? '.5em' : 0};
  }
`;

const CategoryLocationSearch = () => {
  const [category, setCategory] = useState<Category>();
  const [place, setPlace] = useState<Place>();
  const history = useHistory();

  const search = useCallback(() => {
    console.log({ category, place });

    if (category?.slug) {
      if (place?.location) {
        const hex = latLon2Hex(place.location.lat, place.location.lon);
  
        console.log('latlon hex', { hex });
  
        ReactGA.event({
          category: 'Search',
          action: `${hex} | ${category.slug}`
        });
  
        history.push(`/q/${category.slug}/${hex}`, {
          place,
          category
        });
      } else if(place?.zip) {
        ReactGA.event({
          category: 'Search',
          action: `${place.zip} | ${category.slug}`
        });
  
        history.push(`/search/${place.zip}/${category.slug}`, {
          place,
          category
        });
      }
    }
  }, [category, place, history]);
  
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

      <VariableContainer flex={4}>
        <CategorySelect onSelect={setCategory} />
      </VariableContainer>

      <VariableContainer flex={2}>
        <LocationSearch onSelect={setPlace} place={place} />
      </VariableContainer>

      <VariableContainer style={{ padding: '.1em' }} flex={1} right>
        <button 
          className='btn btn-primary btn-block'
          onClick={search} 
          disabled={!category || !place}>
            Search
        </button>
      </VariableContainer>
    </div>
  );
}

export default CategoryLocationSearch;