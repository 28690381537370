
import React, { useMemo } from 'react';
import classnames from 'classnames';

const defaultStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    width: '100%',
    maxWidth: 1180,
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const Container = ({ children, className = null, style = {}, ...props }) => {
  const classes = useMemo(() => classnames('container', className), [className]);
  
  const styles = {
    ...defaultStyles.container, 
    ...style
  };

  return (
    <div className={classes} style={styles} {...props} >
     { children }
    </div>
  );
}

export default Container;