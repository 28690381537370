
import { ServerError, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import ExternalLink from '../../../components/ExternalLink';
import HookFormInput from '../../../components/form/HookFormInput';
import StripeCreditCardInput from '../../../components/form/StripeCreditCardInput';
import useCoopReferrer from '../../../data/useCoopReferrer';

const mutation = gql`
  mutation GHSVendorRegistration($ticket_info: TicketInfo!) {
    pay_for_ghs2022_vendor(ticket_info: $ticket_info)
  }
`;

function GHSVendorSignup() {
  const [referrer_uuid] = useCoopReferrer();
  const [token, setToken] = useState<any>();
  const [error, setError] = useState<string>();
  const [mutate, { data, loading }] = useMutation(mutation, {
    onError: error => {
      console.log(error);
      if (error.networkError?.name === 'ServerError') {
        try {
          const [{ message }] = (error.networkError as ServerError).result.errors;
          console.error(message);
          setError(message);
        } catch(e) {}
      }
    }
  });
  const _form = useForm({
    mode: 'onBlur'
  });

  const onSubmit = useCallback(async (data: any) => {
    if (token) {
      const ticket_info = {
        ...data,
        referrer_uuid,
        token_id: token.id
      };

      await mutate({ variables: { ticket_info } });
    }
    
  }, [token, referrer_uuid, mutate]);

  if (data?.pay_for_ghs2022_vendor) {
    return(
      <div style={{ marginTop: '1em', textAlign: 'center' }}>
        <h2>You've been registered succesfully!</h2>
        <ExternalLink href={data.pay_for_ghs2022_vendor}>Your Receipt</ExternalLink>
      </div>
    );
  }

  return (
    <div className='p-2' style={{ marginTop: '1em', textAlign: 'left' }}>
      <h2>Purchase your GHS 2022 Vendor Booth!<br /><small>Vendor Booth comes with two general admission tickets ($70 value)</small></h2>
      <form onSubmit={_form.handleSubmit(onSubmit)}>
        <HookFormInput 
          form={_form} 
          name='name'
          label='Name'
          placeholder='Name'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='phone'
          label='Phone'
          placeholder='Your Phone Number'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <HookFormInput 
          form={_form} 
          name='email'
          label='Email'
          placeholder='Your Email Address'
          errorMessage='Required'
          formProps={{
            required: true
          }}
          />
        <div className='divider' />
        <StripeCreditCardInput 
          token={token} 
          onToken={setToken} 
          onError={() => {}}
          />
        <div className='divider' />
        <button 
          type='submit' 
          disabled={!token || loading}
          className='btn btn-lg float-right my-2'>
            Purchase ($250)
        </button>
        { Boolean(error) && (
          <p className='text-error'>{ error }</p>
        )}
      </form>
    </div>
  );
}

export default GHSVendorSignup;