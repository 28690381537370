
import { gql, useQuery } from '@apollo/client';
import { Pricing } from './useLocalPricing';

const RemotePricingQuery = gql`
  query Pricing($coupon: String) {
    remote_pricing(coupon: $coupon) {
      total
      regular
      discount
      coupon
    }
  }
`;

const defaultValue = {
  total: 0,
  regular: 0,
  discount: 0,
  coupon: ''
}

export interface RemotePricingResponse {
  remote_pricing: Pricing
}

export const useRemotePricing = (coupon?: string) => {
  const { data } = useQuery<RemotePricingResponse>(RemotePricingQuery, {
    variables: {
      coupon
    }
  });

  if (data) {
    return data.remote_pricing;
  }

  return defaultValue;
}

export default useRemotePricing;