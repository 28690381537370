
import React, { useCallback } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import ZipInput from './ZipInput.js';
import CategorySelect from './CategorySelect.js';
import { useCategory, useZip } from '../../HnpContext.js';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 720px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const VariableContainer = styled.div`
  flex: ${({flex = 1}) => flex};
  margin: 0;
  margin-left: ${({right}) => right ? '.5em' : 0};

  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
    margin-top: ${({right}) => right ? '.5em' : 0};
  }
`;


const ListingSearchInput = ({ loading, onSearch, onChange }) => {
  const [zip] = useZip();
  const [category] = useCategory();
  const valid = Boolean(zip && category && category.slug);

  const _onSearch = useCallback(() => {
    if (onSearch && valid) {
      onSearch(zip, category);
    }
  }, [zip, category, valid, onSearch]);

  const _onChange = useCallback(() => {
    if (onChange && valid) {
      onChange(zip, category);
    }
  }, [zip, category, valid, onChange]);

  return (
    <SearchContainer>
      <VariableContainer flex={4}>
        <CategorySelect onChange={_onChange} />
      </VariableContainer>
      <VariableContainer flex={2} right>
        <ZipInput onChange={_onChange} />
      </VariableContainer>
      { onSearch &&
        <VariableContainer flex={1} right>
          <button 
            className={classNames('btn', 'btn-primary', 'btn-block', { loading })} 
            onClick={_onSearch} 
            disabled={!valid || loading}>
              Search
          </button>
        </VariableContainer>
      }
    </SearchContainer>
  )
}

export default ListingSearchInput;