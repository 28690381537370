
import React from 'react';
import dateFormat from 'dateformat';
import ExternalLink from './ExternalLink';
import nl2br from '../tools/nl2br';
import ExternalLinkify from './ExternalLinkify';

const DATE_FORMAT = 'dddd, mmmm dS, yyyy @ h:MMTT';

const EventItem = ({ event = {} }) => {
  const { title, location, starts_at, ends_at, description, url } = event;
  const start = dateFormat(starts_at, DATE_FORMAT);
  const end = dateFormat(ends_at, DATE_FORMAT);

  return (
    <div className='panel'>
      <div className='panel-header'>
        <div className='panel-title h4'>
          { title }
        </div>
      </div>
      <div className='panel-body'>
        <h5>{location}</h5>
        <h6>Start: {start}</h6>
        <h6>End: {end}</h6>
      </div>
      <div className='panel-body'>
        <p>
          <ExternalLinkify>
            { nl2br(description) }
          </ExternalLinkify>
        </p>
      </div>
      { url &&
        <div className='panel-footer'>
          <ExternalLink className='btn btn-primary' href={url}>
            More Info / Registration
          </ExternalLink>
        </div>
      }
    </div>
  );
}

export default EventItem;
