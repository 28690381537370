
import React, { useEffect, useState } from 'react';
import Page from '../../components/layout/Page';
import './index.css';
import Modal from './components/Modal';
import WobblyButton from '../../components/WobblyButton';
import Container from '../../components/layout/Container';

type Chamber = {
  name: string;
  logo_url: string;
  president_name: string;
  president_image_url: string;
  orientations: Array<{ name: string, url: string }>;
}

interface ChamberLandingProps {
  chamber: Chamber;
}

interface SectionProps {
  chamber: Chamber;
  onShowOrientations?: () => void;
}

const ChamberLandingPage = () => {
  // const { slug } = useParams<{ slug: string }>();
  const chamber: Chamber = {
    name: 'New Kent Chamber',
    logo_url: 'https://cdn.msgsndr.com/location%2FnGMlGmCPOmp0PUEhX6bl%2Fimages%2Ff5e45bad-02de-49f3-8402-a6392d1184cc.png?alt=media',
    president_name: 'Some Person',
    president_image_url: 'https://cdn.msgsndr.com/location%2FnGMlGmCPOmp0PUEhX6bl%2Fimages%2F444facb0-63ea-4e4a-8646-fbe4cf1c50af.jpeg?alt=media',
    orientations: [
      { name: 'Orientation Registration', url: 'https://us02web.zoom.us/meeting/register/tZYucOChrzkuH9dlym7uojauNVoB3K8zW6um' }
    ]
  };

  return (
    <ChamberLanding chamber={chamber} />
  )
}

const RegisterButton = ({ chamber, onShowOrientations}: SectionProps) => {
  return chamber.orientations.length === 1 ? (
    <WobblyButton as='a' href={chamber.orientations[0].url} target='_blank' rel='noopener noreferrer' style={{ textTransform: 'uppercase' }}>
      <div className='title'>
        Attend the Next Orientation
      </div>
      <div className='subtitle'>
        Learn How to Best Use Your New HNP Membership
      </div>
    </WobblyButton>
  ) : (
    <WobblyButton onClick={onShowOrientations} style={{ textTransform: 'uppercase' }}>
      <div className='title'>
        Attend the Next Orientation
      </div>
      <div className='subtitle'>
        Learn How to Best Use Your New HNP Membership
      </div>
    </WobblyButton>
  )
}

const ZoomBackground = () => (
  <div style={{ 
    backgroundImage: 'url("/images/zoom_bg_hnp.jpg")',
    backgroundRepeat: 'repeat repeat',
    backgroundPosition: 'center center',
    backgroundColor: 'black',
    opacity: .3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
    zIndex: -1
  }} />
);

const Hero = ({ chamber, onShowOrientations }: SectionProps) => (
  <Container style={{ position: 'relative', maxWidth: '100%' }}>
    <ZoomBackground />
    <div className='text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5em' }}>
      <img alt='' src='/images/hnp-decal.png' style={{ maxWidth: '50vw', marginBottom: '1.5em' }} />
      <img alt='' src={chamber.logo_url} style={{ maxWidth: '50vw', marginBottom: '1.5em' }} />
      <h1><strong>The Happiest</strong> Place In The World<br />For <strong>Business People</strong></h1>
      <RegisterButton chamber={chamber} onShowOrientations={onShowOrientations} />
    </div>
  </Container>
);

const Benefits = ({ chamber }: SectionProps) => (
  <Container className='text-center'>
    <h1 style={{ color: '#5755d9', fontSize: '300%', fontWeight: 'bold' }}>Because Your Chamber Partnered With HNP</h1>
    <img alt='' src={chamber.president_image_url} width={256} style={{ marginBottom: '2em'}} />
    <h2 style={{ color: 'black' }}>You Get Access To 65+<br />Weekly Virtual Networking Events</h2>
    <div style={{ color: 'black' }}>
      <div className='columns mt-2'>
        <div className='column d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
          <h2>You <span style={{ textDecoration: 'underline' }}>Grow</span> By Being A Part of A Supportive Community</h2>
        </div>
        <div className='column d-flex col-sm-12' style={{ flexDirection: 'column' }}>
          <p style={{ flex: 1 }}>"Since joining HNP, I have gotten a 4,910%+ ROI. Of course, you get out what you put in, but I am a serial networker and I have NEVER gotten more clients from a group than Happy Neighborhood Project."</p>
          <h5>Marc Hamm, Owner at Pro Web Firm</h5>
          <h5>Director of the Greater East Side Chamber of Commerce</h5>
        </div>
      </div>
      <div className='divider' />
      <div className='columns'>
        <div className='column d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
          <h2>You <span style={{ textDecoration: 'underline' }}>Cultivate</span> Collaboration, Opportunities &amp; Ideas</h2>
        </div>
        <div className='column d-flex col-sm-12' style={{ flexDirection: 'column' }}>
          <p style={{ flex: 1 }}>"My favorite thing about this community is that they all just have genuine hearts and they passionately care about people."</p>
          <h5>Stefanie Thayer, Owner at Wellness With Stef</h5>
        </div>
      </div>
      <div className='divider' />
      <div className='columns'>
        <div className='column d-flex col-sm-12' style={{ justifyContent: 'center', alignItems: 'center' }}>
          <h2>You <span style={{ textDecoration: 'underline' }}>Expand</span> Your Skills</h2>
        </div>
        <div className='column d-flex' style={{ flexDirection: 'column' }}>
          <p style={{ flex: 1 }}>"We dug into content, content, posts, engagement, interactions, design. He showed me some real NUGGETS of information. I loved how much I was able to take away from our social media session."</p>
          <h5>Melissa Bowers, Owner at Melissa Bowers At Your Service</h5>
        </div>
      </div>
    </div>
  </Container>
);

const RecognizedBy = () => (
  <Container>
    <h4 style={{ color: 'black' }}>Our Work Has Been Recognized By</h4>
    <div>
      <img alt='' src='/images/logos/pbs-64.png' height={64} className='px-2' />
      <img alt='' src='/images/logos/tedx-unlv-64.png' height={64} className='px-2' />
      <img alt='' src='/images/logos/united-nations-64.jpg' height={64} className='px-2' />
    </div>
  </Container>
)

const LastCall = ({ chamber, onShowOrientations }: SectionProps) => (
  <Container style={{ position: 'relative', maxWidth: '100%' }}>
    <ZoomBackground />
    <div className='text-center' style={{ padding: '5em' }}>
      <RegisterButton chamber={chamber} onShowOrientations={onShowOrientations} />
    </div>
  </Container>
);

const ChamberLanding = ({ chamber }: ChamberLandingProps) => {
  const [showOrientations, setShowOrientations] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [chamber]);

  return (
    <Page title='Happy Neighborhood' className='landing-page text-white'>
      <Hero chamber={chamber} onShowOrientations={() => setShowOrientations(true)} />
      <Benefits chamber={chamber} onShowOrientations={() => setShowOrientations(true)} />
      <RecognizedBy />
      <LastCall chamber={chamber} onShowOrientations={() => setShowOrientations(true)} />
      <Modal visible={showOrientations} onClose={() => setShowOrientations(false)} title='Register for Orientation'>
        { chamber.orientations.map((orientation, i) => (
          <div key={i}>
            <a href={orientation.url} target='_blank' rel='noopener noreferrer'>
              <h2>{ orientation.name }</h2>
            </a>
          </div>
        ))}
      </Modal>
    </Page>
  );
}

export default ChamberLandingPage;