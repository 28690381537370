
import React from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import StripeCreditCardInput from './StripeCreditCardInput';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);

const StripeInput = (props) => {
  const { horizontal, getValue, setValue, label = 'Credit Card', showRequired, isPristine, isValid, showError, 
    getErrorMessage } = props;
  const error = getErrorMessage();

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <div className={classNames('col-sm-12', { 'col-9': horizontal })}>
        <StripeCreditCardInput token={getValue()} onToken={token => setValue(token)} />
          { Boolean(error) && (
            <p className="form-input-hint">{ error }</p>
          ) }
      </div>
    </div>
  );
}

export default withFormsy(StripeInput);