
import React, { ReactNode } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import { Link } from 'react-router-dom';
import Divider from '../components/Divider';

interface FourthPanelProps {
  children: ReactNode;
  title?: string;
  subtitle?: string; 
  footText?: string;
  to?: string;
  link?: string;
  img?: string;
}

function FourthPanel({ children, title, subtitle, footText, to, link, img }: FourthPanelProps) {
  return (
    <div className='column col-12' style={{ marginBottom: '.8rem' }}>
      <div className='panel' style={{ minHeight: '100%' }}>
        { Boolean(title || subtitle) && (
          <div className='panel-header'>
            { Boolean(title) && <h2 className='text-center'>{ title }</h2> }
            { Boolean(subtitle) && <h6 className='text-center'>{ subtitle }</h6> }
          </div>
        )}
        {/* { Boolean(img) ? (
          <div className='text-center'>
            <img alt='' style={{ width: '100%' }} src={img} />
          </div>
        ) : (
          <div className='text-center hide-sm hide-xs'>
            <img alt='' style={{ width: '100%' }} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' />
          </div>
        )} */}
        <div className='panel-body checklist'>
          { children }
        </div>
        { Boolean(footText) && <h6 className='text-center'>{ footText }</h6> }
        { Boolean(to && link) && (
          <div className='panel-footer'>
            <Link to={to || ''} className='btn btn-primary btn-block'>
              { link }
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

function Plans() {

  return (
    <Page title='HNP - Join'>
      <Container>
        <div className='text-center'>
          <h3>We Invite You to Be a Part of Something Greater Than Yourself!</h3>
          <h4>
            <span className='text-primary'>Our commitment is to grow and nourish our community so that everyone will thrive.</span><br />
            With the support of our members, we are growing the happiest place in the world for businesspeople!
          </h4>
          <Divider />
          <h4>All Paid Memberships Come With:</h4>
          <h6>Access to the HNP decal sticker to use on Zoom background, social media, website, marketing promos, etc.</h6>
          <div className='columns p-2 m-2'>
            <div className='column'>
              <img 
                alt='HNP Decal' 
                style={{ height: 300 }}
                src='/images/hnp-decal.png' 
                />
            </div>
            <div className='column'>
              <img
                src='/images/hnp-tribe.png' 
                alt='Happy businesses showing off their decals!' 
                title='Happy businesses showing off their decals!' 
                style={{ height: 300 }}
                />
            </div>
          </div>
          <Divider />
        </div>
        <div className='columns' style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
          <FourthPanel title='Verified Happy Business' footText='All these Happy Perks for $79/month or $799/year (plus $100 non-refundable setup fees). Requires 30 days advance notice for cancellation. Payments automatically renew monthly or annually.' to='/remote/register' link='Get Started' img='/images/eagle.jpg'>
            <p>Are you looking to take your business to the next level? Do you want to stand out in your community and attract a loyal, engaged clientele?</p>
            <p>Look no further than becoming a Verified Happy Business (VHB) in the Happy Neighborhood Project. By joining this prestigious program, you will reap incredible rewards and experience tremendous growth. Here are ten irresistible reasons why becoming a VHB is the best choice for your business:</p>
            <ul className='checkmark'>
              <li>Every VHB has the opportunity to profit from revenue-sharing as a valued share member in the Coop. With this unique model, you can earn more while working with businesses that uplift your community. <em>(You must be a share member in the Coop to participate, which is $197 one-time fee)</em></li>
              <li>Get featured in our prestigious Coop directory, appearing on both our back office and main website, and showcase your business with a comprehensive profile. This profile allows you to flaunt your logo, LinkedIn, videos, and more! This added exposure will enhance your brand visibility while giving potential customers a sense of what sets you apart.</li>
              <li>Improve your social media presence by participating in our Posting Parties! Network with fellow members and learn valuable techniques to engage your audience and expand your reach.</li>
              <li>Proudly display the HNP decal on all your marketing promotions, creating an instant connection with consumers who value happiness, community, and collaboration.</li>
              <li>Gain access to exclusive Monthly Speed Networking events reserved only for Verified Happy Businesses. These dynamic events provide an opportunity to share experiences, collaborate on innovative ideas, and establish fruitful partnerships.</li>
              <li>Celebrate International Day of Happiness and attend the Global Happiness Summit without any additional cost! Join like-minded individuals from around the world as we work together to create a happier, more prosperous society.</li>
              {/* <li>Enjoy the perks of Zoom Enterprise, including the ability to accommodate 500 participants, no time limit, and unlimited storage. Elevate your virtual meetings, presentations, and workshops like never before!</li> */}
              <li>Earn 5 points per dollar (1,000 max per transaction) from any Coop member you do business with. With this generous reward system, your hard work will lead to even greater opportunities and prosperity.</li>
              <li>Earn 250 points as a VHB (monthly) or 2,200 points (yearly)!</li>
              <li>Experience VIP access at Summits and expand your network by mingling with influential speakers and our very own Chief Happiness Officer, Edwin Edebiri.</li>
              <li>Share your expertise and experience by teaching classes to our thriving community! As a VHB, you'll have the unique opportunity to inspire and educate your peers through interactive workshops and courses. <em>(Based on eligibly and availability)</em></li>
            </ul>
            <p>Becoming a Verified Happy Business in the Happy Neighborhood Project is an investment in not just your own success but the success of your entire community. Your dedication to fostering happiness, collaboration, and growth will create a positive ripple effect that benefits everyone involved.</p>
            <p>So, don't miss out on this fantastic opportunity to elevate your business and bring happiness to those around you. The benefits are real, persuasive, and exclusive to members of this esteemed program. Embrace the chance to be part of a thriving, energetic community that seeks mutual success. Join the Happy Neighborhood Project as a Verified Happy Business today and start reaping the rewards of a happier, more prosperous future!</p>
          </FourthPanel>
        </div>
        <p>If you are unsure about our plans, you can <Link to='/business/request-info'>request more info</Link>!</p>
      </Container>
    </Page>
  );
}

export default Plans;