
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ExternalLink from '../components/ExternalLink.js';

const Classes = () => {
  return (
    <Page title='HNP - Classes'>
      <HeaderBar title='Classes' />
      <Container style={{textAlign: 'center'}}>
        <ul>
          <li>
            <ExternalLink href='https://us02web.zoom.us/meeting/register/tZ0oduuvqj0tH9OGTt1MiwkBcKMlCxUhkxuM'>
              Zoom 101: Using Zoom to service your existing customers and grow your business
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href='https://us02web.zoom.us/meeting/register/tZwrcuiuqTMiH9WSGxFweU9LoITQSRltW4TM'>
              Networking 101: Getting the most out of your online networking 
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href='https://us02web.zoom.us/meeting/register/tZUodu-vrz4qHdf4PWCosiF4D0r30WbkbUNp'>
              Happiness As A Skill: Building your business and life on a foundation of happiness 
            </ExternalLink>
          </li>
        </ul>
      </Container>
    </Page>
  )
}

export default Classes;