
import React, { useState, useCallback, useMemo } from 'react';
import Formsy from 'formsy-react';
import Input from '../components/form/Input';
import Checkboxes from '../components/form/Checkboxes';
import RequiredCheckbox from '../components/form/RequiredCheckbox';
import Select from '../components/form/Select';
import Divider from '../components/Divider';
import CopiableLink from '../components/CopiableLink';
import classNames from 'classnames';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Call } from '../data/useCalls';
import dateFormat from 'dateformat';
import useCoopReferrer from '../data/useCoopReferrer';

const query = gql`
  query Meeting ($meeting_id: Float!) {
    meeting(meeting_id: $meeting_id) {
      message
      questions {
        question
        placeholder
        type
        answers
        required
      }
      occurrences {
        occurrence_id
        start
        start_time
        duration
        status
      }
    }
  }
`;

const mutation = gql`
  mutation RegisterNetworking($meeting_id: Float! $occurrences: [String]! $data: NetworkingRegistrationInput!) {
    register_networking(meeting_id: $meeting_id occurrences: $occurrences data: $data) {
      join_url
      ical_url
      manual_approval
    }
  }
`;

const NetworkingRegistrationForm = ({ call }: { call: Call}) => {
  const [, setValid] = useState<boolean>(false);
  const [banned, setBanned] = useState<boolean>(false);
  const [referrer_uuid] = useCoopReferrer();
  const { data: query_data } = useQuery(query, {
    variables: { meeting_id: call.meeting_id }
  });
  const [register, { data, loading }] = useMutation(mutation, {
    onError: error => {
      if (error.message.indexOf('banned') > -1) {
        setBanned(true);
      }
    }
  });

  const occurrences = useMemo(() => {
    const count = 4;

    const _occurrences = (query_data?.meeting?.occurrences || [])
      .slice()
      .sort((a: any, b: any) => a.start - b.start);
  
    return _occurrences
      .slice(0, count > 0 ? count : _occurrences.length) as Array<any>;
  }, [query_data]);

  const submit = useCallback(async ({ occurrences, ...data }) => {
    delete data.agreement;
    try {
      await register({ variables: { 
        meeting_id: call.meeting_id, 
        occurrences,
        data 
      }});
    } catch(error) {
      console.error('Networking Registration', error);
    }
  }, [call, register]);

  if (banned) {
    return (<></>);
  }

  if (data) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've successfully registered. Details should arrive in your email very soon!</h2>
        { data?.register_networking?.ical_url && (
          <a href={data?.register_networking.ical_url} target='_blank' rel='noreferrer noopener'>
            <h3>Add to Calendar</h3>
          </a>
        )}
        {
          data?.register_networking.manual_approval ? 
          <div className='toast toast-warning'>
            This meeting requires manual approval for registration. You should be approved shortly at which point your join link will be emailed to you.
          </div> :
          <CopiableLink link={{
            url: data?.register_networking?.join_url,
            info: 'Your personal meeting join URL! Please do not share it.'
          }} />
        }
      </div>
    );
  }

  const start = occurrences[0]?.start_time;
  const hasMinutes = Boolean(parseInt(dateFormat(start, 'M'), 10));
  const timeAndDay = hasMinutes ? 
    dateFormat(start, 'dddd"s" @ h:MMtt (Z)') : 
    dateFormat(start, 'dddd"s" @ htt (Z)');

  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onValidSubmit={submit}
      >
      <h3 className='text-center'>
        { Boolean(referrer_uuid) ? (
          <>
          <small>Free Happy Business Networking!</small>
            <Input type='hidden' name='ref_uuid' value={referrer_uuid} />
            <Input type='hidden' name='ref_type' value='coop-member' />
          </>
        ) : (
          <small>Free Happy Business Networking</small>
        )}
        <br />
        { call?.location }
      </h3>
      <h4 className='text-center'>{ start ? timeAndDay : '' }</h4>
      <Divider />
      <p>You can choose to attend one or more of the following sessions on { start ? timeAndDay : '' }:</p>
      <Checkboxes 
        name='occurrences' 
        options={occurrences}
        defaultValue={occurrences[0]?.occurrence_id}
        validations={{
          atLeastOne: (_values: any, value: any) => (Array.isArray(value) && value.length > 0) || 'You must select at least one meeting time.'
        }}
        extractKey={(occ: any) => occ.occurrence_id} 
        extractValue={(occ: any) => occ.occurrence_id} 
        extractLabel={(occ: any) => dateFormat(occ.start_time, 'mmmm dS, yyyy')} 
        showSelectAll
        columns
        required
        />
      <Divider />
      <Input name='first_name' label='First Name' placeholder='Your First Name' required />
      <Input name='last_name' label='Last Name' placeholder='Your Last Name' required />
      {/* <Input name='company' label='Business Name' placeholder="Your Business' Name" required /> */}
      <Input name='email' label='Email' placeholder='name@example.com' validations='isEmail' validationError='Valid email required.' required />
      <Input name='email_confirm' label='Confirm Email' placeholder='name@example.com' validations='equalsField:email' validationError='Emails must match.' required />
      <Input name='phone' label='Cell Phone Number (for reminders &amp; networking)' placeholder='(555) 555-5555' required />
      {/* <Input name='website' label='Website' placeholder='https://yourwebsite.com' /> */}
      {/* <Input name='linkedin' label='LinkedIn Profile' placeholder='Put your LinkedIn here!' /> */}
      <Divider />
      <Select
        name='cohost'
        label='Invited by a Co-Host?'
        placeholder='Select the Co-Host that invited you...'
        nullValue="I wasn't invited by a co-host."
        items={call.cohosts} 
        randomize
        />
      <Select
        name='how_did_you_hear'
        label='How did you hear about these events?'
        placeholder='Choose One...'
        items={[
          'Direct Message', 'Eventbrite', 'Friend', 'Facebook Post', 'Instagram Post', 'LinkedIn Post',
          'Meetup', 'Newsletter'
        ]} 
        />
      <Input 
        name='referrer' 
        label='If you were referred to this event by someone, who was it? (We will give them some love!)' 
        />
      <Divider />
      { Boolean(query_data?.meeting?.questions) && (
        <>
          { query_data.meeting.questions.map((item: any, index: number) => {
            if (item.type === 'options') {
              return (<div key={item.question}>
                <Input type='hidden' value={item.question} name={`questions[${index}].question`} />
                <Select
                  name={`questions[${index}].answer`}
                  label={item.question}
                  placeholder={item.placeholder || item.question}
                  items={item.answers} 
                  required={item.required}
                  />
              </div>);
            }

            return (<div key={item.question}>
              <Input type='hidden' value={item.question} name={`questions[${index}].question`} />
              <Input 
                name={`questions[${index}].answer`}
                label={item.question}
                placeholder={item.placeholder || item.question}
                required={item.required}
                />
            </div>);
          })}
          <Divider />
        </>
      )}
      { Boolean(query_data?.meeting?.message) && (
        <p>{ query_data.meeting.message }</p>
      )}
      <Divider />
      <RequiredCheckbox 
        name='agreement' 
        label='By registering, I agree to be fair with my time and split it evenly between listening and presenting during any 1 on 1 meetings with anyone I meet from Happy Business Networking.'
        />
      <div className='col-12 clearfix my-2'>
        <button type='submit' className={classNames('btn btn-primary btn-block', { loading })} disabled={loading}>Submit</button>
      </div>
    </Formsy>
  );
}

export default NetworkingRegistrationForm;