
import React, { useMemo } from 'react';
import DataTable from '../components/Table/DataTable';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const query = gql`
  query GetEvents {
    events {
      uuid
      title
      description
      type
      start
    }
  }
`;

export function EventsTableCard() {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });

  const columns = useMemo(() => [{
    Header: 'Title',
    accessor: 'title',
    Cell: ({ value, row }: { value: string, row: any }) => 
      <Link to={`/class/${row.original.uuid}`}>{ value }</Link>
  }, {
    Header: 'Start',
    accessor: 'start',
    Cell: ({ value }: { value: string, row: any }) => value ? 
      format(new Date(value), 'MMM do, yyyy h:mma') : 
      ''
  }], []);

  const events = useMemo(() => data?.events || [], [data]);

  return (
    <DataTable
      title='Events'
      data={events} 
      columns={columns} 
      initialSort='start'
      ascending
      />
  );
}

const ClassesEvents = () => {
  
  return (
    <Page title='HNP - Free Classes'>
      <HeaderBar title='Free Classes' />
      <Container>
          <EventsTableCard />
      </Container>
    </Page>
  );
}

export default ClassesEvents;