
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { getListingsBySlug } from '../API.js';
import ListingSearchInput from '../components/form/ListingSearchInput.js';
import { useZip, useCategory } from '../HnpContext.js';

const Listings = () => {
  const history = useHistory();
  const [zip, setZip] = useZip();
  const [category, setCategory] = useCategory();
  const { zip: _zip, slug } = useParams();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (zip && category && category.slug) {
      history.replace(`/search/${zip}/${category.slug}`);
    }
  }, [history, zip, category]);

  useEffect(() => {
    setLoading(true);

    getListingsBySlug(_zip, slug).then(result => {
      const { listings = [], category, leader } = result;
      setZip(_zip);

      if (leader) {
        listings.unshift(leader);
      }

      setListings(listings);
      setCategory(category);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });
  }, [_zip, slug, setZip, setCategory]);

  return (
    <Page title={`HNP - Listings for ${category ? category.name : slug} in ${zip}`}>
      <HeaderBar>
        <div style={{ margin: '0 auto', maxWidth: 960 }}>
          <ListingSearchInput />
        </div>
      </HeaderBar>
      <Container style={{textAlign: 'center'}}>
        { loading && 
          <p>{ loading ? 'Loading...' : '' }</p>
        }
        { listings.map((listing, i) => (
          <div className='panel' key={listing.uuid} style={{padding: '1em', marginBottom: '1em'}}>
            <Link to={{state: { listing }, pathname: `/listing/${listing.friendly_id}`}}>
              <h4>
                <strong>
                  {listing.name}
                </strong>
                <br />
                <small>
                  {listing.blurb}
                </small>
              </h4>
            </Link>
          </div>
        ))}
      </Container>
    </Page>
  )
}

export default Listings;