
import { useCallback, useState } from 'react';

const HNP_COOP_REFERRER_KEY = 'HNP_COOP_REFERRER';
const HNP_COOP_REFERRER_SLUG_KEY = 'HNP_COOP_REFERRER_SLUG';

type CoopReferrerResult = [string, (arg0: string) => void];

export default function useCoopReferrer() {
  const _referrer_uuid = localStorage.getItem(HNP_COOP_REFERRER_KEY) || '';
  const [referrer, setReferrer] = useState<string>(_referrer_uuid);

  const set = useCallback((uuid: string) => {
    localStorage.setItem(HNP_COOP_REFERRER_KEY, uuid);
    setReferrer(uuid);
  }, []);

  return [referrer, set] as CoopReferrerResult;
}

export function useCoopReferrerSlug() {
  const _referrer_slug = localStorage.getItem(HNP_COOP_REFERRER_SLUG_KEY) || '';
  const [slug, setSlug] = useState<string>(_referrer_slug);

  const set = useCallback((slug: string) => {
    localStorage.setItem(HNP_COOP_REFERRER_SLUG_KEY, slug);
    setSlug(slug);
  }, []);

  return [slug, set] as CoopReferrerResult;
}