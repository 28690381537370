
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ZipInput from '../components/form/ZipInput.js';
import CategorySelect from '../components/form/CategorySelect.js';
import { getZipAvailability_V2_DEPRECATED } from '../API.js';
import Divider from '../components/Divider.js';

const Availability = () => {
  const [availability, setAvailability] = useState();
  const [loading, setLoading] = useState();
  const [zip, setZip] = useState();
  const [category, setCategory] = useState();

  useEffect(() => {
    (async () => {
      if (zip && category) {
        setLoading(true);
        const availability =  await getZipAvailability_V2_DEPRECATED(zip, category.uuid);
        setAvailability(availability);
        setLoading(false);
      }
    })();
  }, [zip, category]);

  return (
    <Page title='Check Your Zip Code'>
      <HeaderBar title='Check Your Zip Code' />
      <Container style={{textAlign: 'center'}}>
        <div className='columns mb-2'>
          <div className='column col-md-6'>
            <ZipInput onChange={setZip} emitInitial />
          </div>
          <div className='column col-md-6'>
            <CategorySelect onChange={setCategory} />
          </div>
        </div>
        <Divider />
        <div className={classNames('mt-2 p2', { loading, 'loading-lg': loading })}>
          { availability && 
            <>
              <Divider />
              <h2>{availability.zip} is { availability.available ? <span className='text-success'>AVAILABLE</span> : <span className='text-error'>OCCUPIED</span>} for {category.name}</h2>
              <Divider />
              <span className='mb-2'>Other Zip Codes Nearby</span>
              <div className='columns'>
                { availability.nearby.map(z => (
                  <div className='column col-sm-12 col-md-6 col-3'>
                    <div key={z.zip} className='card mt-2 mb-2'>
                      <div class="card-header">
                        <div class="card-title h5">{z.zip}</div>
                        <div class="card-subtitle text-gray">{z.distance} miles away</div>
                      </div>
                      <div className='card-body'>
                      { z.available ?  
                        <span className='text-success'>AVAILABLE</span> : 
                        <span className='text-error'>OCCUPIED</span> 
                      }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </> 
          }
        </div>
      </Container>
    </Page>
  )
}

export default Availability;