
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const Business = () => {
  const history = useHistory();

  const goToRequestInfo = useCallback(() => {
    history.push('/business/request-info');
  }, [history]);

  const goToIneligble = useCallback(() => {
    history.push('/business/ineligible');
  }, [history]);

  return (
    <Page title='HNP - Business Pledges'>
      <HeaderBar>
        <h1>Business Pledges</h1>
        <h5>Every business member of the Happy Neighborhood Project must take two pledges:</h5>
        <ol>
          <li>To provide great customer service.</li>
          <li>To encourage a positive work environment.</li>
        </ol>
      </HeaderBar>
      <Container style={{textAlign: 'center', paddingTop: '1em'}}>
        <h4>Are you ready to take these pledges?</h4>
        <div style={{ display: 'inline-block' }}>
          <button className='btn btn-primary' onClick={goToRequestInfo}>Yes</button>
          <span style={{width: '1em', display: 'inline-block'}} />
          <button className='btn' onClick={goToIneligble}>No</button>
        </div>
      </Container>
    </Page>
  )
}

export default Business;