
import React, { useCallback, useEffect, useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { ApolloClient, InMemoryCache, useLazyQuery } from '@apollo/client';
import 'animate.css';

const query = gql`
  query Winner($meeting_id: Float!) {
    pick_winner(meeting_id: $meeting_id) {
      time
      total
      random {
        user_name
        email
        ip_address
      }
      present {
        user_name
        email
        ip_address
      }
    }
  }
`;

export const client = new ApolloClient({
  uri: 'https://api.hnpabc.com/graphql', // 'https://hnp-api-dev.herokuapp.com/graphql',
  cache: new InMemoryCache()
});

const RandomWebinarParticipant = () => {
  const [password, setPassword] = useState<string>();
  const [meetingId, setMeetingId] = useState<string>();
  const [ticker, setTicker] = useState<string>();
  const [winner, setWinner] = useState<string>();
  const [fetchWinner, { data, loading, called, refetch }] = useLazyQuery(query, {
    client,
    pollInterval: 55555,
    variables: {
      meeting_id: meetingId ? parseInt(meetingId, 10) : null
    }
  });

  useEffect(() => {
    let i = 0;
    if (data?.pick_winner) {
      const interval = setInterval(() => {
        const person = data.pick_winner.present[i++];
        
        if (person) {
          setTicker(person.user_name);
        } else {
          i = 0;
        }
      }, 70);
  
      return () => interval && clearInterval(interval);
    }
  }, [data]);

  const reset = useCallback(() => {
    setWinner(undefined);

    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const authorized = String(password).toLowerCase().indexOf('osagie') > -1 || 
    String(password).toLowerCase().indexOf('happy') > -1;

  return (
    <Page title='HNP Happy Wheel'>
      <HeaderBar title='HNP Happy Wheel' />
      <Container style={{textAlign: 'center'}}>
        <input className={classNames('form-input col-3', { 'is-success': authorized, 'is-error': !authorized })} type='password' onChange={e => setPassword(e.currentTarget.value)} placeholder='Password' />
        <div className='divider' />
        <input className='form-input col-3' disabled={!authorized} type='number' value={meetingId} onChange={e => setMeetingId(e.currentTarget.value)} placeholder='Zoom Meeting ID' />
        <button className='btn btn-sm mt-2' onClick={() => called ? (refetch && refetch()) : fetchWinner()} disabled={!authorized || !meetingId}>Set Meeting</button>
        { Boolean(loading) && <h3>Loading...</h3>}
        <div className='divider' />
        { Boolean(!winner) && (
          <>
            <h2 key={ticker}>{ ticker }</h2>
            <div className='divider' />
            <h1>Total Participants: { data?.pick_winner?.total }</h1>
            <button className='btn btn-sm' onClick={() => setWinner(String(data?.pick_winner?.random?.user_name))} disabled={!data?.pick_winner?.random}>Choose Random Winner</button>
          </>
        )}
        { Boolean(winner) && 
          <>
            <div className='divider' />
            <h1 className='animate__animated animate__pulse animate__infinite'>Winner: { winner }</h1>
            <button className='btn btn-sm mt-2' onClick={reset}>Choose Another</button>
          </>
        }
      </Container>
    </Page>
  );
}

export default RandomWebinarParticipant;