
import React from 'react';
import classNames from 'classnames';

const HookFormRadio = ({ name, label, errorMessage, items, form, formProps }) => {
  const { register, errors } = form;
  const error = errors[name];

  if (items && items.length) {
    return (
      <div className={classNames('form-group', { 'has-error': Boolean(error) })}>
        { Boolean(label) &&
          <label className='form-label' htmlFor={name}>
            { label }
            <span style={{ color: 'red' }}>{ Boolean(formProps && formProps.required) ? '*' : ''}</span>
          </label>
        }
        { items.map((item, i) => (
          <label key={i} class="form-radio">
            <input type="radio" name={name} value={item} ref={register(formProps)} />
            <i class="form-icon"></i> {item}
          </label>
        ))}
        { Boolean(error) && 
          <p class="form-input-hint">{ errorMessage || 'Required.' }</p> 
        }
      </div>
    );
  }

  return null;
}

export default HookFormRadio;