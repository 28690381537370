
import React from 'react';

export const Redirect = ({ href, replace }: { href: string, replace?: boolean }) => {
  if (href) {
    if (replace) {
      window.location.replace(href);
    } else {
      window.location.assign(href);
    }
  }

  return null;
}

export const redirectTo = (url: string, replace?: boolean) => {
  return () => (
    <Redirect href={url} replace={replace} />
  );
}

export default redirectTo;