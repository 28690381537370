
import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { FaCopy } from 'react-icons/fa';
import copyToClipboard from '../tools/copyToClipboard.js';

const ShareModal = ({ url, visible, onClose }) => {
  const [copied, setCopied] = useState();

  useEffect(() => {
    if (copied !== 0) {
      const timeout = setTimeout(() => {
        setCopied(0);
      }, 3000);
  
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const copy = useCallback(() => {
    copyToClipboard(url).then(success => {
      setCopied(success ? 1 : -1);
    }, () => {
      setCopied(-1);
    });
  }, [url]);

  const _onClose = useCallback(() => {
    setCopied(0);
    onClose && onClose();
  }, [onClose]);

  return (
    <div className={classNames('modal', { active: visible })} id='modal-id'>
      <div className='modal-overlay' aria-label='Close' onClick={_onClose} />
      <div className='modal-container'>
        <div className='modal-header'>
          <button className='btn btn-clear float-right' onClick={_onClose} aria-label='Close' />
          <div className='modal-title h2'>
            Share URL <small>(copy & paste)</small>
          </div>
        </div>
        <div className='modal-body'>
          <div className='content'>
            <div className='input-group'>
              <input type='text' className='form-input' value={url} readOnly />
              <button className='btn btn-primary input-group-btn' onClick={copy}>
                <FaCopy className='icon' /> Copy
              </button>
            </div>
          </div>
          { copied === 1 && 
            <div className='toast toast-success' style={{marginTop: '.5em'}}>
              <button className='btn btn-clear float-right' onClick={() => setCopied(0)} />
              Copied to clipboard!
            </div>
          }
          { copied === -1 && 
            <div className='toast toast-error' style={{marginTop: '.5em'}}>
              <button className='btn btn-clear float-right' onClick={() => setCopied(0)} />
              Unabled to copy to clipboard, please do it manually.
            </div>
          }
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={_onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;