
import React, { useState, useCallback } from 'react';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Input from '../components/form/Input.js';
import Divider from '../components/Divider.js';
import Hider from '../components/Hider';
import { sendNumberGuess } from '../API.js';
import { Link } from 'react-router-dom';

const GuessNumber = () => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const submit = useCallback((data) => {
    (async () => {
      setLoading(true);
      setError(undefined);

      try {
        const result = await sendNumberGuess(data);
        console.log('result', result);

        if (result.success) {
          setSuccess(result);
        }

        setError('An error occured. Please try again.');
      } catch (error) {
        console.warn('submit info', error);
        setError('An error occured. Please try again.');
      }

      setLoading(false);
    })();
  }, []);

  return (
    <Page title='HNP - The Number is Right!'>
      <HeaderBar>
        <h1>The Number is Right!</h1>
        <h4>
          Guess the number of registrants for the big <Link to='/reveal'>reveal</Link>!
        </h4>
      </HeaderBar>
      <Container className='p-2'>
        <Hider hidden={success} className='m-2'>
          <h5>Guess How Many!<br />Get as close as you can without going over!</h5>
          <Formsy style={{width: '100%' }} onValidSubmit={submit} onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
            <Input name='form_type' type='hidden' value='Guess Number Form' />
            <Input name='name' label='Your Name' placeholder='Your Name' required />
            <Input 
              name='email' 
              label='Email' 
              placeholder='email@company.com' 
              validations='isEmail' 
              validationError='Must be a valid email.' 
              required 
              />
            <Input 
              name='number' 
              type='number'
              label='Your Guess' 
              placeholder='How Many?'
              required 
              />
            <Divider />
            <Input name='referrer_name' label='Referrer' placeholder='Referrer' />
            <button className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>Submit</button>
            <Hider hidden={!error}  className='toast toast-error mt-2'>
              { error }
            </Hider>
          </Formsy>
        </Hider>
        <Hider className='empty' hidden={!success}>
          <div className='empty-icon'>
            <i className='icon icon-3x icon-mail'></i>
          </div>
          <p className='empty-title h5'>Thanks for your guess!</p>
          <p className='empty-subtitle'>
            We will announce the winner at the event!<br />
            Also, make sure you're registered for the big reveal! If you aren't already registered <Link to='/reveal'>go do that now</Link>!
          </p>
        </Hider>
      </Container>
    </Page>
  )
}

export default GuessNumber;