
import React, { useState } from 'react';
import { FaShareSquare, FaRegStickyNote } from 'react-icons/fa';
import ShareModal from './ShareModal.js';
import FeedbackDisplay from './FeedbackDisplay.js';

const LISTING_BASE_URL = 'https://happyneighborhoodproject.com/listing/';

const ShareAndFeedback = ({ listing = {}, ...props }) => {
  const [showShare, setShowShare] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <div {...props}>
      <div>
        <button title='Share' className='btn m-1' onClick={() => setShowShare(true)}>
          <FaShareSquare className='icon' /> Share
        </button>
        <button title='Send Feedback!' className='btn m-1' onClick={() => setShowFeedback(true)}>
          <FaRegStickyNote className='icon' /> Send Feedback
        </button>
      </div>
      <div style={{ textAlign: 'initial' }}>
        <ShareModal 
          url={LISTING_BASE_URL + listing.friendly_id}
          visible={showShare} 
          onClose={() => setShowShare(false)} 
          />
        <FeedbackDisplay
          listing={listing}
          visible={showFeedback}
          onClose={() => setShowFeedback(false)}
          />
      </div>
    </div>
  );
}

export default ShareAndFeedback;