import React from 'react';
import classes from './single-faq.module.css'

const Sfaq = (props) => {
    return (
        <div className={classes.faq}>
            <div onClick={props.clicked} className={[classes.faqhdr, props.visible ? classes.open : null].join(' ')}>
                <p>{props.titel}</p>
            </div>
            <div className={[classes.faqBody, props.visible ? classes.open : null].join(' ')}>
                <p>{props.body}</p>
            </div>
        </div>
    )
}
export default Sfaq