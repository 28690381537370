
import React, { ReactChild } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactChild | Array<ReactChild>;
  visible?: boolean;
  title?: string;
  onClose?: () => void;
}

const Modal = ({ children, title, visible, onClose }: Props) => {

  return (
    <div className={classNames('modal', { active: visible })} id='modal-id'>
      <div className='modal-overlay' aria-label='Close' onClick={onClose} />
        <div  className='modal-container'>
          <div className='modal-header'>
            <button className='btn btn-clear float-right' onClick={onClose} aria-label='Close' />
            { Boolean(title) && 
              <div className='modal-title h1'>{ title }</div> 
            }
          </div>
              <div className='modal-body'>
                <div className='content'>
                  { children }
                </div>
              </div>
        </div>
    </div>
  );
}

export default Modal;