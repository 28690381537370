
import { useState, useCallback, useEffect } from 'react';
import Cookies, { CookieAttributes } from 'js-cookie';

type CookieHookValue = [
  any,
  (value: any) => void,
  () => void
];

export function setCookie(name: string, value: any, options?: CookieAttributes): void {
  Cookies.set(name, JSON.stringify(value), options);
}

export function getCookie(name: string): any {
  const value = Cookies.get(name);
  console.log({ name, value })
  if (value) {
    try {
      return JSON.parse(value);
    } catch(e) {
      return value;
    }
  }

  return undefined;
}

const handlers: { [name: string]: Array<() => void> } = {};
function subscribe(name: string, callback: () => void) {
  if (!handlers[name]) {
    handlers[name] = [callback];
  } else {
    handlers[name].push(callback);
  }

  return function unsubscribe() {
    handlers[name].splice(handlers[name].indexOf(callback));
  }
}

function publish(name: string) {
  if (handlers[name]?.length) {
    for (const handler of handlers[name]) {
      handler();
    } 
  }
}

export default function useCookie(name: string, options?: CookieAttributes): CookieHookValue {
  const [value, setValue] = useState(() => getCookie(name));

  useEffect(() => {
    const unsubscribe = subscribe(name, () => {
      setValue(getCookie(name));
    });

    return () => unsubscribe();
  }, [name])

  const update = useCallback((value: any): void => {
    setCookie(name, value);
    publish(name);
  }, [name]);

  const remove = useCallback((): void => {
    Cookies.remove(name, options);
    publish(name);
  }, [name, options]);

  return [
    value, update, remove
  ];
}