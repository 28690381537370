
import React, { useCallback } from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);
// TODO make required work for Radio button!

const Radio = (props) => {
  const { options = [], horizontal, getValue, setValue, label, showRequired, isPristine, isValid, showError, 
    getErrorMessage, placeholder, ...rest } = props;

  const error = getErrorMessage();

  const RadioOptions = useCallback(() => options.map((option, i) => (
    <label key={option} className={classNames('form-radio', horizontal && 'form-inline')}>
      <input 
        type='radio'
        value={option}
        checked={getValue() === option}
        onChange={e => setValue(e.currentTarget.value)}
        />
      <i className="form-icon"></i> {option}
    </label>
  )), [options, getValue, setValue, horizontal]);

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <div className={classNames('col-sm-12', { 'col-9': horizontal })} {...rest}>
        <RadioOptions />
      </div>
      <div className={classNames('col-sm-12', { 'col-9 col-offset-3': horizontal })} {...rest}>
        { Boolean(error) && (
          <p className="form-input-hint">{ error }</p>
        ) }
      </div>
    </div>
  );
}

export default withFormsy(Radio);