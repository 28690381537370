
import React from 'react';
import { useMemo } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';

const countdownRenderer = (points: number) => ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (!completed) {
    return (
      <span>
        <strong>{days ? `${days} days, ` : ''}{hours} hours, {minutes} minutes &amp; {seconds} seconds</strong> remaining to receive a <strong>{points} point bonus</strong> as an early member of the Happy CoOp!
      </span>
    );
  }

  return null;
};

export default function CoopCountdown() {
  const { date, points } = useMemo(() => {
    const now = new Date();

    const two = new Date('2021-07-07T00:00:00.000Z');
    const twenty_four = new Date('2021-07-10T07:00:00.000Z');

    return {
      date: two > now ? two : twenty_four,
      points: two > now ? 1000 : 500
    }
  }, []);

  const renderer = useMemo(() => countdownRenderer(points), [points]);

  return (
    <Countdown 
      date={date}
      daysInHours
      renderer={renderer}
      />
  );
}