
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Divider from '../components/Divider.js';

const Jobs = () => {
  return (
    <Page title='HNP - Jobs'>
      <HeaderBar title='Available Positions' />
      <Container>
        <p><em>Nominated Business "Innovation Award" by the Sacramento Business Journal, Happy Neighborhood Project is a registry of happy businesses. We define a happy business as "a business that is committed to great customer service and encourages a positive work environment". Although almost all businesses think they are doing that, thousands of customer service problems still happen each day.</em></p>
        <p><em>Happy Neighborhood Project holds our happy businesses accountable through feedback from customers and employees. We also make it easier for our customers to find happy businesses that will take care of them. Our goal is to humanize the review and feedback systems.</em></p>
        <p>If interested in a position, please email your resume to <a href="mailto:hiring@hnpabc.com">hiring@hnpabc.com</a> with the job title as the subject. Qualified candidates will receive an initial phone screen call within 7 days from the application submission date. If performed well, they will move on to an in-person interview.</p>
        <Divider />
        <div className='card'>
          <div class="card-header">
            <div class="card-title h3">Outside Sales Representative</div>
            <div class="card-title h4">$34,776 - $64,656 per year</div>
            <div class="card-subtitle text-gray">Full-Time (W-2)</div>
          </div>
          <div class="card-body">
            <h5>Job Description</h5>
            <p>The Happy Outside Sales Representative will be responsible for signing up happy businesses to be a part of the Happy Neighborhood Project. We currently have about 500 business members in the local area. This ideal person lives in either Placer, Sacramento, Yolo or Solano Counties and enjoys the flexibility of working remotely, but is also disciplined, a go-getter, and thrives in hitting goals.</p>
            <p>The Happy Outside Sales Representative has a positive outlook and is excited about being a part of a fast-paced, fast-growing company. Finally, this ideal person is a top performer and is ready to be a part of taking the company to the next level.</p>
            <h5>Requirements</h5>
            <ul>
              <li>Ability to travel up to 50% in the local area.</li>
              <li>2 or more years of outside sales experience.</li>
              <li>Must have a happy and positive outlook.</li>
              <li>Must be coachable and willing to follow a proven system.</li>
              <li>Thrives in a startup, fast growth, and dynamic environment.</li>
              <li>Have a reliable car and cell phone to schedule appointments and close deals.</li>
            </ul>
            <h5>Key Job Functions</h5>
            <ul>
              <li>Sell memberships to happy business owners in the local area.</li>
              <li>Responsible for full-cycle sales from setting sales appointments to closing the deal.</li>
              <li>Schedule sales appointment through calls and email marketing automation tools.</li>
              <li>Prospect for new happy businesses to increase sales and market share in a designated territory.</li>
              <li>Attend weekly sales meetings to improve the sales process.</li>
              <li>Daily reporting of sales activity through CRM.</li>
              <li>Establish, develop, and maintain a positive relationship with clients to drive annual renewals.</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Be a part of a fast-paced and fast-growing company- Nominated "Innovation Award" by Sacramento Business Journal.</li>
              <li>Opportunity for growth within 6 months if top performer.</li>
              <li>Ability to work from home / remotely.</li>
              <li>Competitive high-performance bonus and annual renewal bonus.</li>
              <li>Excellent onboarding and hands-on training.</li>
              <li>Cell phone reimbursement.</li>
            </ul>
          </div>
        </div>
      </Container>
    </Page>
  )
}

export default Jobs;