
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { Grid, Row, Col } from '@react-spectre/grid';
import classNames from 'classnames';
import { useTranslation } from'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../components/form/HookFormInput';
import HookFormRadio from '../components/form/HookFormRadio';
import { sendClub100Info } from '../API.js';
import Link from 'react-router-dom/Link';

const Club100Form = ({ onResult }) => {
  const { t } = useTranslation();
  const _form = useForm();
  const { handleSubmit, errors } = _form;
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await sendClub100Info(data);
    setResult(result);
    setLoading(false);
    onResult && onResult(result);
  };

  if (result) {
    return (
      <div class="empty">
        <div class="empty-icon">
          <i class="icon icon-people"></i>
        </div>              
        <p>{ t('countmein.success.p1', 'Thank you for your support!') }</p>
        <p>{ t('countmein.success.p2', 'We are glad to be able to Count on You!') }</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookFormInput 
        name='name.first' 
        placeholder='First Name'
        label='First Name'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='name.last' 
        placeholder='Last Name'
        label='Last Name'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='email' 
        placeholder='Email Address'
        label='Email Address'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='phone' 
        placeholder='Phone Number'
        label='Cell Phone'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormRadio 
        name='coop_member' 
        items={['Yes', 'No']}
        label={<span>
          Are you a <Link to='/coop'>CoOp Member</Link>?
        </span>}
        form={_form}
        formProps={{ required: true }}
        />
      <button 
        disabled={errors.length || loading} 
        className={classNames('btn btn-primary mt-2', { loading })} 
        type="submit" 
        >
          Submit!
      </button>
    </form>
  );
}

const Club100 = () => {
  const { t } = useTranslation();
  
  return (
    <Page title='Club 100'>
      <HeaderBar title={t('club100.heading', `I want to join Club 100!`)} />
      <Container>
        <div className='text-center'>
          <blockquote>
            I am committing to personally help 100 new members join the CoOp in 2023.
          </blockquote>
        </div>
        <Grid>
          <Row>
            <Col md={12}>
              <Club100Form />
            </Col>
          </Row>
        </Grid>
      </Container>
    </Page>
  )
}

export default Club100;