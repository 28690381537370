
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';

const Contact = () => {
  return (
    <Page title='HNP - Contact Us'>
      <HeaderBar title='Contact Us' />
      <Container style={{textAlign: 'center'}}>
        <p>Email: <a href='mailto:info@hnpabc.com'>info@hnpabc.com</a></p>
        <p>Phone: <a href='tel:9165422467'>(916) 542-2467</a></p>
      </Container>
    </Page>
  )
}

export default Contact;