
import React, { useEffect } from 'react';
import Page from '../../components/layout/Page';
import Section, { Divider } from './components/Section';
import { Header, SubHeader, Purple, Yellow, Paragraph, Text, Checkmark } from './components/Text';
import { Row, Third, Half } from './components/Layout';
import ExternalLink from '../../components/ExternalLink';
import { Link } from 'react-router-dom';

const Classes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP Classes' className='landing-page'>
    <Section light full short>
      <Header>
        HAPPY NEIGHBORHOOD PROJECT's
      </Header>
      <SubHeader>
        <span style={{ position: 'absolute', backgroundColor: 'white', padding: '.5em 1em', zIndex: 1 }}>3 FREE CLASSES</span>
        <span style={{ margin: '.5em 1em' }}>3 FREE CLASSES</span>
        <div style={{ position: 'absolute', top: '80%', left: '10%', backgroundColor: '#54319B', width: '80%', height: '.25em', zIndex: 0 }} />
      </SubHeader>
      <Divider />
      <Row>
        <Third className='v-split px-2'>
          <div>
            <img src='/images/icon-bars.png' style={{ width: '10em' }} alt='Happiness as a Skill' />
            <SubHeader><Purple>Happiness as a Skill</Purple></SubHeader>
            <Divider />
            <Paragraph>Learn how to develop your skills to improve your happiness. Our class will transform your way of thinking so that you can be happy all of the time.</Paragraph>
          </div>
          <ExternalLink className='btn btn-lg' href='https://us02web.zoom.us/meeting/register/tZUodu-vrz4qHdf4PWCosiF4D0r30WbkbUNp'>
            Register for this Class
          </ExternalLink>
        </Third>
        <Third className='v-split px-2'>
          <div>
            <img src='/images/icon-handshake.png' style={{ width: '10em' }} alt='Networking 101' />
            <SubHeader><Purple>Networking 101</Purple></SubHeader>
            <Divider />
            <Paragraph>Learn learn how to network the right way. This class will help you transform the amount of referrals you are getting so that you can grow your business.</Paragraph>
          </div>
          <ExternalLink className='btn btn-lg' href='https://us02web.zoom.us/meeting/register/tZwrcuiuqTMiH9WSGxFweU9LoITQSRltW4TM'>
            Register for this Class
          </ExternalLink>
        </Third>
        <Third className='px-2 v-split'>
          <div>
            <img src='/images/icon-display.png' style={{ width: '10em' }} alt='Zoom 101' />
            <SubHeader><Purple>Zoom 101</Purple></SubHeader>
            <Divider />
            <Paragraph>Learn how to use Zoom to service your customers and grow your business.</Paragraph>
          </div>
          <ExternalLink className='btn btn-lg' href='https://us02web.zoom.us/meeting/register/tZ0oduuvqj0tH9OGTt1MiwkBcKMlCxUhkxuM'>
            Register for this Class
          </ExternalLink>
        </Third>
      </Row>
    </Section>
    <Section full short>
      <SubHeader>
        <span style={{ position: 'absolute', backgroundColor: 'black', padding: '.5em 1em', zIndex: 1 }}>EXCLUSIVE BUSINESS<br />GROWTH EDUCATION<br />SESSIONS</span>
        <span style={{ margin: '.5em 1em' }}>EXCLUSIVE BUSINESS<br />GROWTH EDUCATION<br />SESSIONS</span>
        <div style={{ position: 'absolute', top: '50%', left: '10%', backgroundColor: '#FECA66', width: '80%', height: '.25em', zIndex: 0 }} />
      </SubHeader>
      <Divider />
      <Row>
        <Half>
          <img src='/images/some-zooms.png' style={{ width: '15em' }} alt='Zoom 101' />
          <Divider />
          <SubHeader><Yellow><Checkmark /> #AskHNP</Yellow></SubHeader>
          <Text>+Power Networking</Text>
          <Divider />
          <Link className='btn btn-lg btn-error' to='/askhnp'>
            Learn More
          </Link>
        </Half>
        { /* <Half>
          <img src='/images/some-zooms.png' style={{ width: '15em' }} alt='Zoom 101' />
          <Divider />
          <SubHeader><Yellow>HNP Business Deep Dive</Yellow></SubHeader>
          <Text>+Power Networking</Text>
          <Divider />
          <Link className='btn btn-lg btn-error' to='/deepdive'>
            Learn More
          </Link>
        </Half> */}
      </Row>
    </Section>
    </Page>
  );
}

export default Classes;