
import React from 'react';
import { DEBUG_MODE } from '../API.js';
import useMember from '../tools/useMember';

const DebugView = () => {
  const { member } = useMember();

  if (DEBUG_MODE || member) {
    return (
      <>
        { Boolean(DEBUG_MODE) && (
          <div style={{ position: 'fixed', right: '1em', bottom: '1em', zIndex: 1000, opacity: .4, pointerEvents: 'none' }}>
            <h4 style={{ backgroundColor: 'yellow', padding: '.5em', borderRadius: '.5em' }}>Debug Mode!</h4>
          </div>
        )}
        { Boolean(member) && (
          <div title={member.name} style={{ position: 'fixed', left: '1em', bottom: '1em', zIndex: 1000, opacity: .4, pointerEvents: 'none' }}>
            <div style={{ backgroundColor: 'green', padding: '.5em', borderRadius: '.5em' }} />
          </div>
        )}
      </>
    );
  }

  return null;
}

export default DebugView;