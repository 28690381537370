
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HamburgerMenu from 'react-hamburger-menu';
import { HideDesktop, HideMobile } from './ResponsiveHide';
import MenuItem from './MenuItem.js';
import DropdownItem from './DropdownItem.js';

const MENU_ITEMS = [
  { label: <><i className='icon icon-search'/> Directory</>, to: '/listings' },
  // { label: 'App', to: '/app' },
  { label: 'TEDx', to: '/TEDx' },
  // { label: 'Our Story', to: '/about' },
  { label: 'Events', items: [
    { label: 'Networking', to: '/networking' },
    { label: 'Free Classes', to: '/class' },
    // { label: 'Community Events', to: '/events' }
  ] },
  // { label: 'Classes', href: 'https://hnpabc.eventbrite.com/' },
  // { label: 'Refer', to: 'refer-a-business' },
  // { label: 'PBS', to: '/PBS' },
  // { label: 'Zoom', to: '/zoom' },
  // { label: 'Business Support', to: '/business-support' },
  // { label: 'Contact', to: '/contact' },
  // { label: 'Jobs', to: '/jobs' },
  { label: 'Membership', to: '/membership', boxed: true },
  { label: 'The Happy Coop', to: '/coop', items: [
    { label: 'Overview', to: '/coop' },
    { label: 'Points', to: '/coop/points' }
  ] },
  { spacer: true },
  // { label: 'Happy Sales Flow', to: '/salesflow' },
  { label: 'Podcast', to: '/podcast' },
  { label: 'YouTube', href: 'https://youtube.com/@EdwinEdebiri' },
  // { label: 'GHS 2023', to: '/ghs' },
  // { label: 'GHS 2023', href: 'https://hopin.com/events/global-happiness-summit-2023', target: '_blank', rel: 'noreferrer noopener' },
  { label: 'IDH 2024', to: '/idh/ticket' },
  // { label: 'Networking', to: '/networking' },
  // { label: 'Classes', to: '/class' },
  // { label: 'Global Summit', to: '/ghs2020' },
  // { label: 'Links', to: '/links' },
  // { label: 'Shop', to: '/shop' },
  // { label: 'Login', href: 'https://admin.hnpabc.com/', style: { marginLeft: '3em'} }
];

const styles = {
  mobileHeader: { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    padding: '1em'
  }
};

const Navigation = styled.div`
  padding: 20;
  text-align: center;

  @media (max-width: 720px) {

  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: height 2s ease-out;

  @media (max-width: 720px) {
    display: ${({open = false }) => open ? 'flex' : 'none'};
    flex-direction: column;
    overflow: visible;
    height: ${({open = false }) => open ? 'auto' : 0};
    transition: height 2s ease-out;
  }
`;

const Logo = ({size = 8}) => (
  <Link to='/' style={{display: 'inline-block'}}>
    <img 
      src='/images/hnp-logo-192.png' 
      style={{height: `${size}em`}} 
      alt='Happy Neighborhod Project Logo' 
      />
  </Link>
);

function renderMenuItem (sideEffect) {
  return function (item, index) {
    return (
      item.items ? 
        <DropdownItem key={item.label || index} {...item} sideEffect={sideEffect} /> :
        <MenuItem key={item.label || index} {...item} onClick={sideEffect} />
    );
  }
}

const Header = () => {
  const [open, setOpen] = useState(false);
  const items = useMemo(() => MENU_ITEMS.map(renderMenuItem(() => setOpen(false))), []);

  return (
    <Navigation>
      <HideDesktop>
        <div style={styles.mobileHeader}>
          <Logo size={5} />
          <div style={{padding: '1em', flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
            <HamburgerMenu
              color='#5755D9'
              isOpen={open}
              strokeWidth={3}
              menuClicked={() => setOpen(o => !o)}
              />
          </div>
        </div>
      </HideDesktop>
      <Menu open={open}>
        <HideMobile>
          <Logo size={3} />
        </HideMobile>
        { items }
      </Menu>
    </Navigation>
  );
}

export default Header;