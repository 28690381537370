
import React from 'react';
import { withFormsy } from 'formsy-react';
import StripeCreditCardInput from './StripeCreditCardInput.js';
import classNames from 'classnames';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);

function FormsyStripeCreditCardInput ({ getValue, setValue, label, showRequired, horizontal }) {

  return (
    <>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <StripeCreditCardInput 
        token={getValue()} 
        onToken={setValue} 
        onError={e => console.error('FormsyStripeCreditCardInput', e)}
        />
    </>
  );
}

export default withFormsy(FormsyStripeCreditCardInput);