
import React, { useState, useEffect, useMemo } from 'react';
import dateFormat from 'dateformat';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import RegistrantsModal from '../components/ZoomRegistrantsModal';
import { getZoomLeaderboard } from '../API.js';
import {
  useTable,
  useSortBy
} from 'react-table';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import ExternalLink from '../components/ExternalLink';

const query = gql`
  query GetHostsQuery {
    mentors {
      name
      website
    }
    captains {
      name
      website
    }
    trainers {
      name
      website
    }
  }
`;

const toInt = (i) => parseInt(String(i), 10) || 0;

const ZoomLeaderboard = ({ selectRegistrants }) => {
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState();
  const { data: hostData } = useQuery(query);
  const [totals, setTotals] = useState({
    registrants: 0,
    previous: 0
  });

  const columns = useMemo(() => [{
    Header: 'Location',
    accessor: 'location',
    // Cell: ({ value, row }) => (
    //   <button className='btn btn-link' onClick={() => selectRegistrants(row.original)}>
    //     { value }
    //   </button>
    // ),
  }, {
    Header: 'Registered',
    accessor: 'registrants'
  }, {
    Header: 'Previous Registered',
    accessor: 'previous'
  }, {
    Header: 'Time',
    accessor: 'start_time',
    Cell: ({ value }) => value ? dateFormat(value, 'dddd mmm d, h:MMTT (Z)') : ''
  }, {
    Header: 'Meeting ID',
    accessor: 'meeting_id'
  }], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
     columns, 
     data,
     initialState: {
       sortBy: [{
         id: 'registrants',
         desc: true
       }]
     }
    }, useSortBy);

  useEffect(() => {
    getZoomLeaderboard().then((registrants = []) => {
      setData(registrants);

      const totals = registrants.reduce((p = {}, c) => {
        return {
          registrants: toInt(p.registrants) + toInt(c.registrants),
          previous: toInt(p.previous) + toInt(c.previous)
        }
      }, {});

      setTotals(totals);
      setUpdated(new Date());
    });
  }, []);

  return (
    <div>
      { Boolean(hostData) && (
        <div>
          Mentors: {hostData.mentors.map((mentor, key) => (
            <span key={key}>{ mentor.name } { Boolean(mentor.website) && <ExternalLink href={mentor.website}>(Website)</ExternalLink> } | </span>
          ))}
          <br />
          Captains: {hostData.captains.map((captain, key) => (
            <span key={key}>{ captain.name } { Boolean(captain.website) && <ExternalLink href={captain.website}>(Website)</ExternalLink> } | </span>
          ))}
          {/* <br />
          Trainers: {hostData.trainers.map((trainer, key) => (
            <span key={key}>{ trainer.name } { Boolean(trainer.website) && <ExternalLink href={trainer.website}>(Website)</ExternalLink> } | </span>
          ))} */}
          <hr />
        </div>
      )}
      <p style={!totals.registrants ? { display: 'hidden' } : {}}>
        Total Registered: { totals.registrants } ({totals.previous} previous)
        <br />
        <small>[Last Updated: { updated ? dateFormat(updated, 'mm/dd, h:MMtt (Z)') : ''}]</small>
      </p>
      <table className='table' {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

const Hosts = () => {
  const [registrants, setRegistrants] = useState();

  return (
    <Page title='Zoom Leaderboard'>
      <HeaderBar title='Zoom Leaderboard' />
      <Container style={{textAlign: 'center'}}>
        <ZoomLeaderboard selectRegistrants={setRegistrants} />
        { Boolean(registrants) && 
          <RegistrantsModal registrants={registrants} onClose={() => setRegistrants()} />
        }
      </Container>
    </Page>
  )
}

export default Hosts;