
import React, { useState, useCallback } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Formsy from 'formsy-react';
import Input from '../components/form/Input.js';
import Radio from '../components/form/Radio.js';
import Divider from '../components/Divider';
import FormsyStripeCreditCardInput from '../components/form/FormsyStripeCreditCardInput.js';
import ExternalLink from '../components/ExternalLink.js';
import classNames from 'classnames';
import { gql, useMutation } from '@apollo/client';
import useCoopReferrer from '../data/useCoopReferrer';

const mutation = gql`
  mutation RegisterRemoteListing($data: RemoteListingRegistrationInput) {
    registerRemoteListing(data: $data) {
      receipt {
        url
      }
    }
  }
`;

const RemoteListingRegistrationForm = () => {
  const [valid, setValid] = useState<boolean>(false);
  const [referrer_uuid] = useCoopReferrer();
  const [register, { data, loading }] = useMutation(mutation);

  const submit = useCallback(async (variables) => {
    try {
      const result = await register({ variables});

      console.log('Subscribe to Remote Listing Plan', result);
    } catch(error) {
      console.error('Subscribe to Remote Listing Plan', error);
    }
  }, [register]);

  if (data) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've successfully registered.</h2>
        <ExternalLink href={data?.receipt_url}>View Your Receipt</ExternalLink>
      </div>
    );
  }

  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onSubmit={submit}
      className='col-8'
      >
      { Boolean(referrer_uuid) ? (
        <>
          <h2>Please Enter Your Information!</h2>
          <Input type='hidden' name='referrer_uuid' value={referrer_uuid} />
          <Input type='hidden' name='referrer_type' value='coop_member' />
        </>
      ) : (
        <h2>Please Enter Your Information</h2>
      )}
      <Input name='company' label='Company Name' placeholder='Company Name' />
      <Input name='name.first' label='First Name' placeholder='Your First Name' required />
      <Input name='name.last' label='Last Name' placeholder='Your Last Name' required />
      <Divider />
      <Input name='email' label='Email' placeholder='name@example.com' validations='isEmail' validationError='Valid email required.' required />
      <Input name='phone' label='Phone Number' />
      <Divider />
      <FormsyStripeCreditCardInput name='token' label='Payment Info' required />
      <Input name='address.street' label='Street Address' />
      <Input name='address.city' label='City' />
      <Input name='address.state' label='State' />
      <Input name='address.zip' label='Zip' />
      <Radio 
        name='isHappyBusiness' 
        label='Are you already a Verified Happy Business&trade;?'
        options={['Yes', 'No']} 
        />
      <Divider />
      <div className='col-12 clearfix my-2'>
        <button type='submit' className={classNames('btn btn-primary btn-block', { loading })} disabled={!valid || loading}>Submit</button>
      </div>
    </Formsy>
  );
}

const RemoteListingSignupPage = () => {
  return (
    <Page title='HNP - Remote Listing / Networking Plan'>
      <HeaderBar title='Remote Listing / Networking Plan' />
      <Container>
        <div>
          <p>We have a plan for our Happy Networkers</p>
          <p>All plans purchased now will get the month of November for free making your first renewal date December 1st, 2021.</p>
        </div>
        <hr />
        <Divider />
        <RemoteListingRegistrationForm />
      </Container>
    </Page>
  );
}

export default RemoteListingSignupPage;