
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/layout/Container';
import HeaderBar from '../../components/layout/HeaderBar';
import Page from '../../components/layout/Page';
import breakdown, { lastUpdated } from './point_breakdown';
import PointBreakdownItem from './PointBreakdownItem';

export default function CoopPoints() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP - CoOp Points'>
      <HeaderBar title='CoOp Points!' />
      <Container>
        <div className='toast toast-primary text-center'>
          <Link to='/coop/registration'>Become a Member Now!</Link>
        </div>
        <div className='divider' />
        <PointsList />
      </Container>
    </Page>
  );
}

const PointsList = () => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <em>Last Updated: { lastUpdated.toLocaleString() }</em>
    <ul style={{ listStyle: 'none' }}>
      { breakdown.map((item, i) => (
        <li key={i}>
          <PointBreakdownItem 
            breakdown={item} 
            top
            />
        </li>
      ))}
    </ul>
  </div>
);