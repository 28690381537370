
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ExternalLink from '../components/ExternalLink.js';
import { Helmet } from 'react-helmet';

const title = encodeURI('The Global Happiness Summit!');
const caption = encodeURI('I am at the virtual Global Happiness Summit! We will be breaking the Guinness World Record for the largest networking event in history from 11:00a PST/ 2:00p EST until 12:30p PST/ 3:30p EST! Come make history with us!')
const link = 'https://hnpabc.com/summit';

const facebook_link = `https://www.facebook.com/sharer/sharer.php?u=${link}&title=${title}&caption=${caption}`;
const linkedin_link = `https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${title}&summary=${caption}`;
const twitter_link = `https://twitter.com/intent/tweet?url=${link}&text=${caption}`;

const ShareGHS = () => {
  return (
    <Page title='HNP - Share the Summit'>
      <HeaderBar title='Share the Global Happiness Summit!' />
      <Helmet>
        <meta property="og:title" content="Global Happiness Summit 2020"/>
        <meta property="og:url" content="https://happyneighborhoodproject.com/summit"/>
        <meta property="og:description" content="Today is the Global Happiness Summit! We will be breaking the Guinness World Record for the largest networking event in history from 11:00a PST/ 2:00p EST until 12:30p PST/ 3:30p EST! Come make history with us!"/>
      </Helmet>
      <Container style={{textAlign: 'center'}}>
        <ul>
          <li>
            <ExternalLink href={facebook_link}>
              Share on Facebook!
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={linkedin_link}>
              Share on LinkedIn!
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={twitter_link}>
              Share on Twitter
            </ExternalLink>
          </li>
        </ul>
      </Container>
    </Page>
  )
}

export default ShareGHS;