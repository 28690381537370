import React from 'react';
import trustImg from '../../assets/img/handshake-businessmen.jpg'
import Image from '../UI/image/image'
const trust = () => {
    return (
        <div className="container">
            <Image style={{borderRadius:"25px"}} src={trustImg} />
        </div>
    )
}
export default trust