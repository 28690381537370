import React, { useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CoopCategory, CoopListing } from '../hnp-graphql';
import ListingCard from '../components/ListingCard';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Page from '../components/layout/Page';
import Fuse from 'fuse.js';

const query = gql`
  query Listings {
    coop_categories {
      uuid
      name
      slug
      count
    }
    coop_listings {
      uuid
      member_uuid
      category_uuid
      category
      share
      vhb
      premium
      name
      blurb
      logo
      description
      keywords
      contact
      phone
      website
      email
      linkedin
      instagram
      facebook
      twitter
      tiktok
      address {
        street
        city
        state
        zip
        country
      }
    }
  }
`;

interface QueryResponse {
  coop_listings: Array<CoopListing>;
  coop_categories: Array<CoopCategory>;
}

const ListingSearchPage = () => {
  const { data } = useQuery<QueryResponse>(query);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState<Array<any>>([]);

  const fuse = useMemo(() => {
    if (data?.coop_listings) {
      return new Fuse(data.coop_listings, {
        keys: ['name', 'blurb', 'contact', 'description']
      });
    }

    return new Fuse([]);
  }, [data?.coop_listings]);

  useEffect(() => {
    const results = fuse.search(search);

    if (results.length) {
      setFiltered(results.map(result => result.item));
    } else {
      setFiltered(data?.coop_listings || []);
    }
  }, [search, fuse, data?.coop_listings]);

  return (
    <Page title='Search Listings'>
      <HeaderBar title='Business Listings' />
      <Container>
        <div className='form-group p-2 col-sm-12 col-md-8 col-lg-6 col-4'>
          <div className='has-icon-left'>
            <input 
              className='form-input'
              type='text'
              value={search}
              placeholder='Search...'
              onChange={e => setSearch(e.currentTarget.value)}
              />
            <i className='form-icon icon icon-search'></i>
          </div>
        </div>
        <div className='mt-2 p-2 columns'>
          { filtered.map(listing => (
            <div key={listing.uuid} className='column col-sm-12 col-md-12 col-lg-6 col-4 py-2'>
              <ListingCard listing={listing} />
            </div>
          ))}
        </div>
      </Container>
    </Page>
  );
};

export default ListingSearchPage;
