
import React from 'react';
import Linkify from 'react-linkify';
import punycode from 'punycode';
import ExternalLink from './ExternalLink.js'

const decorator = (linkProps) => (href, text, index) => (
  <ExternalLink {...linkProps} key={index} href={href}>
    { punycode.toASCII(text) }
  </ExternalLink>
);

const ExternalLinkify = ({ children, properties, linkProps, ...props }) => (
  <Linkify {...props} componentDecorator={decorator(linkProps)} >
    { children }
  </Linkify>
);

export default ExternalLinkify;