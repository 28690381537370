import React from 'react';
import { Link } from 'react-router-dom';
import btnClasses from '../UI/button/button.module.css';
import classes from './about.module.css'

const about = (props) => {
    return (
        <section id={classes.about}>
            <div className={[classes.container, 'container'].join(' ')}>
                <h2 className={classes.sectitel}>What is the Happy CoOp</h2>
                <p className={classes.aboutdes}>The Happy CoOp is the first ever networking organization that allows its members to share in its revenue! We believe in having a happy community where our members should have a voice. So we decided to form a cooperative association with 2 classes of membership, Free Membership and Share Members who will have one Membership Share and One Vote.</p>
                <div style={{ textAlign: 'center' }}>
                    <Link to='/coop/registration' className={[btnClasses.button, btnClasses.btnBlue].join(' ')} center style={{ marginTop: '30px' }} >
                        BECOME A MEMBER
                    </Link>
                </div>
            </div>
        </section>
    )
}
export default about;