
import React, { useEffect, useState } from 'react';
import useCalls from '../data/useCalls';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import CallInfo from '../components/CallInfo';
import { useTranslation } from'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const GOBRUNCH_links = {
  monthly_gourmet: 'https://checkout.gobrunch.com/b/eVacNddpz2dH74Y009?prefilled_promo_code=HNP24',
  monthly_prime_chef: 'https://checkout.gobrunch.com/b/dR6aF51GRf0tfBu8wG?prefilled_promo_code=HNP24'
};

const CALLS = [
  { name: 'HNP 24/7 Networking Lounge', day: 'Everyday', time: '', url: 'https://gobrunch.com/hnp247networking', captains: [], hosts: [] },
  { name: 'Posting Party', day: 'Monday', time: '4pm', url: 'https://gobrunch.com/postingparty', captains: [''], hosts: [] },
  { name: 'HNP Global', day: 'Tuesday', time: '7am', url: 'https://gobrunch.com/hnpglobal', captains: ['Claudeth', 'Fareed'], hosts: ['Shirley Burgess', 'Elizabeth Oberkirch', 'Medi Lukenia', 'Col. Aditya Singhal'] },
  { name: 'HNP Women', day: 'Tuesday', time: '11am', url: 'https://gobrunch.com/hnpwomen', captains: ['Sheryl', 'Melinda'], hosts: ['River Easter', 'Lenora Wong', 'Deborah Dewet', 'Patricia McDow'] },
  { name: 'HNP LGBTQ Supportive Businesspeople', day: 'Wednesday', time: '3pm', url: 'https://gobrunch.com/hnplgbtq', captains: ['Alan Raisman'], hosts: [] },
  { name: 'HNP B2B', day: 'Wednesday', time: '11am', url: 'https://gobrunch.com/hnpb2b', captains: ['Tom'], hosts: ['Patricia Smith', 'Jennifer Belik', 'Jean Crasta', 'DJ Horton', 'Kurt Roskopf', 'Susan Roskopf'] },
  { name: 'HNP Canada', day: 'Thursday', time: '1pm', url: 'https://gobrunch.com/hnpcanada', captains: ['Sid'], hosts: ['Amanda Russel', 'Kathya Sierwattan', 'Brenda Sheldrake', 'Claudia Swanik', 'Marjorie Saulson'] },
  { name: 'HNP Co-Working Space', day: 'Thursday', time: '2pm', url: 'https://gobrunch.com/hnpcoworking', captains: [], hosts: [] },
  { name: 'HNP Evening Entrepreneurs', day: 'Thursday', time: '4pm', url: 'https://gobrunch.com/hnpeveningentrepreneurs', captains: ['Denise'], hosts: ['Anne Feustel', 'Don Crumlin'] },
  { name: 'HNP Worldwide', day: 'Friday', time: '11am', url: 'https://gobrunch.com/hnpworldwide', captains: ['Edwin'], hosts: [] },
  // { name: 'HNP Australia', day: 'Tuesday', time: '2pm', url: 'https://gobrunch.com/hnpaustralia', captains: ['Ian'], hosts: ['Jacalyn Price', 'Pat Grosse', 'Faye Waterman', 'David Quinn-Watson', 'Debra Harmon'] },
  // { name: 'HNP Australia Lunchtime', day: 'Monday', time: '6pm', url: 'https://gobrunch.com/hnpaustralialunchtime', captains: [], hosts: ['Kristine Best', 'Nicolas Goorbarry'] },
  { name: 'HNP Weekend', day: 'Saturday', time: '9am', url: 'https://gobrunch.com/hnpweekend', captains: ['Carolyn'], hosts: ['Ralph Danna', 'Bola Kareem', 'Charlisa Shelton'] },
  { name: 'HNP Speed Networking', day: 'Wednesday', time: '3pm', frequency:'2nd', url: 'https://gobrunch.com/hnpspeednetworking', captains: ['Ian', 'Sid', 'Carolyn', 'Melinda'], hosts: [] },
  { name: 'HNP Lunch & Learn', day: 'Thursday', time: '10am', frequency:'2nd & 4th', url: 'https://gobrunch.com/hnplunchandlearn', captains: ['Carolyn'], hosts: [] },
];

const CallList = ({ title, calls }) => {
  if (!calls || !calls.length) {
    return null;
  }

  return (
    <div className='columns p-2 text-left'>
      <div className='col-12 mt-2 pt-2'>
        <h2 style={{ color: '#FFF' }}>{ title }</h2>
      </div>
      { calls.map((call, i) => (
        <div key={i} className='col-xs-12 col-md-6 col-3 p-1'>
          <div className='card text-center' style={{ minHeight: '100%', color: 'black' }}>
            <div className='card-header'>
              <div className='card-title h5'>{ call.name }</div>
              {/* <div className='card-subtitle'>
                { timeLocal }{ dayLocalText }
                { Boolean(start_time !== timeLocal) && 
                  <small className='text-gray d-block'>
                    { start_time }
                  </small>
                }
              </div> */}
            </div>
            <div className='card-body'>
              { Boolean(call.time) && (
                <>
                  { call.frequency || 'Every' } { call.day } at { call.time } (Pacific)<br />
                </>
              )}
            </div>
            <div className='card-footer'>
              <a className='btn btn-primary btn-block' href={call.url} target='_blank' rel='noopener noreferrer' title={'Go to ' + call.name}>
                Go to Meeting
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

const Networking = () => {
  const { t } = useTranslation();
  const calls = CALLS.slice().sort((a, b) => parseInt(a.time, 10) - parseInt(b.time, 10));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP - Networking' className='background background-networking-2'>
      <Container style={{ backgroundColor: '#000A', alignItems: 'left' }}>
        <div className='columns'>
          <div className='column pb-2' style={{ color: '#FFF' }}>
            <h1>{ t('networking.title') }</h1>
            <h2>{ t('networking.subtitle') }</h2>
            <p>Join one of our { calls.length || '-' } meetings!</p>
          </div>
        </div>
        <CallList title='Everyday' calls={calls.filter(call => call.day === 'Everyday')} />
        <CallList title='Mondays' calls={calls.filter(call => call.day === 'Monday')} />
        <CallList title='Tuesdays' calls={calls.filter(call => call.day === 'Tuesday')} />
        <CallList title='Wednesdays' calls={calls.filter(call => call.day === 'Wednesday')} />
        <CallList title='Thursdays' calls={calls.filter(call => call.day === 'Thursday')} />
        <CallList title='Fridays' calls={calls.filter(call => call.day === 'Friday')} />
        <CallList title='Saturdays' calls={calls.filter(call => call.day === 'Saturday')} />
        <CallList title='Sundays' calls={calls.filter(call => call.day === 'Sunday')} />
      </Container>
    </Page>
  )
}

export default Networking;