
import React, { useEffect, useState } from 'react';
import Page from '../../components/layout/Page';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './index.css';
import Section, { Divider } from './components/Section';
import { Header, Yellow, Checkmark, Text } from './components/Text';
import { Row, Third } from './components/Layout';
import Modal from './components/Modal';
import AskHNPForm from './forms/AskHNPForm';


const learn = ['Branding', 'Marketing', 'Business Growth', 'Operations', 'Competitive Advantage', 'Sales', 'Hiring', 'Social Media',
  'HR', 'Strategy', 'Leadership', 'Technology', 'Management', 'And more...'];

const powerNetworking = [
  'Be best matched with other verified members that compliment your business',
  'Build lifelong relationships with other like-minded members that want to see you succeed',
  'Go over exercises that will help others think of you first when referring business'
];

const AskHNP = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='Ask HNP' className='landing-page text-white'>
      <div className='d-flex background background-networking-2' style={{ height: '100vh', alignItems: 'center' }}>
        <div className='p-centered landing-hero text-center' style={{ padding: '4em 1em', color: 'white', backgroundColor: '#000A' }}>
          <img alt='' id='logo' src='/images/hnp-logo.png' width='480' height='240' />
          <Header>
            You got business questions<br />
            We've got the answers<br />
            <strong>Let's grow together</strong>
          </Header>
          <AnchorLink href='#power-networking' className='btn mt-2'>What is #AskHNP + Power Networking?</AnchorLink>
        </div>
      </div>
      <Section id='power-networking'>
        <Header>#AskHNP + Power Networking is an interactive business Q&amp;A and consulting session for ambitious verified Happy Business Members.</Header>
      </Section>
      <Section>
        <Header>What will I learn?</Header>
        <Text>The 90 minute session will be full of business Q&amp;A and consulting from the HNP Leadership Team + Power Networking.</Text>
        <Text>Most business owners ask us questions about:</Text>
        <Divider />
        <div className='list two-column'>
          { learn.map(item => (
            <span key={item} className='list-item'>
              <Checkmark /> { item }
            </span>
          ))}
        </div>
      </Section>
      <Section>
        <Header>What is <Yellow>Power Networking?</Yellow></Header>
        <Text>Power Networking is focused on really getting to know other verified members and build a stronger relationship with them.</Text>
        <Text className='text-left'>Through our program you will:</Text>
        <Divider />
        <div className='text-left'>
          { powerNetworking.map(item => (
            <span key={item} className='list-item'>
              <Checkmark /> { item }
            </span>
          ))}
        </div>
      </Section>
      <Section>
        <Header>Who Will I Meet?</Header>
        <Row className='pt-2'>
          <Third>
            <img 
              src='/images/aaron-talking.png' 
              title='A consulting session!' 
              alt='A consulting session!' 
              width='100%' 
              />
            <h2 className='text-yellow mt-2'><strong>HNP Leadership Team</strong></h2>
            <Text>Leadership teams from HNP lead the business Q&amp;A and consulting session.</Text>
          </Third>
          <Third />
          <Third>
            <img 
              src='/images/hnp-tribe.png' 
              alt='Happy businesses showing off their decals!' 
              title='Happy businesses showing off their decals!' 
              width='100%' 
              />
            <h2 className='text-yellow mt-2'><strong>HNP Tribe</strong></h2>
            <Text>Learn, network, collaborate &amp; build life long relationships with other members within the HNP tribe that bend over backwards to see you succeed.</Text>
          </Third>
        </Row>
      </Section>
      <Section full>
        <Header>The #AskHNP breakdown</Header>
        <Row>
          <Third className='p-2'>
            <h2 className='text-yellow'>Business Q&amp;A</h2>
            <Text className='text-muted'>45 minutes of business Q&amp;A. Get your questions answered while learning from others.</Text>
          </Third>
          <Third className='p-2'>
            <h2 className='text-yellow'>Debrief &amp; Action Plan</h2>
            <Text className='text-muted'>Go over key takeaways with the group and create an action plan.</Text>
          </Third>
          <Third className='p-2'>
            <h2 className='text-yellow'>Power Networking</h2>
            <Text className='text-muted'>40 minutes of Power Networking with verified Happy Business Members.</Text>
          </Third>
          <Divider height={3} />
          <Third className='p-2'>
            <h1>1</h1>
            <Text>HNP Business Deep Dive session per month</Text>
          </Third>
          <Third className='p-2'>
            <h1>20+</h1>
            <Text>business questions answered at each #AskHNP + Power Networking session</Text>
          </Third>
          <Third className='p-2'>
            <h1>90</h1>
            <Text>minutes of #AskHNP + Power Networking value</Text>
          </Third>
        </Row>
      </Section>
      <Section full>
        <div className='call-to-action'>
          <div className='left-two-thirds'>
            <Header>Ready to get your your business questions answered and start Power Networking?</Header>
          </div>
          <div className='right-third'>
            <button className='btn btn-lg' onClick={() => setShowForm(true)}>Reserve Your Seat</button>
          </div>
        </div>
      </Section>
      <Modal visible={showForm} onClose={() => setShowForm(false)} title='Reserve Your Seat'>
        <AskHNPForm key={showForm ? 0 : 1} onClose={() => setShowForm(false)} />
      </Modal>
    </Page>
  );
}

export default AskHNP;