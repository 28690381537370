import React from 'react';
import classes from './card.module.css'
const card = (props) => {
    const titel = props.titel ? <h3 className={classes.titel}>
        {props.titel}
    </h3>
        : null
    const styles = props.template === 'inline' ? [classes.inline, classes.card].join(' ') : classes.card
    return (
        <div style={{ width: props.w, ...props.style}} className={styles}>
            <div className={classes.icon}>
                <img src={props.icon} alt="icon" />
            </div>
            <div className={classes.content}>
                {titel}
                <p style={{ textAlign: props.template === 'inline' ? "left" : "center" }} className={classes.des}>
                    {props.des}
                </p>
            </div>
        </div>
    )
}
export default card