
import React from 'react';

const NEWLINE_REGEX = /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g;
// const NEWLINE_REGEX2 = /(\r\n|\r|\n)/g;

export default function nl2br(string) {
  if (typeof string === 'string') {
    return string.split(NEWLINE_REGEX).map((line, i) => (
      line.match(NEWLINE_REGEX) ? <br key={i} /> : line
    ));
  } else if (typeof string === 'number') {
    return string;
  }

  console.warn('nl2br expects a String, received: ' + typeof string);

  return string;
}