
import React, { useEffect } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import NetworkingRegistrationForm from '../components/NetworkingRegistrationForm';
import Divider from '../components/Divider';
import { useParams } from 'react-router-dom';
import useCalls from '../data/useCalls';
import { Helmet } from 'react-helmet';

const NetworkingSignupPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const calls = useCalls();
  const call = calls.find(call => call.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title='HNP - Networking Registration'>
      <HeaderBar title='Networking Registration' />
      <Container>
        <p>Happy Business Networking is the happiest virtual business networking events in the world! At our events you will be able to network with positive and happy business people, while making 10+ quality connections at each event.</p>
        <p>With over 50 weekly events and 12,000+ month registrations, we have made a commitment to keep these events free, forever. Due to a high number of recent registrations and a maximum room capacity, we cannot guarantee that everyone will be able to join the event. You will be given priority entry if you have registered and are early to join the event.</p>
        <Divider />
        { !!call && 
          <div className='col-8 col-md-10 col-sm-12'>
            <Helmet>
              <title>HNP Networking: { call?.location }</title>
              <meta property="og:title" content={`Happy Networking: ${call.location}`} />
              <meta property="og:url" content={`https://happyneighborhoodproject.com/networking/register/${call.slug}`} />
              <meta property="og:description" content={`Online Happy Networking call/meeting for ${call.location}!`} />
            </Helmet>
            <NetworkingRegistrationForm call={call} /> 
          </div>
        }
      </Container>
    </Page>
  );
}

export default NetworkingSignupPage;