
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import ExternalLink from '../components/ExternalLink';

const ZOOM_URL = 'https://us02web.zoom.us/meeting/register/tZEscOqvqD8oGtaLlu2Cx--4_CQwvkDdU2vf';

const ThcReveal = () => {
  return (
    <Page title='July 6th Reveal'>
      <HeaderBar />
      <Container>
        <p>Together since March of 2020, you have helped us spread happiness to thousands of people across the globe. Together, we have grown from 1 weekly networking event to now over 68 weekly events in 36 states and 8 countries. Together, we have raised the level of happiness of our group by an average of 35%.</p>
        <h4 style={{ textAlign: 'center', marginBottom: '2em' }}>It is time for us to give back even more.<br />This time, we are going to bring POWER back to the PEOPLE.</h4>
        <p>Join hundreds of happy and positive businesspeople at this historic BIG REVEAL. This will be the biggest thing we have ever done in the history of Happy Neighborhood Project and welcome you to share this experience with us!</p>
        <h5>We will be having a giveaway for $1,000 of products sponsored by Dell!</h5>
        <ExternalLink href={ZOOM_URL} className='btn btn-primary'>
          Register Now
        </ExternalLink>
      </Container>
    </Page>
  );
}

export default ThcReveal;