
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import useSearch from '../data/useSearch';

const ChatboutOauth = () => {
  const code = useSearch('code');

  return (
    <Page title='HNP - Chatbot'>
      <HeaderBar title='Awesome! See you in Zoom' />
      <Container>
        <h1>Added the Chat Helper</h1>
        <p>You've successfully added our Chatbot Helper to your account! Try it out sending a message in the chat!</p>
        <p style={{ display: 'none' }}>Your OAuth Code is: { code }</p>
      </Container>
    </Page>
  )
}

export default ChatboutOauth;