
import React, { useState } from 'react';
import MenuItem from './MenuItem.js';
import { HideDesktop, HideMobile } from './ResponsiveHide';

const DropdownItem = ({ label, items, sideEffect }) => {  
  const [open, setOpen] = useState(false);

  return (
    <>
      <HideDesktop>
        <button className="btn btn-link dropdown-toggle" onClick={() => setOpen(o => !o)} tabIndex="0">
          { label } <i className="icon icon-caret"></i>
        </button>
        <ul className="menu mb-2" style={{ display: open ? 'inherit' : 'none' }}>
          { items.map((item, index) => (
            <li key={item.label || index}>
              <MenuItem 
                {...item} 
                onClick={sideEffect} 
                />
            </li>
          ))}
        </ul>
      </HideDesktop>
      <HideMobile>
        <div className='dropdown'>
          <button className="btn btn-link dropdown-toggle" tabIndex="0">
            { label } <i className="icon icon-caret"></i>
          </button>
          <ul className="menu">
            { items.map((item, index) => (
              <li key={item.label || index}>
                <MenuItem 
                  {...item} 
                  onClick={sideEffect} 
                  />
              </li>
            ))}
          </ul>
        </div>
      </HideMobile>
    </>
  );
}

export default DropdownItem;