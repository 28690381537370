
import React, { useState, useEffect } from 'react';
import useCoopReferrer, { useCoopReferrerSlug } from '../data/useCoopReferrer';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import Page from '../components/layout/Page';

const query = gql`
  query CoopReferrer($slug: ID!) {
    coop_referrer(slug: $slug)
  }
`;

const CoopReferrer = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data } = useQuery<{ coop_referrer?: string }>(query, { 
    variables: { slug }, 
    returnPartialData: true
  });
  const [, setSlug] = useCoopReferrerSlug();
  const [, setReferrer] = useCoopReferrer();
  const [redirect, setRedirect] = useState('');
  const location = useLocation();

  useEffect(() => {
    setSlug(slug);
  }, [slug, setSlug]);

  useEffect(() => {
    console.log('Referrer UUID', { uuid: data?.coop_referrer });

    if (data?.coop_referrer) {
      setReferrer(data?.coop_referrer);

      setRedirect('/' +
        location.pathname.split('/').slice(3).join('/') +
        location.search
      );
    }
  }, [data, setReferrer, location]);

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <Page title='HNP'>
      <h1>Loading...</h1>
      {/* <p>Slug: { slug }</p>
      <p>Location: { location.pathname }{ location.search }</p>
      <p>Redirect: /{ location.pathname.split('/').slice(3).join('/') }{ location.search } </p>
      <p>
        Referrer: <br />
        <code><pre>{ JSON.stringify({ data }) }</pre></code>
      </p> */}
    </Page>
  );
}

export default CoopReferrer;