
import React from 'react';

export interface PointBreakdown {
  message: string;
  items?: Array<PointBreakdown | string | React.ReactElement>;
  strong?: boolean;
}

export const lastUpdated = new Date('2023-10-10 17:00:00-07');

const breakdown: Array<PointBreakdown> = [{
  message: 'Become / Refer a Verified HNP Member',
  items: [
    '250 points for each VHB (monthly) or',
    '2,200 points for each VHB (yearly)',
    '50 points for each CoOp Free Member referred', 
    <strong>200 points for each CoOp Share Member referred</strong>
  ]
}, {
  message: 'Do business with HNP / HNP partner',
  items: [
    <strong>With HNP: 5 points per dollar (no maximum)</strong>,
    'With HNP Partner: 5 points per dollar (1,000 points max per transaction)'
  ]
}, {
  message: 'Do / Refer business to someone in HNP',
  items: [
    'VHB Member: 5 points per dollar (1,000 max per transaction)',
    'Free Member: 1 points per dollar (150 max per transaction)'
  ]
}, {
  message: 'Being a Co-Host',
  items: [
    'Co-Host: 50 points per month',
    'Captain: 100 points per month',
    'Mentor: 200 points per month'
  ]
}, {
  message: 'Inviting people (first-time) to attend networking events',
  items: [
    '100 points per attendee',
    '150 points for most local attendees',
    '200 points for most weekly attendees (across all meetings)'
  ]
}, {
  message: 'Attending HNP Events (40 minute minimum)',
  items: [
    '60 points per regional networking (600 points per week max)',
    '100 points per worldwide event',
    '40 points per masterclass',
    '50 points per HNP/CoOp Orientation',
    '50 points per Club 100',
    '50 points per HNP Special Elite Forces'
  ]
}, {
  message: 'Engagement Bonuses',
  items: [{
    message: 'Daily Login Bonus',
    items: [
      'Free Members: 1 point',
      'Share Members: 2 points',
      'VHB: 3 points',
      'Share Members & VHB: 4 points'
    ]
  },
  <strong>Happy Monday Weekly Bonus: 10 points for Share Members / Verified Happy Businesses</strong>
]
}, {
  message: 'Representation Bonuses',
  items: [
    'Decal on website (with backlink) 100 points',
    <>Send us video testimony: 25 points <strong>(one time)</strong></>,
    <>Post video testimony to social media: 25 points <strong>(one time)</strong></>,
    '50 points for YouTube subscription'
  ]
}];

export default breakdown;