
import React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Faq from 'react-faq-component';

const data = {
  title: 'Business Support',
  rows: [{
      title: 'An Item',
      content: 'The Info'
    }, {
      title: 'An Item 2',
      content: 'The Info Exists'
    }, {
      title: 'An Item 3',
      content: 'Down Leads to Up!'
    },
  ]
}

const BusinessSupport = () => {

  return (
    <Page title='HNP - About Us'>
      <HeaderBar title='About the HNP!' />
      <Container>
        <Faq data={data} />
      </Container>
    </Page>
  );
}

export default BusinessSupport;