
import React from 'react';
import styled from 'styled-components';

const CUTOFF = 720;

const ResponsiveHide = styled.div`
  @media (${({ type }) => type === 'desktop' ? 'min' : 'max' }-width: ${CUTOFF}px) {
    display: none;
  }
`;

export const HideMobile = ({ children }) => (
  <ResponsiveHide type='mobile'>
    { children }
  </ResponsiveHide>
);

export const HideDesktop = ({ children }) => (
  <ResponsiveHide type='desktop'>
    { children }
  </ResponsiveHide>
);