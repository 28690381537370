
import { gql, useQuery } from '@apollo/client';
import useCoopReferrer from './useCoopReferrer';

const DEFAULT_HOPIN_LINK = 'https://hopin.com/events/international-day-of-happiness';

export interface HopinLinkResponse {
  hopin_referral_link: string;
}

const HopinLinkQuery = gql`
  query HopinLink($member_uuid: ID!) {
    hopin_referral_link(member_uuid: $member_uuid)
  }
`;

export const useHopinReferralLink = () => {
  const [referrer_uuid] = useCoopReferrer();
  const { data } = useQuery<HopinLinkResponse>(HopinLinkQuery, {
    variables: {
      member_uuid: referrer_uuid
    }
  });

  if (referrer_uuid) 
    return `${DEFAULT_HOPIN_LINK}?ref=${referrer_uuid}`;
    
  // return data?.hopin_referral_link || DEFAULT_HOPIN_LINK;
  return DEFAULT_HOPIN_LINK;
}

export default useHopinReferralLink;