
import React, { useCallback } from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { Survey, Model } from "survey-react";
import { sendSurveyData } from '../API.js';

import data from '../data/zoom-networking-survery.json';
const model = new Model(data);

const CollabSurvey = () => {
  const onComplete = useCallback((survey, options) => {
    sendSurveyData('Collab Survey', survey.data);
  }, []);

  return (
    <Page title='HNP - Collaboration Pays'>
      <HeaderBar title='Collaboration Pays' />
      <Container>
				<div>
					<h2>Get Rewarded For Doing Business With Other Business Owners In The Happy Neighborhood Project</h2>
					<ol>
						<li>Be entered to get highlighted at our Worldwide Main event (refreshes on Friday after the Worldwide Main event)</li>
						<li>Additional highlight in our Facebook, Facebook story, Facebook group, Instagram, Instagram story, LinkedIn, and email newsletter (reach 1,500-3,000 people)</li>
					</ol>
					<p>Fill this form <strong>ONLY</strong> if the business transaction/referral was completed.</p>
				</div>
				<hr />
				<div className='panel' style={{ width: '100%' }}>
					<div className='panel-body my-2'>
						<Survey
							model={model}
							onComplete={onComplete}
							completedHtml='<p>Feedback Sent!</p>'
							css={css}
						/>
					</div>
				</div>
      </Container>
    </Page>
  );
}

export default CollabSurvey;

const css = {
	"root": "sv_main sv_bootstrap_css",
	"container": "sv_container",
	"header": "panel-heading card-header",
	"body": "panel-body card-block mt-4",
	"bodyEmpty": "panel-body card-block mt-4 sv_body_empty",
	"footer": "panel-footer card-footer",
	"title": "",
	"description": "",
	"headerText": "sv_header__text",
	"navigationButton": "",
	"completedPage": "",
	"navigation": {
		"complete": "btn sv_complete_btn",
		"prev": "btn sv_prev_btn",
		"next": "btn sv_next_btn",
		"start": "btn sv_start_btn",
		"preview": "btn sv_preview_btn",
		"edit": "btn sv_edit_btn"
	},
	"page": {
		"root": "",
		"title": "",
		"description": "small"
	},
	"pageTitle": "",
	"pageDescription": "small",
	"row": "sv_row",
	"question": {
		"mainRoot": "sv_qstn",
		"flowRoot": "sv_q_flow sv_qstn",
		"header": "",
		"headerLeft": "title-left",
		"content": "",
		"contentLeft": "content-left",
		"titleLeftRoot": "sv_qstn_left",
		"title": "",
		"number": "sv_q_num",
		"description": "small",
		"descriptionUnderInput": "small",
		"requiredText": "sv_q_required_text",
		"comment": "form-control",
		"required": "",
		"titleRequired": "",
		"hasError": "has-error",
		"indent": 20,
		"formGroup": "form-group"
	},
	"error": {
		"root": "alert alert-danger",
		"icon": "glyphicon glyphicon-exclamation-sign",
		"item": "",
		"locationTop": "sv_qstn_error_top",
		"locationBottom": "sv_qstn_error_bottom"
	},
	"html": {
		"root": ""
	},
	"radiogroup": {
		"root": "sv_qcbc form-inline",
		"item": "radio",
		"itemChecked": "checked",
		"itemInline": "sv_q_radiogroup_inline",
		"label": "",
		"labelChecked": "",
		"itemControl": "",
		"itemDecorator": "sv-hidden",
		"controlLabel": "",
		"materialDecorator": "circle",
		"other": "sv_q_radiogroup_other form-control",
		"clearButton": "sv_q_radiogroup_clear button",
		"column": "sv_q_select_column"
	},
	"text": "form-input"
};