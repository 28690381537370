
import React, { useMemo } from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import shuffle from '../../tools/shuffle';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);

const Select = (props) => {
  const { items = [], horizontal, randomize, getValue, setValue, label, showRequired, isPristine, isValid, showError, 
    getErrorMessage, resetValue, setValidations, validationError, validationErrors, getErrorMessages, hasValue, isFormDisabled, isFormSubmitted, isRequired, isValidValue, placeholder = '', nullValue, extractKey, extractLabel, extractValue, ...rest } = props;
  const error = getErrorMessage();

  const itemElements = useMemo(() => {
    const output = items.slice();

    if (randomize) {
      shuffle(output);
    }

    return output.map(item => (
      <option 
        key={extractKey ? extractKey(item) : item.id || item.uuid || item}
        value={extractValue ? extractValue(item) : item.value || item.id || item.uuid || item}
        >
        {extractLabel ? extractLabel(item) : item.label || item.name || item}
      </option>
    ));
  }, [items, randomize, extractKey, extractLabel, extractValue]);

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <div className={classNames('col-sm-12', { 'col-9': horizontal })}>
        <select className="form-select text-dark" value={getValue()} defaultValue=''  onChange={e => setValue(e.currentTarget.value)} {...rest}>
          { placeholder &&
            <option value='' disabled>{ placeholder }</option>
          }
          { itemElements }
          { nullValue &&
            <option value=''>{ nullValue }</option>
          }
        </select>
        { Boolean(error) && (
          <p className="form-input-hint">{ error }</p>
        ) }
      </div>
    </div>
  );
}

export default withFormsy(Select);