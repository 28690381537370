
import React from 'react';
import './index.css';
import { useParams } from 'react-router-dom';

const IDHRedirect = () => {
  const { slug } = useParams<{ slug: string }>();
  
  window.location.href = `https://hopin.com/events/international-day-of-happiness?ref=${slug}`;

  return null;
}

export default IDHRedirect;