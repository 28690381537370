
import React from 'react';
import { withFormsy } from 'formsy-react';

interface RequiredCheckboxProps {
  name: string;
  label?: string;
  getValue?: () => string;
  setValue?: (val: string) => void;
}

const RequiredCheckbox = (props: RequiredCheckboxProps) => {
  const { label = '', getValue = () => undefined, setValue = () => {} } = props;

  return (
    <label className='form-checkbox form-inline'>
      <input 
        type='checkbox' 
        value={getValue()}
        onChange={e => setValue(e.currentTarget.value === 'checked' ? '' : 'checked')}
        required 
        />
      <i className="form-icon"></i> { label }
    </label>
  );
}

export default withFormsy(RequiredCheckbox);