
import React, { useMemo } from 'react';
import ExternalLink from './ExternalLink';
import { FaFacebookSquare, FaInstagram, FaYoutube, FaSlackHash, FaLinkedinIn } from 'react-icons/fa';

const socials = {
  instagram: {
    Icon: FaInstagram,
    url: 'https://instagram.com/happy_neighborhood',
    color: '#FFF',
    title: 'Visit the Happy Neighborhood Project on Instagram!'
  },
  youtube: {
    Icon: FaYoutube,
    url: 'https://www.youtube.com/channel/UCdLpm0_tyK_xGhERg-Koilw',
    color: '#E52D57',
    title: 'Happy Neighborhood YouTube Channel'
  },
  facebook: {
    Icon: FaFacebookSquare,
    url: 'https://www.facebook.com/happyneighborhood',
    color: '#3B5998',
    title: 'Visit the Happy Neighborhood Project on Facebook!'
  },
  slack: {
    Icon: FaSlackHash,
    url: 'https://happyneighborhood.slack.com/',
    color: '#2EB67D',
    title: 'Happy Neighborhood Slack Workspace'
  },
  linkedin: {
    Icon: FaLinkedinIn,
    url: 'https://linkedin.com/company/happyneighborhood',
    color: '#2867B2',
    title: 'Happy Neighborhood on LinkedIn'
  }
};

const SocialLink = ({ name = 'instagram', color = '#5755D9', size = 2, url, style, ...props }) => {
  const styles = useMemo(() => {
    return {
      container: {
        height: `${size * 1.25}em`,
        width: `${size * 1.25}em`
      },
      icon: {
        margin: `${size / 4}em`,
        ...style
      }
    }
  }, [style, size]);

  const social = useMemo(() => socials[name.toLowerCase()], [name]);

  return (
    <ExternalLink title={social.title} href={social.url} style={styles.container} {...props}>
      <social.Icon 
        className='hover-scale'
        style={styles.icon} 
        color={social.color} 
        size={`${size}em`}
        />
    </ExternalLink>
  );
}

export default SocialLink;