
import React, { createContext, useContext, useState, useMemo } from 'react';

const HnpContext = createContext({
  zip: '',
  category: {}
});

HnpContext.displayName = 'HnpContext';

export function useZip() {
  const { zip, setZip } = useContext(HnpContext);

  return [zip, setZip];
}

export function useCategory() {
  const { category, setCategory } = useContext(HnpContext);

  return [category, setCategory];
}

export const HnpProvider = ({ children }) => {
  const zipFromGPS = false; // useZipCodeFromGPS();
  const [zip, setZip] = useState('');
  const [category, setCategory] = useState({});

  const value = useMemo( () => ({
    zip, setZip, category, setCategory
  }), [zip, category]);

  if (zipFromGPS && !zip) {
    setZip(zipFromGPS);
  }

  return (
    <HnpContext.Provider value={value}>
      { children }
    </HnpContext.Provider>
  )
}

export default HnpProvider;