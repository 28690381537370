
import React, { useState, useEffect, useMemo } from 'react';
import Formsy from 'formsy-react';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';
import StripeInput from './form/StripeInput.js';
import Input from './form/Input.js';
import Divider from '../components/Divider.js';
import { getPricing } from '../API.js';
import Hider from './Hider';

function centsToUSD(cents) {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

const PaymentInformationForm = ({ remote, zips =[], onChange, onNext, onPrev, processing, error }) => {
  const [valid, setValid] = useState(false);
  const [pricing, setPricing] = useState({});
  const [code, setCode] = useState();
  const [coupon, setCoupon] = useState();
  const [loading, setLoading] = useState(false);
  const [showCouponEntry, setShowCouponEntry] = useState(false);

  const _onChange = useMemo(() => debounce(600, false, (values, isChanged) => {
    onChange && onChange(values, valid);
  }), [valid, onChange]);

  useEffect(() => {
    console.log({ remote, zips, coupon });
    setLoading(true);
    
    getPricing(zips, coupon).then(pricing => {
      setPricing(pricing);
      setLoading(false);
    }).catch(error => {
      console.warn('useEffect getPricing', error);
      setLoading(false);
    });
  }, [remote, zips, coupon]);
  
  return (
    <Formsy 
      onValid={() => setValid(true)} 
      onInvalid={() => setValid(false)}
      onChange={_onChange}
      className={classNames('col-12')}
      >
      <Input type='hidden' name='price' value={parseInt(pricing.total || 0)} />
      <Input type='hidden' name='coupon' value={coupon} />
      <Hider hidden={processing}>
        <div className={classNames('card', {loading})}>
          <div className='card-header'>
            <div className='card-title h5'>Selected Zips</div>
          </div>
          <div className='card-body'>
            Zips: { zips.map(z => <span key={z} className='chip'>{z}</span>)}
          </div>
          <div className='card-footer'>
            Total: {centsToUSD(pricing.total)} <Hider hidden={!pricing.discount} className='d-inline'> (regular {centsToUSD(pricing.regular)})</Hider>
            <Hider hidden={!pricing.discount}>
              {centsToUSD(pricing.discount)} discount from coupon code <span className='label label-secondary'>{pricing.coupon}</span>! <button onClick={() => setCoupon(null)} className="btn btn-link" aria-label="Remove Coupon">(Remove)</button>
            </Hider>
          </div>
        </div>
        {
          Boolean(!pricing || (pricing && pricing.total > 0)) && (
            <>
              <h2 className='mt-2'>Enter Payment Information: </h2>
              <StripeInput name='stripe' required />
            </>
          )
        }
        <button className={classNames('btn', 'btn-link', { 'd-none': showCouponEntry })} onClick={() => setShowCouponEntry(true)}>I have a coupon code</button>
        <Hider hidden={!showCouponEntry || pricing.discount}>
          <div className='input-group col-9'>
            <input 
              className="form-input" 
              value={code}
              placeholder='Coupon Code'
              onChange={e => setCode(e.currentTarget.value)}
              />
            <button class="btn btn-primary input-group-btn" onClick={() => setCoupon(code)}>
              <i class="form-icon icon icon-check" />
            </button>
          </div>
        </Hider>
      </Hider>
      <Hider hidden={!processing}>
        <div className='loading loading-lg' />
        <p>Processing Payment...</p>
      </Hider>
      <Hider className='toast toast-error mt-2' hidden={!error}>
        { error }
      </Hider>
      <Divider />
      <div className='col-12 clearfix'>
        <button className='btn btn-primary float-left' onClick={onPrev} disabled={processing}>Previous</button>
        <button className='btn btn-success float-right' onClick={onNext} disabled={!valid || processing}>Register!</button>
      </div>
    </Formsy>
  );
}

export default PaymentInformationForm;