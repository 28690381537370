
import React from 'react';
import Page from '../components/layout/Page';
import { Grid, Row, Col } from '@react-spectre/grid';
import { Video } from '@react-spectre/media';
import { Link } from 'react-router-dom';
import { useTranslation } from'react-i18next';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import Divider from '../components/Divider.js';

const About = () => {
  const { t } = useTranslation();

  return (
    <Page title='HNP - Our Story'>
      <HeaderBar>
        <h4>{ t('about.heading', 'Our Story! About the HNP!') }</h4>
      </HeaderBar>
      <Container>
        <Grid>
          <Row className='mb-2 text-center'>
            <div className='p-centered p-2'>
              <img src="/images/TEDx_300x100.png" style={{margin: 0}} height={50} width={150} alt="TEDx"/>
            </div>
            <Video.Container responsive> 
              <iframe title='HNP: A Registry for Happy Businesses' src='https://www.youtube.com/embed/z3cNvYW_A1g?rel=0&autoplay=1' />
            </Video.Container>
          </Row>
          <Divider />
          <Row className='pt-2' style={{ padding: 50 }}>
            <Col>
              <p>{ t('about.business.p3') }</p>
              <p>{ t('about.business.p4') }</p>
              <div className='text-center'>
                <Link className='btn mb-2' to='/plans'>
                  { t('about.business.button') }
                </Link>
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </Page>
  )
}

export default About;