
import React, { ReactChild } from 'react';
import classNames from 'classnames';

interface Props {
  id?: string;
  className?: string;
  children: ReactChild | Array<ReactChild>;
  full?: boolean;
  short?: boolean;
  light?: boolean;
}

export const Divider = ({ height = 2 }) => (
  <div style={{ width: '100%', margin: `${height}em 0` }} />
);

const Section = ({ children, full, className, light, short, ...props }: Props) => (
  <div className={classNames('landing-section', { light })}>
    <div {...props} className={classNames('landing-section-content', { full, short }, className)}>
      { children }
    </div>
  </div>
);

export default Section;