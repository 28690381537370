import React from 'react';
import classes from './hero.module.css';
import btnClasses from '../UI/button/button.module.css';
import { Link } from 'react-router-dom';

const hero = (props) => {
    return (
        <section id={classes.hero}>
            <div className="container">
                <div className={classes.heroWraper}>
                    <div className={classes.hrLft}></div>
                    <div className={classes.hrRght}>
                        <h1>
                           Why we started the Happy CoOp
                        </h1>
                        <p>Since March of 2020, you have helped us spread happiness to thousands of people across the globe. </p>
                        <p>Together, we have raised the level of happiness of our group by an average of 35%.</p>
                        <span>So it is time for us to give back even more! </span>
                        <Link to='/coop/registration' className={[btnClasses.button].join(' ')} style={{ marginTop: '80px' }}>BECOME A MEMBER</Link>
                        <br />
                        <a href='https://coop.happyneighborhoodproject.com/' className={[btnClasses.button].join(' ')} style={{ marginTop: '20px' }}>Login</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default hero