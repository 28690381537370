
import React, { ReactChild } from 'react';
import classNames from 'classnames';

interface TextProps {
  className?: string;
  children: ReactChild | Array<ReactChild>;
}

export const Header = ({ children, className }: TextProps) => (
  <h1 className={classNames('header', className)}>
    { children }
  </h1>
);

export const SubHeader = ({ children, className }: TextProps) => (
  <h3 className={classNames('sub-header', className)}>
    { children }
  </h3>
);

export const Purple = ({ children, className }: TextProps) => (
  <span className={classNames('text-purple', className)}>
    { children }
  </span>
);

export const Yellow = ({ children, className }: TextProps) => (
  <span className={classNames('text-yellow', className)}>
    { children }
  </span>
);

export const Checkmark = ({ color = 'yellow' }: { color?: 'yellow' | 'purple' }) => (
  <span className={`text-${color} landing-checkmark`}>&#10003;</span>
);

export const Text = ({ children, className }: TextProps) => (
  <span className={classNames('text', className)}>
    { children }
  </span>
);

export const Paragraph = ({ children, className }: TextProps) => (
  <p className={classNames('paragraph', className)}>
    { children }
  </p>
);