
import React from 'react';
import classes from './whyus.module.css'
import Card from '../UI/card/card'
import btnClasses from '../UI/button/button.module.css';
import profiticon from '../../assets/img/profit.png'
import member from '../../assets/img/member.png'
import vote from '../../assets/img/manual-voting.png'
import click from '../../assets/img/click.png'
import { Link } from 'react-router-dom';


const whyus = () => {
    return (
        <section id={classes.whyus}>
            <div className="container">
                <h2 className={classes.sectitel}>
                    Why join the Happy CoOp
                </h2>
                <div className={classes.wrapper}>
                    <Card icon={profiticon} w='23%' des="Earn quarterly Revenue Sharing from the Patronage Fund of the Happy CoOp" />
                    <Card icon={member} w='23%' des="Membership Share of the Happy CoOp" />
                    <Card icon={vote} w='23%' des="Earn one Vote on the direction of our community" />
                    <Card icon={click} w='23%' des="Earn special access to private Leadership calls, clothing, and events " />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Link to='/coop/registration' className={[btnClasses.button, btnClasses.btnBlue].join(' ')} center style={{ marginTop: '50px' }} >
                        BECOME A MEMBER
                    </Link>
                </div>
            </div>
        </section>
    )
}
export default whyus