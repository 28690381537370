
import React, { useState } from 'react';
import Page from '../components/layout/Page';
import classNames from 'classnames';
import { useTranslation } from'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../components/form/HookFormInput';
import HookFormSelect from '../components/form/HookFormSelect';
import HookFormRadio from '../components/form/HookFormRadio';
import { sendSummitSponsorRequest } from '../API.js';
import HookFormTextarea from '../components/form/HookFormTextarea.js';
import { GlobalSummitLandingComponent } from './GlobalSummitLanding.js';

export const SponsorForm = ({ onResult }) => {
  const { t } = useTranslation();
  const _form = useForm();
  const { handleSubmit, watch, errors } = _form;
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const referral = watch('referral');

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await sendSummitSponsorRequest(data);
    setResult(result);
    setLoading(false);
    onResult && onResult(result);
  };

  if (result) {
    return (
      <div class="empty">
        <div class="empty-icon">
          <i class="icon icon-people"></i>
        </div>              
        <p>{ t('summit.sponsor.success.p1', 'Thank you for your interest in sponsoring!') }</p>
        <p>{ t('summit.sponsor.success.p2', 'We will contact you soon.') }</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>Become a Sponsor</h3>
      <HookFormInput 
        name='company' 
        placeholder='Company Name'
        label='Company Name'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='name' 
        placeholder='Contact Person'
        label='Contact Person'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='email' 
        placeholder='Email Address'
        label='Email Address'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='phone' 
        placeholder='Phone Number'
        label='Phone Number'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormRadio
        name='size'
        label='Company Size'
        items={['Local', 'Regional', 'National', 'International']}
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormSelect
        name='budget'
        label='Sponsorship Budget'
        placeholder='Select amount...'
        items={[
          '$1,000 to $2,500',
          '$2,500 to $5,000',
          '$5,000 to $10,000',
          '$10,000 or more'
        ]}
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormInput 
        name='call_time' 
        placeholder='Best Time to Call?'
        label='When should we call?'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormTextarea 
        name='why' 
        placeholder='Why do you want your brand associated with Global Happiness Summit?'
        label='Why do you want your brand associated with Global Happiness Summit?'
        form={_form}
        formProps={{ required: true }}
        />
      <HookFormRadio
        name='referral'
        label='Is This a Referral'
        items={['Yes', 'No']}
        form={_form}
        formProps={{ required: true }}
        />
      { referral === 'Yes' && 
        <>
          <HookFormInput 
            name='referral_name' 
            placeholder='Referrer Name'
            label='Your Name'
            form={_form}
            formProps={{ required: true }}
            />
          <HookFormInput 
            name='referral_phone' 
            placeholder='Referrer Phone Number'
            label='Your Phone Number'
            form={_form}
            formProps={{ required: true }}
            />
        </>
      }
      <button 
        disabled={errors.length || loading} 
        className={classNames('btn btn-primary mt-2', { loading })} 
        type="submit" 
        >
          Submit!
      </button>
    </form>
  );
}

const GlobalSummit = () => {
  return (
    <Page title='HNP - Global Happiness Summit 2020' className='background background-networking-2'>
      <GlobalSummitLandingComponent />
    </Page>
  );
}

export default GlobalSummit;