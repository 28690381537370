import React from 'react';
import Card from '../UI/card/card'
import btnClasses from '../UI/button/button.module.css';
import classes from './earn-share-points.module.css'
import event from '../../assets/img/calendar.png'
import network from '../../assets/img/global-network.png'
import online from '../../assets/img/online.png'
import backlink from '../../assets/img/backlinks.png'
import testimonial from '../../assets/img/testimonial.png'
import refer from '../../assets/img/refer.png'
import user from '../../assets/img/user.png'
import vm from '../../assets/img/user (1).png'
import business from '../../assets/img/handshake.png'
import { Link } from 'react-router-dom';
import CoopCountdown from '../../../../../components/custom/CoopCountdown';

const earn = () => {
    return (
        <section id={classes.ernpoints}>
            <div className="container">
                <h2 className={classes.sectitel}>
                    Ways to Earn Share Points
                </h2>
                <div className={classes.wrapper}>
                    <Card template="inline" style={{ marginTop: "20px" }} icon={event} w='23%' des="Attending networking events / masterclasses " />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={network} w='23%' des="Inviting people to networking events / masterclasses" />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={online} w='23%' des="Being a Co-Host / Captain / Mentor monthly points  " />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={backlink} w='23%' des="Put HNP Decal on website and backlink  " />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={testimonial} w='23%' des="Video testimony about HNP" />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={user} w='23%' des="Become/refer an HNP member (different points for different levels)" />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={refer} w='23%' des="Engage with us / Happy Mondays" />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={vm} w='23%' des="Do business with / refer business to HNP Members" />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={business} w='23%' des="Do business with an HNP partner " />
                    <Card template="inline" style={{ marginTop: "20px" }} icon={business} w='23%' des="Do business with HNP" />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <br />
                    <CoopCountdown />
                    <br />
                    <Link to='/coop/registration' className={[btnClasses.button, btnClasses.btnBlue].join(' ')} center style={{ marginTop: '50px' }} >
                        BECOME A MEMBER
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default earn