
import React, { isValidElement } from 'react';
import { PointBreakdown } from './point_breakdown';

interface PointBreakdownItemProps {
  breakdown: PointBreakdown;
  top?: boolean;
}

function PointBreakdownItem({ breakdown, top }: PointBreakdownItemProps) {

  return (
    <>
      { top ?
        <h2>{ breakdown.message }</h2>
        : breakdown.message 
      }
      <ul>
        { breakdown.items?.map((item, i) => (
          <li key={i}>
            { typeof item === 'string' || isValidElement(item) ? 
              item : 
              <PointBreakdownItem breakdown={item} />
            }
          </li>
        ))}
      </ul>
    </>
  );
}

export default PointBreakdownItem;