
import React from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';

const RedAsterisk = () => (<span style={{color: 'red'}}>*</span>);

const Input = (props) => {
  const { horizontal, getValue, setValue, label, showRequired, isPristine, isValid, showError, 
    getErrorMessage, resetValue, setValidations, validationError, validationErrors, getErrorMessages, hasValue, isFormDisabled, isFormSubmitted, isRequired, isValidValue, placeholder = '', ...rest } = props;
    
  const error = getErrorMessage();

  return (
    <div className={classNames('form-group', {'has-success': !isPristine() && isValid(), 'has-error': showError() })}>
      { label &&
        <div className={classNames('col-sm-12', { 'col-3': horizontal })}>
          <label className="form-label">
            { label }{ showRequired() ? <RedAsterisk /> : '' }
          </label>
        </div>
      }
      <div className={classNames('col-sm-12', { 'col-9': horizontal })}>
        <input 
          className="form-input" 
          value={getValue() || ''}
          placeholder={placeholder}
          onChange={e => setValue(e.currentTarget.value)}
          {...rest}
          />
        { Boolean(error) && (
          <p className="form-input-hint">{ error }</p>
        ) }
      </div>
    </div>
  );
}

export default withFormsy(Input);