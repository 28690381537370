
import React, { useEffect } from 'react';
import './index.css';
import Header from './components/header/header';
import HomePage from './containers/homepage';

function CoopLanding() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <HomePage/>
    </React.Fragment>
  );
}

export default CoopLanding;
