
import React, { useState, useEffect } from 'react';
import Page from '../components/layout/Page';
import { Grid, Row, Col } from '@react-spectre/grid';
import Container from '../components/layout/Container';
import HeaderBar from '../components/layout/HeaderBar';
import { Redirect } from "react-router-dom";

const APPLE_URL = 'https://itunes.apple.com/us/app/happy-neighborhood/id1450773829?ls=1&mt=8';
const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.happyneighborhoodproject.app';

const AppDownload = () => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
      if (/android/i.test(userAgent)) {
        setMobile('android');
      }
  
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setMobile('ios');
      }
    }, 1911);

    return () => timeout && clearTimeout(timeout);
  }, []);

  if (mobile) {
    return (
      <Redirect 
        to={mobile === 'ios' ? APPLE_URL : ANDROID_URL} 
        />
    );
  }

  return (
    <Page title='HNP - Download Our App!'>
      <HeaderBar title='Download Our App!' />
      <Container>
        <Grid>
          <Row>
            <Col>
              <div className="col-md-12 text-center">
                <h2>Happy Neighborhood App</h2>
                <h3>“Increase and sustain a higher level of happiness!”</h3>
                <div className="text-center">
                  <a style={{marginRight: 20}} href={APPLE_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/images/app-store-badge.png" height="50" alt="Download in the Apple App Store"/>
                  </a>
                  <a href={ANDROID_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/images/google-play-badge.png" height="50" alt="Download for Android in the Google Play Store"/>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src="/images/app-dashboard.png" width="100%" alt="HNP App screenshot"/>
            </Col>
            <Col>
              <div style={{height: '100%', paddign: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <p>The purpose of this app is to help individuals increase and sustain a high level of happiness using the Gratitude Journal supported by our daily Happiness Messages. We have also made it easy to locate happy businesses and events in your community.</p>
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </Page>
  );
}

export default AppDownload;