
import React from 'react';
import classNames from 'classnames';

const HookFormTextarea = ({ name, label, placeholder, errorMessage, form, formProps }) => {
  const { register, errors } = form;
  const error = errors[name];
  
  return (
    <div className={classNames('form-group', { 'has-error': Boolean(error) })}>
      { Boolean(label) &&
        <label class="form-label" for={name}>{label}</label>
      }
      <textarea 
        className='form-input' 
        placeholder={placeholder} 
        name={name} 
        ref={register(formProps)} 
        />
      { Boolean(error) && 
        <p class="form-input-hint">{ errorMessage || 'Required.' }</p> 
      }
    </div>
  );
}

export default HookFormTextarea;