
import React from 'react';
import ExternalLink from '../components/ExternalLink.js';
import { FaPhone, FaRegEnvelope, FaGlobeAfrica } from 'react-icons/fa';

const ContactInfo = ({ info = {}, ...props }) => {
  const { phone, email, website } = info;

  if (!phone && !website && !email) {
    return null;
  }

  return (
    <div {...props}>
      { phone &&
        <ExternalLink title='Call Phone' className='btn m-1' href={`tel:${phone}`}>
          <FaPhone /> Call
        </ExternalLink>
      }
      { email &&
        <ExternalLink title='Email' className='btn m-1' href={`mailto:${email}`}>
          <FaRegEnvelope className='icon' /> Email
        </ExternalLink>
      }
      { website &&
        <ExternalLink title='Website' className='btn m-1' href={website}>
          <FaGlobeAfrica className='icon' /> Visit Website
        </ExternalLink>
      }
    </div>
  );
}

export default ContactInfo;