
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import SocialLink from './SocialLink.js';

export const Footer = ({title = ''}) => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className='bg-dark' style={{padding: '1rem', textAlign: 'center'}}>
      <div className='hero-body'>
        <p style={{margin: 0 }}>
          &copy; 2015 - {year || '2023'} <Link to=''>Happy Neighborhood Project</Link>. All Rights Reserved.
          <br />
          <Link to='/contact'>Contact Us</Link> &middot; <Link to='/privacy'>Privacy Policy</Link> &middot; <Link to='/terms'>Terms &amp; Conditions</Link>
        </p>
        <div>
          <SocialLink name='facebook' />
          <SocialLink name='instagram' />
          <SocialLink name='youtube' />
          <SocialLink name='linkedin' />
          {/* <SocialLink name='slack' /> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;